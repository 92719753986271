import React, { createContext, useContext, useState } from 'react'

const LayoutContext = createContext({})

const startTime = (days) =>
  Math.floor(Date.now() / 1000) - (days * 24 * 60 * 60)

const token = {
  BSC: {
    url: 'https://api.bscscan.com',
    token: '0x55d398326f99059fF775485246999027B3197955',
    apikey: 'U5ISUAPVNNTHPTICV2FR3CKFF7KQ1ZZY57'
  },
  OP: {
    url: 'https://api-optimistic.etherscan.io',
    token: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
    apikey: 'WUZMFFRP59RY8BEZJMWMMBS1RSJE4674WD'
  }
}

const useLayoutContext = () => {
  const context = useContext(LayoutContext)
  if (!context) {
    throw new Error('Cannot use LayoutContext outside LayoutContextProvider!')
  }
  return context
}

const LayoutContextProvider = ({ children }) => {
  const [paginate, setPaginate] = useState({})
  const [tk, setTk] = useState({})
  const [val1, setVal1] = useState()
  const [val2, setVal2] = useState()
  const [bscTx, setBscTx] = useState()
  const [opTx, setOpTx] = useState()
  const [txDay, setTxDay] = useState(7)

  const getPageNo = (key) => {
    return paginate[key]
  }

  const onPageChange = (key, page) => {
    setPaginate({
      ...paginate,
      [key]: page
    })
  }

  const getTK = (key) => {
    return tk[key]
  }

  const setTK = (key, value) => {
    setTk({
      ...tk,
      [key]: value
    })
  }

  const getStat = () => {
    return val1
  }

  const setStat = (value) => {
    setVal1(value)
  }

  const getKey = () => {
    return val2
  }

  const setKey = (value) => {
    setVal2(value)
  }

  const getTXs = async (tk, address, days) => {
    try {
      const d = (days === 7) ? txDay : days
      if ((tk === 'BSC') && (txDay === d) && bscTx) {
        return Promise.resolve(bscTx)
      }
      if ((tk === 'OP') && (txDay === d) && opTx) {
        return Promise.resolve(opTx)
      }
      setTxDay(days)
      let n = 100
      let txs = []
      const st = startTime(days)
      const getBlockNobyTime = await fetch(`${token[tk].url}/api?module=block&action=getblocknobytime&timestamp=${st}&closest=before&apikey=${token[tk].apikey}`)
      const iniBlock = await getBlockNobyTime.json()
      while (n === 100) {
        const getTokenTx = await fetch(`${token[tk].url}/api?module=account&action=tokentx&contractaddress=${token[tk].token}&address=${address}&page=1&offset=100&startblock=${iniBlock.result}&endblock=9999999999&sort=desc&apikey=${token[tk].apikey}`)
        const re = await getTokenTx.json()
        n = re.result.length
        txs = [...txs, ...re.result.reduce((acc, o) => {
          return ((o.to).toLowerCase() === address.toLowerCase()) ? [...acc, {
            id: `${o.blockNumber}-${o.transactionIndex}`,
            hash: o.hash,
            blockNo: o.blockNumber,
            txi: parseInt(o.transactionIndex),
            from: o.from,
            to: o.to,
            ts: parseInt(o.timeStamp),
            txv: parseInt(o.value) / (Math.pow(10, parseInt(o.tokenDecimal)))
          }] : acc
        }, [])]
      }
      if (tk === 'BSC') setBscTx(txs)
      if (tk === 'OP') setOpTx(txs)
      return Promise.resolve(txs)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const removeTX = (tk, value) => {
    if (tk === 'BSC') {
      const v = bscTx.filter((o) => o.id !== value)
      setBscTx(v)
    }
    if (tk === 'OP') {
      const v = opTx.filter((o) => o.id !== value)
      setOpTx(v)
    }
  }

  const contextValue = {
    getPageNo,
    onPageChange,
    getTK,
    setTK,
    getStat,
    setStat,
    getKey,
    setKey,
    getTXs,
    removeTX
  }
  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutContextProvider
export {
  useLayoutContext
}
