import React from 'react'

import { Layout, Col, Row, Card, Image, Form, Button } from 'antd'

import { useAuth } from '../../contexts/AuthContextProvider'

import Logo from '../../assets/images/logo.png'
import Google from '../../assets/images/google.svg'

const Login = () => {
  const { firebaseLogin } = useAuth()

  return (
    <Layout className='auth-layout'>
      <Layout.Content>
        <Row>
          <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 8 }} lg={{ span: 9 }}></Col>
          <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
            <Card style={{ marginTop: '50%', backgroundColor: '#ffffff80', borderRadius: '4px', borderColor: '#ffffff30' }}>
              <Image src={Logo} className='logo' preview={false}></Image>
              <Form layout='vertical'>
                <Form.Item>
                  <Button className='gsi-material-button' style={{ width: '100%', marginTop: 20 }} onClick={() => firebaseLogin()}>
                    <div className='gsi-material-button-state'></div>
                    <div className='gsi-material-button-content-wrapper'>
                      <div className='gsi-material-button-icon'>
                        <img src={Google} alt='google' />
                      </div>
                      <span className='gsi-material-button-contents'>Sign in with Google</span>
                      <span style={{ display: 'none' }}>Sign in with Google</span>
                    </div>
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 8 }} lg={{ span: 9 }}></Col>
        </Row>
      </Layout.Content>
    </Layout>
  )
}

export default Login
