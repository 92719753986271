import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Row, Col, Button, Input, Switch, message, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { collection, doc, getDoc, setDoc, addDoc } from 'firebase/firestore'

import { useAuth } from '../../contexts/AuthContextProvider'
import { pickBy } from '../../utils/tools'

import './Setting.css'

const Form = () => {
  const navigate = useNavigate()
  const { db, uid } = useAuth()
  const { tid } = useParams()
  const [messageApi, contextHolder] = message.useMessage()
  const [btnLoading, setBtnLoading] = useState(false)
  const [connectedTelegram, setConnectedTelegram] = useState(false)
  const [isTelegram, setIsTelegram] = useState(false)

  const [tokenForm] = AntdForm.useForm()

  const mode = tid ? 'Edit' : 'Create'

  const { value, loading } = useAsync(async () => {
    if (!tid) {
      return {
        uid: uid
      }
    }

    const snap = await getDoc(doc(db, 'tokens', tid))
    const data = snap.data()
    setConnectedTelegram((data.tg && !!data.tgcid) || !data.tg)
    setIsTelegram(data.tg)
    return {
      ...data,
      id: snap.id,
    }
  }, [tid])

  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      if (formData.tg && !connectedTelegram) {
        messageApi.open({
          type: 'error',
          content: 'Can not connect to Telegram! Please review Token and Chat ID or test Telegram connection before submit.'
        })
        setBtnLoading(false)
        return Promise.resolve()
      }
      const data = pickBy(formData)
      if (tid) {
        await setDoc(doc(db, 'tokens', tid), data, { merge: true })
      } else {
        await addDoc(collection(db, 'tokens'), data)
      }
      setBtnLoading(false)
      navigate('/admin/settings/tokens')
      return Promise.resolve()
    } catch (error) {
      setBtnLoading(false)
      return Promise.resolve()
    }
  }

  const testTelegram = async () => {
    try {
      setBtnLoading(true)
      const data = tokenForm.getFieldsValue()
      if (!(data.tg && data.tgcid)) {
        messageApi.open({
          type: 'error',
          content: 'Please input Telegram Token and Telegram Chat ID!',
          duration: 5
        })
        setBtnLoading(false)
        return Promise.resolve()
      }
      const chat = await fetch(`https://api.telegram.org/bot${data.tgtkn}/sendMessage?chat_id=${data.tgcid}&text='Test for Telegram Connection'`)
      const jsonChat = await chat.json()
      if (!jsonChat.ok) {
        messageApi.open({
          type: 'error',
          content: 'Can not connect to Telegram from this Token and Chat ID!',
          duration: 5
        })
      } else {
        messageApi.open({
          type: 'success',
          content: 'Telegram connected!',
          duration: 5
        })
        setConnectedTelegram(true)
      }
      setBtnLoading(false)
      return Promise.resolve()
    } catch (error) {
      setBtnLoading(false)
      return Promise.resolve()
    }
  }

  if (loading) return <Skeleton></Skeleton>
  return (
    <>
      {contextHolder}
      <div className='setting-page'>
        <Card
          title={`${mode} Alert Token`}
          styles={{
            header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
            body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
          }}
        >
          <AntdForm
            labelCol={{ span: 4 }}
            onFinish={handleSubmit}
            initialValues={value}
            form={tokenForm}
            name='tokenForm'
          >
            <AntdForm.Item name='uid' hidden={true}>
              <Input />
            </AntdForm.Item>
            <AntdForm.Item name='name' label='Name' rules={[{ required: true, message: 'Please input Name!' }]}>
              <Input />
            </AntdForm.Item>
            <AntdForm.Item name='tg' label='Telegram Message'>
              <Switch checkedChildren='Yes' unCheckedChildren='No' onChange={(v) => setIsTelegram(v)} />
            </AntdForm.Item>
            {(isTelegram) && (
              <>
                <AntdForm.Item name='tgtkn' label='Telegram Token' labelCol={{ span: 6 }} rules={[{ required: true, message: 'Please input Telegram Token!' }]}>
                  <Input />
                </AntdForm.Item>
                <AntdForm.Item name='tgcid' label='Telegram Chat ID' labelCol={{ span: 6 }} rules={[{ required: true, message: 'Please input Telegram Chat ID!' }]}>
                  <Input />
                </AntdForm.Item>
                <Row>
                  <Col span={6}></Col>
                  <Col span={18}>
                    <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginBottom: 32 }} onClick={() => testTelegram()}>Test Telegram Connection</Button>
                  </Col>
                </Row>
              </>
            )}
            <div style={{ textAlign: 'center' }}>
              <Button loading={btnLoading} style={{ width: 300, borderRadius: '4px', height: 40, marginTop: 20 }} onClick={() => tokenForm.submit()} type='primary'><SaveOutlined />Submit</Button>
              <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 20 }} onClick={() => navigate(-1)}><CloseOutlined />Close</Button>
            </div>
          </AntdForm>
        </Card>
      </div>
    </>
  )
}

export default Form
