const binanceTickers = {
  spot: [
    {
      tk: 'ETHBTC',
      balas: 'BTC',
      trdas: 'ETH',
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 0.0001
    },
    {
      tk: 'LTCBTC',
      balas: 'BTC',
      trdas: 'LTC',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'BNBBTC',
      balas: 'BTC',
      trdas: 'BNB',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'NEOBTC',
      balas: 'BTC',
      trdas: 'NEO',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'QTUMETH',
      balas: 'ETH',
      trdas: 'QTUM',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'EOSETH',
      balas: 'ETH',
      trdas: 'EOS',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'SNTETH',
      balas: 'ETH',
      trdas: 'SNT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'BNTETH',
      balas: 'ETH',
      trdas: 'BNT',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'BCCBTC',
      balas: 'BTC',
      trdas: 'BCC',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'GASBTC',
      balas: 'BTC',
      trdas: 'GAS',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'BNBETH',
      balas: 'ETH',
      trdas: 'BNB',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'BTCUSDT',
      balas: 'USDT',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 5
    },
    {
      tk: 'ETHUSDT',
      balas: 'USDT',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 5
    },
    {
      tk: 'HSRBTC',
      balas: 'BTC',
      trdas: 'HSR',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'OAXETH',
      balas: 'ETH',
      trdas: 'OAX',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'DNTETH',
      balas: 'ETH',
      trdas: 'DNT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'MCOETH',
      balas: 'ETH',
      trdas: 'MCO',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'ICNETH',
      balas: 'ETH',
      trdas: 'ICN',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'MCOBTC',
      balas: 'BTC',
      trdas: 'MCO',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'WTCBTC',
      balas: 'BTC',
      trdas: 'WTC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'WTCETH',
      balas: 'ETH',
      trdas: 'WTC',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'LRCBTC',
      balas: 'BTC',
      trdas: 'LRC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'LRCETH',
      balas: 'ETH',
      trdas: 'LRC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'QTUMBTC',
      balas: 'BTC',
      trdas: 'QTUM',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'YOYOBTC',
      balas: 'BTC',
      trdas: 'YOYO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'OMGBTC',
      balas: 'BTC',
      trdas: 'OMG',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'OMGETH',
      balas: 'ETH',
      trdas: 'OMG',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'ZRXBTC',
      balas: 'BTC',
      trdas: 'ZRX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ZRXETH',
      balas: 'ETH',
      trdas: 'ZRX',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'STRATBTC',
      balas: 'BTC',
      trdas: 'STRAT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'STRATETH',
      balas: 'ETH',
      trdas: 'STRAT',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'SNGLSBTC',
      balas: 'BTC',
      trdas: 'SNGLS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'SNGLSETH',
      balas: 'ETH',
      trdas: 'SNGLS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'BQXBTC',
      balas: 'BTC',
      trdas: 'BQX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BQXETH',
      balas: 'ETH',
      trdas: 'BQX',
      scnt: 1,
      pdec: -1,
      sdec: -1,
      min: 0.005
    },
    {
      tk: 'KNCBTC',
      balas: 'BTC',
      trdas: 'KNC',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'KNCETH',
      balas: 'ETH',
      trdas: 'KNC',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'FUNBTC',
      balas: 'BTC',
      trdas: 'FUN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'FUNETH',
      balas: 'ETH',
      trdas: 'FUN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'SNMBTC',
      balas: 'BTC',
      trdas: 'SNM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'SNMETH',
      balas: 'ETH',
      trdas: 'SNM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'NEOETH',
      balas: 'ETH',
      trdas: 'NEO',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'IOTABTC',
      balas: 'BTC',
      trdas: 'IOTA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'IOTAETH',
      balas: 'ETH',
      trdas: 'IOTA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'LINKBTC',
      balas: 'BTC',
      trdas: 'LINK',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'LINKETH',
      balas: 'ETH',
      trdas: 'LINK',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'XVGBTC',
      balas: 'BTC',
      trdas: 'XVG',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'XVGETH',
      balas: 'ETH',
      trdas: 'XVG',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'SALTBTC',
      balas: 'BTC',
      trdas: 'SALT',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'SALTETH',
      balas: 'ETH',
      trdas: 'SALT',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'MDABTC',
      balas: 'BTC',
      trdas: 'MDA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'MDAETH',
      balas: 'ETH',
      trdas: 'MDA',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'MTLBTC',
      balas: 'BTC',
      trdas: 'MTL',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'MTLETH',
      balas: 'ETH',
      trdas: 'MTL',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'SUBBTC',
      balas: 'BTC',
      trdas: 'SUB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'SUBETH',
      balas: 'ETH',
      trdas: 'SUB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'EOSBTC',
      balas: 'BTC',
      trdas: 'EOS',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SNTBTC',
      balas: 'BTC',
      trdas: 'SNT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ETCETH',
      balas: 'ETH',
      trdas: 'ETC',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'ETCBTC',
      balas: 'BTC',
      trdas: 'ETC',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'MTHBTC',
      balas: 'BTC',
      trdas: 'MTH',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'MTHETH',
      balas: 'ETH',
      trdas: 'MTH',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'ENGBTC',
      balas: 'BTC',
      trdas: 'ENG',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ENGETH',
      balas: 'ETH',
      trdas: 'ENG',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'DNTBTC',
      balas: 'BTC',
      trdas: 'DNT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ZECBTC',
      balas: 'BTC',
      trdas: 'ZEC',
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'ZECETH',
      balas: 'ETH',
      trdas: 'ZEC',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'BNTBTC',
      balas: 'BTC',
      trdas: 'BNT',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ASTBTC',
      balas: 'BTC',
      trdas: 'AST',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ASTETH',
      balas: 'ETH',
      trdas: 'AST',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'DASHBTC',
      balas: 'BTC',
      trdas: 'DASH',
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'DASHETH',
      balas: 'ETH',
      trdas: 'DASH',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'OAXBTC',
      balas: 'BTC',
      trdas: 'OAX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ICNBTC',
      balas: 'BTC',
      trdas: 'ICN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BTGBTC',
      balas: 'BTC',
      trdas: 'BTG',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'BTGETH',
      balas: 'ETH',
      trdas: 'BTG',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'EVXBTC',
      balas: 'BTC',
      trdas: 'EVX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'EVXETH',
      balas: 'ETH',
      trdas: 'EVX',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'REQBTC',
      balas: 'BTC',
      trdas: 'REQ',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'REQETH',
      balas: 'ETH',
      trdas: 'REQ',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'VIBBTC',
      balas: 'BTC',
      trdas: 'VIB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'VIBETH',
      balas: 'ETH',
      trdas: 'VIB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'HSRETH',
      balas: 'ETH',
      trdas: 'HSR',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'TRXBTC',
      balas: 'BTC',
      trdas: 'TRX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'TRXETH',
      balas: 'ETH',
      trdas: 'TRX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'POWRBTC',
      balas: 'BTC',
      trdas: 'POWR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'POWRETH',
      balas: 'ETH',
      trdas: 'POWR',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'ARKBTC',
      balas: 'BTC',
      trdas: 'ARK',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ARKETH',
      balas: 'ETH',
      trdas: 'ARK',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'YOYOETH',
      balas: 'ETH',
      trdas: 'YOYO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'XRPBTC',
      balas: 'BTC',
      trdas: 'XRP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'XRPETH',
      balas: 'ETH',
      trdas: 'XRP',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'MODBTC',
      balas: 'BTC',
      trdas: 'MOD',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'MODETH',
      balas: 'ETH',
      trdas: 'MOD',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'ENJBTC',
      balas: 'BTC',
      trdas: 'ENJ',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ENJETH',
      balas: 'ETH',
      trdas: 'ENJ',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'STORJBTC',
      balas: 'BTC',
      trdas: 'STORJ',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'STORJETH',
      balas: 'ETH',
      trdas: 'STORJ',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'BNBUSDT',
      balas: 'USDT',
      trdas: 'BNB',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'VENBNB',
      balas: 'BNB',
      trdas: 'VEN',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'YOYOBNB',
      balas: 'BNB',
      trdas: 'YOYO',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'POWRBNB',
      balas: 'BNB',
      trdas: 'POWR',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'VENBTC',
      balas: 'BTC',
      trdas: 'VEN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'VENETH',
      balas: 'ETH',
      trdas: 'VEN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'KMDBTC',
      balas: 'BTC',
      trdas: 'KMD',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'KMDETH',
      balas: 'ETH',
      trdas: 'KMD',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'NULSBNB',
      balas: 'BNB',
      trdas: 'NULS',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'RCNBTC',
      balas: 'BTC',
      trdas: 'RCN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'RCNETH',
      balas: 'ETH',
      trdas: 'RCN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'RCNBNB',
      balas: 'BNB',
      trdas: 'RCN',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'NULSBTC',
      balas: 'BTC',
      trdas: 'NULS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'NULSETH',
      balas: 'ETH',
      trdas: 'NULS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'RDNBTC',
      balas: 'BTC',
      trdas: 'RDN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'RDNETH',
      balas: 'ETH',
      trdas: 'RDN',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'RDNBNB',
      balas: 'BNB',
      trdas: 'RDN',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'XMRBTC',
      balas: 'BTC',
      trdas: 'XMR',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'XMRETH',
      balas: 'ETH',
      trdas: 'XMR',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'DLTBNB',
      balas: 'BNB',
      trdas: 'DLT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'WTCBNB',
      balas: 'BNB',
      trdas: 'WTC',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'DLTBTC',
      balas: 'BTC',
      trdas: 'DLT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'DLTETH',
      balas: 'ETH',
      trdas: 'DLT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'AMBBTC',
      balas: 'BTC',
      trdas: 'AMB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'AMBETH',
      balas: 'ETH',
      trdas: 'AMB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'AMBBNB',
      balas: 'BNB',
      trdas: 'AMB',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'BCCETH',
      balas: 'ETH',
      trdas: 'BCC',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.005
    },
    {
      tk: 'BCCUSDT',
      balas: 'USDT',
      trdas: 'BCC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BCCBNB',
      balas: 'BNB',
      trdas: 'BCC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 0.05
    },
    {
      tk: 'BATBTC',
      balas: 'BTC',
      trdas: 'BAT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BATETH',
      balas: 'ETH',
      trdas: 'BAT',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'BATBNB',
      balas: 'BNB',
      trdas: 'BAT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'BCPTBTC',
      balas: 'BTC',
      trdas: 'BCPT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BCPTETH',
      balas: 'ETH',
      trdas: 'BCPT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'BCPTBNB',
      balas: 'BNB',
      trdas: 'BCPT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'ARNBTC',
      balas: 'BTC',
      trdas: 'ARN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ARNETH',
      balas: 'ETH',
      trdas: 'ARN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'GVTBTC',
      balas: 'BTC',
      trdas: 'GVT',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'GVTETH',
      balas: 'ETH',
      trdas: 'GVT',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'CDTBTC',
      balas: 'BTC',
      trdas: 'CDT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'CDTETH',
      balas: 'ETH',
      trdas: 'CDT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'GXSBTC',
      balas: 'BTC',
      trdas: 'GXS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'GXSETH',
      balas: 'ETH',
      trdas: 'GXS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'NEOUSDT',
      balas: 'USDT',
      trdas: 'NEO',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'NEOBNB',
      balas: 'BNB',
      trdas: 'NEO',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'POEBTC',
      balas: 'BTC',
      trdas: 'POE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'POEETH',
      balas: 'ETH',
      trdas: 'POE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'QSPBTC',
      balas: 'BTC',
      trdas: 'QSP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'QSPETH',
      balas: 'ETH',
      trdas: 'QSP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'QSPBNB',
      balas: 'BNB',
      trdas: 'QSP',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'BTSBTC',
      balas: 'BTC',
      trdas: 'BTS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BTSETH',
      balas: 'ETH',
      trdas: 'BTS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'BTSBNB',
      balas: 'BNB',
      trdas: 'BTS',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'XZCBTC',
      balas: 'BTC',
      trdas: 'XZC',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'XZCETH',
      balas: 'ETH',
      trdas: 'XZC',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'XZCBNB',
      balas: 'BNB',
      trdas: 'XZC',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'LSKBTC',
      balas: 'BTC',
      trdas: 'LSK',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'LSKETH',
      balas: 'ETH',
      trdas: 'LSK',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'LSKBNB',
      balas: 'BNB',
      trdas: 'LSK',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'TNTBTC',
      balas: 'BTC',
      trdas: 'TNT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'TNTETH',
      balas: 'ETH',
      trdas: 'TNT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'FUELBTC',
      balas: 'BTC',
      trdas: 'FUEL',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'FUELETH',
      balas: 'ETH',
      trdas: 'FUEL',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'MANABTC',
      balas: 'BTC',
      trdas: 'MANA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'MANAETH',
      balas: 'ETH',
      trdas: 'MANA',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'BCDBTC',
      balas: 'BTC',
      trdas: 'BCD',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'BCDETH',
      balas: 'ETH',
      trdas: 'BCD',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.005
    },
    {
      tk: 'DGDBTC',
      balas: 'BTC',
      trdas: 'DGD',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'DGDETH',
      balas: 'ETH',
      trdas: 'DGD',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.005
    },
    {
      tk: 'IOTABNB',
      balas: 'BNB',
      trdas: 'IOTA',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'ADXBTC',
      balas: 'BTC',
      trdas: 'ADX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ADXETH',
      balas: 'ETH',
      trdas: 'ADX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'ADXBNB',
      balas: 'BNB',
      trdas: 'ADX',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'ADABTC',
      balas: 'BTC',
      trdas: 'ADA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ADAETH',
      balas: 'ETH',
      trdas: 'ADA',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'PPTBTC',
      balas: 'BTC',
      trdas: 'PPT',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'PPTETH',
      balas: 'ETH',
      trdas: 'PPT',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'CMTBTC',
      balas: 'BTC',
      trdas: 'CMT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'CMTETH',
      balas: 'ETH',
      trdas: 'CMT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'CMTBNB',
      balas: 'BNB',
      trdas: 'CMT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'XLMBTC',
      balas: 'BTC',
      trdas: 'XLM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'XLMETH',
      balas: 'ETH',
      trdas: 'XLM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'XLMBNB',
      balas: 'BNB',
      trdas: 'XLM',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'CNDBTC',
      balas: 'BTC',
      trdas: 'CND',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'CNDETH',
      balas: 'ETH',
      trdas: 'CND',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'CNDBNB',
      balas: 'BNB',
      trdas: 'CND',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'LENDBTC',
      balas: 'BTC',
      trdas: 'LEND',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'LENDETH',
      balas: 'ETH',
      trdas: 'LEND',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'WABIBTC',
      balas: 'BTC',
      trdas: 'WABI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'WABIETH',
      balas: 'ETH',
      trdas: 'WABI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'WABIBNB',
      balas: 'BNB',
      trdas: 'WABI',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'LTCETH',
      balas: 'ETH',
      trdas: 'LTC',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'LTCUSDT',
      balas: 'USDT',
      trdas: 'LTC',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'LTCBNB',
      balas: 'BNB',
      trdas: 'LTC',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.01
    },
    {
      tk: 'TNBBTC',
      balas: 'BTC',
      trdas: 'TNB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'TNBETH',
      balas: 'ETH',
      trdas: 'TNB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'WAVESBTC',
      balas: 'BTC',
      trdas: 'WAVES',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'WAVESETH',
      balas: 'ETH',
      trdas: 'WAVES',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'WAVESBNB',
      balas: 'BNB',
      trdas: 'WAVES',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'GTOBTC',
      balas: 'BTC',
      trdas: 'GTO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'GTOETH',
      balas: 'ETH',
      trdas: 'GTO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'GTOBNB',
      balas: 'BNB',
      trdas: 'GTO',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'ICXBTC',
      balas: 'BTC',
      trdas: 'ICX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ICXETH',
      balas: 'ETH',
      trdas: 'ICX',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'ICXBNB',
      balas: 'BNB',
      trdas: 'ICX',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'OSTBTC',
      balas: 'BTC',
      trdas: 'OST',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'OSTETH',
      balas: 'ETH',
      trdas: 'OST',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'OSTBNB',
      balas: 'BNB',
      trdas: 'OST',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'ELFBTC',
      balas: 'BTC',
      trdas: 'ELF',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ELFETH',
      balas: 'ETH',
      trdas: 'ELF',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'AIONBTC',
      balas: 'BTC',
      trdas: 'AION',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'AIONETH',
      balas: 'ETH',
      trdas: 'AION',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'AIONBNB',
      balas: 'BNB',
      trdas: 'AION',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'NEBLBTC',
      balas: 'BTC',
      trdas: 'NEBL',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'NEBLBNB',
      balas: 'BNB',
      trdas: 'NEBL',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'BRDBTC',
      balas: 'BTC',
      trdas: 'BRD',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BRDETH',
      balas: 'ETH',
      trdas: 'BRD',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'BRDBNB',
      balas: 'BNB',
      trdas: 'BRD',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'MCOBNB',
      balas: 'BNB',
      trdas: 'MCO',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'EDOBTC',
      balas: 'BTC',
      trdas: 'EDO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'EDOETH',
      balas: 'ETH',
      trdas: 'EDO',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'WINGSBTC',
      balas: 'BTC',
      trdas: 'WINGS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'WINGSETH',
      balas: 'ETH',
      trdas: 'WINGS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'NAVBTC',
      balas: 'BTC',
      trdas: 'NAV',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'NAVETH',
      balas: 'ETH',
      trdas: 'NAV',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'NAVBNB',
      balas: 'BNB',
      trdas: 'NAV',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'LUNBTC',
      balas: 'BTC',
      trdas: 'LUN',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'LUNETH',
      balas: 'ETH',
      trdas: 'LUN',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'TRIGBTC',
      balas: 'BTC',
      trdas: 'TRIG',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'TRIGETH',
      balas: 'ETH',
      trdas: 'TRIG',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'TRIGBNB',
      balas: 'BNB',
      trdas: 'TRIG',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'APPCBTC',
      balas: 'BTC',
      trdas: 'APPC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'APPCETH',
      balas: 'ETH',
      trdas: 'APPC',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'APPCBNB',
      balas: 'BNB',
      trdas: 'APPC',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'VIBEBTC',
      balas: 'BTC',
      trdas: 'VIBE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'VIBEETH',
      balas: 'ETH',
      trdas: 'VIBE',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'RLCBTC',
      balas: 'BTC',
      trdas: 'RLC',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'RLCETH',
      balas: 'ETH',
      trdas: 'RLC',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'RLCBNB',
      balas: 'BNB',
      trdas: 'RLC',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'INSBTC',
      balas: 'BTC',
      trdas: 'INS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'INSETH',
      balas: 'ETH',
      trdas: 'INS',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'PIVXBTC',
      balas: 'BTC',
      trdas: 'PIVX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'PIVXBNB',
      balas: 'BNB',
      trdas: 'PIVX',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'IOSTBTC',
      balas: 'BTC',
      trdas: 'IOST',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'IOSTETH',
      balas: 'ETH',
      trdas: 'IOST',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'CHATBTC',
      balas: 'BTC',
      trdas: 'CHAT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'CHATETH',
      balas: 'ETH',
      trdas: 'CHAT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'STEEMBTC',
      balas: 'BTC',
      trdas: 'STEEM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'STEEMETH',
      balas: 'ETH',
      trdas: 'STEEM',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'STEEMBNB',
      balas: 'BNB',
      trdas: 'STEEM',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'NANOBTC',
      balas: 'BTC',
      trdas: 'NANO',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'NANOETH',
      balas: 'ETH',
      trdas: 'NANO',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'NANOBNB',
      balas: 'BNB',
      trdas: 'NANO',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'VIABTC',
      balas: 'BTC',
      trdas: 'VIA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'VIAETH',
      balas: 'ETH',
      trdas: 'VIA',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'VIABNB',
      balas: 'BNB',
      trdas: 'VIA',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'BLZBTC',
      balas: 'BTC',
      trdas: 'BLZ',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BLZETH',
      balas: 'ETH',
      trdas: 'BLZ',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'BLZBNB',
      balas: 'BNB',
      trdas: 'BLZ',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'AEBTC',
      balas: 'BTC',
      trdas: 'AE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'AEETH',
      balas: 'ETH',
      trdas: 'AE',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'AEBNB',
      balas: 'BNB',
      trdas: 'AE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'RPXBTC',
      balas: 'BTC',
      trdas: 'RPX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'RPXETH',
      balas: 'ETH',
      trdas: 'RPX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'RPXBNB',
      balas: 'BNB',
      trdas: 'RPX',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'NCASHBTC',
      balas: 'BTC',
      trdas: 'NCASH',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'NCASHETH',
      balas: 'ETH',
      trdas: 'NCASH',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'NCASHBNB',
      balas: 'BNB',
      trdas: 'NCASH',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'POABTC',
      balas: 'BTC',
      trdas: 'POA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'POAETH',
      balas: 'ETH',
      trdas: 'POA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'POABNB',
      balas: 'BNB',
      trdas: 'POA',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'ZILBTC',
      balas: 'BTC',
      trdas: 'ZIL',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ZILETH',
      balas: 'ETH',
      trdas: 'ZIL',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'ZILBNB',
      balas: 'BNB',
      trdas: 'ZIL',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'ONTBTC',
      balas: 'BTC',
      trdas: 'ONT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ONTETH',
      balas: 'ETH',
      trdas: 'ONT',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'ONTBNB',
      balas: 'BNB',
      trdas: 'ONT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'STORMBTC',
      balas: 'BTC',
      trdas: 'STORM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'STORMETH',
      balas: 'ETH',
      trdas: 'STORM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'STORMBNB',
      balas: 'BNB',
      trdas: 'STORM',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'QTUMBNB',
      balas: 'BNB',
      trdas: 'QTUM',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'QTUMUSDT',
      balas: 'USDT',
      trdas: 'QTUM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'XEMBTC',
      balas: 'BTC',
      trdas: 'XEM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'XEMETH',
      balas: 'ETH',
      trdas: 'XEM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'XEMBNB',
      balas: 'BNB',
      trdas: 'XEM',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'WANBTC',
      balas: 'BTC',
      trdas: 'WAN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'WANETH',
      balas: 'ETH',
      trdas: 'WAN',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'WANBNB',
      balas: 'BNB',
      trdas: 'WAN',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'WPRBTC',
      balas: 'BTC',
      trdas: 'WPR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'WPRETH',
      balas: 'ETH',
      trdas: 'WPR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'QLCBTC',
      balas: 'BTC',
      trdas: 'QLC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'QLCETH',
      balas: 'ETH',
      trdas: 'QLC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'SYSBTC',
      balas: 'BTC',
      trdas: 'SYS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'SYSETH',
      balas: 'ETH',
      trdas: 'SYS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'SYSBNB',
      balas: 'BNB',
      trdas: 'SYS',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'QLCBNB',
      balas: 'BNB',
      trdas: 'QLC',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'GRSBTC',
      balas: 'BTC',
      trdas: 'GRS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'GRSETH',
      balas: 'ETH',
      trdas: 'GRS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'ADAUSDT',
      balas: 'USDT',
      trdas: 'ADA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ADABNB',
      balas: 'BNB',
      trdas: 'ADA',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'CLOAKBTC',
      balas: 'BTC',
      trdas: 'CLOAK',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'CLOAKETH',
      balas: 'ETH',
      trdas: 'CLOAK',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'GNTBTC',
      balas: 'BTC',
      trdas: 'GNT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'GNTETH',
      balas: 'ETH',
      trdas: 'GNT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'GNTBNB',
      balas: 'BNB',
      trdas: 'GNT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'LOOMBTC',
      balas: 'BTC',
      trdas: 'LOOM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'LOOMETH',
      balas: 'ETH',
      trdas: 'LOOM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'LOOMBNB',
      balas: 'BNB',
      trdas: 'LOOM',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'XRPUSDT',
      balas: 'USDT',
      trdas: 'XRP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BCNBTC',
      balas: 'BTC',
      trdas: 'BCN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BCNETH',
      balas: 'ETH',
      trdas: 'BCN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'BCNBNB',
      balas: 'BNB',
      trdas: 'BCN',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'REPBTC',
      balas: 'BTC',
      trdas: 'REP',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'REPBNB',
      balas: 'BNB',
      trdas: 'REP',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'BTCTUSD',
      balas: 'TUSD',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 5
    },
    {
      tk: 'TUSDBTC',
      balas: 'BTC',
      trdas: 'TUSD',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ETHTUSD',
      balas: 'TUSD',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 5
    },
    {
      tk: 'TUSDETH',
      balas: 'ETH',
      trdas: 'TUSD',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'TUSDBNB',
      balas: 'BNB',
      trdas: 'TUSD',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'ZENBTC',
      balas: 'BTC',
      trdas: 'ZEN',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ZENETH',
      balas: 'ETH',
      trdas: 'ZEN',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'ZENBNB',
      balas: 'BNB',
      trdas: 'ZEN',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'SKYBTC',
      balas: 'BTC',
      trdas: 'SKY',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SKYETH',
      balas: 'ETH',
      trdas: 'SKY',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.005
    },
    {
      tk: 'SKYBNB',
      balas: 'BNB',
      trdas: 'SKY',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'EOSUSDT',
      balas: 'USDT',
      trdas: 'EOS',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'EOSBNB',
      balas: 'BNB',
      trdas: 'EOS',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'CVCBTC',
      balas: 'BTC',
      trdas: 'CVC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'CVCETH',
      balas: 'ETH',
      trdas: 'CVC',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'CVCBNB',
      balas: 'BNB',
      trdas: 'CVC',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'THETABTC',
      balas: 'BTC',
      trdas: 'THETA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'THETAETH',
      balas: 'ETH',
      trdas: 'THETA',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'THETABNB',
      balas: 'BNB',
      trdas: 'THETA',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'XRPBNB',
      balas: 'BNB',
      trdas: 'XRP',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'TUSDUSDT',
      balas: 'USDT',
      trdas: 'TUSD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'IOTAUSDT',
      balas: 'USDT',
      trdas: 'IOTA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'XLMUSDT',
      balas: 'USDT',
      trdas: 'XLM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'IOTXBTC',
      balas: 'BTC',
      trdas: 'IOTX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'IOTXETH',
      balas: 'ETH',
      trdas: 'IOTX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'QKCBTC',
      balas: 'BTC',
      trdas: 'QKC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'QKCETH',
      balas: 'ETH',
      trdas: 'QKC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'AGIBTC',
      balas: 'BTC',
      trdas: 'AGI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'AGIETH',
      balas: 'ETH',
      trdas: 'AGI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'AGIBNB',
      balas: 'BNB',
      trdas: 'AGI',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'NXSBTC',
      balas: 'BTC',
      trdas: 'NXS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'NXSETH',
      balas: 'ETH',
      trdas: 'NXS',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'NXSBNB',
      balas: 'BNB',
      trdas: 'NXS',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'ENJBNB',
      balas: 'BNB',
      trdas: 'ENJ',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'DATABTC',
      balas: 'BTC',
      trdas: 'DATA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'DATAETH',
      balas: 'ETH',
      trdas: 'DATA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'ONTUSDT',
      balas: 'USDT',
      trdas: 'ONT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TRXBNB',
      balas: 'BNB',
      trdas: 'TRX',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'TRXUSDT',
      balas: 'USDT',
      trdas: 'TRX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ETCUSDT',
      balas: 'USDT',
      trdas: 'ETC',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ETCBNB',
      balas: 'BNB',
      trdas: 'ETC',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'ICXUSDT',
      balas: 'USDT',
      trdas: 'ICX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SCBTC',
      balas: 'BTC',
      trdas: 'SC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'SCETH',
      balas: 'ETH',
      trdas: 'SC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'NPXSBTC',
      balas: 'BTC',
      trdas: 'NPXS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'NPXSETH',
      balas: 'ETH',
      trdas: 'NPXS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'VENUSDT',
      balas: 'USDT',
      trdas: 'VEN',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'KEYBTC',
      balas: 'BTC',
      trdas: 'KEY',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'KEYETH',
      balas: 'ETH',
      trdas: 'KEY',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'NASBTC',
      balas: 'BTC',
      trdas: 'NAS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'NASETH',
      balas: 'ETH',
      trdas: 'NAS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'NASBNB',
      balas: 'BNB',
      trdas: 'NAS',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'MFTBTC',
      balas: 'BTC',
      trdas: 'MFT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'MFTETH',
      balas: 'ETH',
      trdas: 'MFT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'MFTBNB',
      balas: 'BNB',
      trdas: 'MFT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'DENTBTC',
      balas: 'BTC',
      trdas: 'DENT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'DENTETH',
      balas: 'ETH',
      trdas: 'DENT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'ARDRBTC',
      balas: 'BTC',
      trdas: 'ARDR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ARDRETH',
      balas: 'ETH',
      trdas: 'ARDR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'ARDRBNB',
      balas: 'BNB',
      trdas: 'ARDR',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'NULSUSDT',
      balas: 'USDT',
      trdas: 'NULS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HOTBTC',
      balas: 'BTC',
      trdas: 'HOT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'HOTETH',
      balas: 'ETH',
      trdas: 'HOT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'VETBTC',
      balas: 'BTC',
      trdas: 'VET',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'VETETH',
      balas: 'ETH',
      trdas: 'VET',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'VETUSDT',
      balas: 'USDT',
      trdas: 'VET',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'VETBNB',
      balas: 'BNB',
      trdas: 'VET',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'DOCKBTC',
      balas: 'BTC',
      trdas: 'DOCK',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'DOCKETH',
      balas: 'ETH',
      trdas: 'DOCK',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'POLYBTC',
      balas: 'BTC',
      trdas: 'POLY',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'POLYBNB',
      balas: 'BNB',
      trdas: 'POLY',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'PHXBTC',
      balas: 'BTC',
      trdas: 'PHX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'PHXETH',
      balas: 'ETH',
      trdas: 'PHX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'PHXBNB',
      balas: 'BNB',
      trdas: 'PHX',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'HCBTC',
      balas: 'BTC',
      trdas: 'HC',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'HCETH',
      balas: 'ETH',
      trdas: 'HC',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'GOBTC',
      balas: 'BTC',
      trdas: 'GO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'GOBNB',
      balas: 'BNB',
      trdas: 'GO',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'PAXBTC',
      balas: 'BTC',
      trdas: 'PAX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'PAXBNB',
      balas: 'BNB',
      trdas: 'PAX',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'PAXUSDT',
      balas: 'USDT',
      trdas: 'PAX',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PAXETH',
      balas: 'ETH',
      trdas: 'PAX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'RVNBTC',
      balas: 'BTC',
      trdas: 'RVN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'DCRBTC',
      balas: 'BTC',
      trdas: 'DCR',
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'DCRBNB',
      balas: 'BNB',
      trdas: 'DCR',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'USDCBNB',
      balas: 'BNB',
      trdas: 'USDC',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'MITHBTC',
      balas: 'BTC',
      trdas: 'MITH',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'MITHBNB',
      balas: 'BNB',
      trdas: 'MITH',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'BCHABCBTC',
      balas: 'BTC',
      trdas: 'BCHABC',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'BCHSVBTC',
      balas: 'BTC',
      trdas: 'BCHSV',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'BCHABCUSDT',
      balas: 'USDT',
      trdas: 'BCHABC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BCHSVUSDT',
      balas: 'USDT',
      trdas: 'BCHSV',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BNBPAX',
      balas: 'PAX',
      trdas: 'BNB',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'BTCPAX',
      balas: 'PAX',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'ETHPAX',
      balas: 'PAX',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 10
    },
    {
      tk: 'XRPPAX',
      balas: 'PAX',
      trdas: 'XRP',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'EOSPAX',
      balas: 'PAX',
      trdas: 'EOS',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XLMPAX',
      balas: 'PAX',
      trdas: 'XLM',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'RENBTC',
      balas: 'BTC',
      trdas: 'REN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'RENBNB',
      balas: 'BNB',
      trdas: 'REN',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'BNBTUSD',
      balas: 'TUSD',
      trdas: 'BNB',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'XRPTUSD',
      balas: 'TUSD',
      trdas: 'XRP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'EOSTUSD',
      balas: 'TUSD',
      trdas: 'EOS',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XLMTUSD',
      balas: 'TUSD',
      trdas: 'XLM',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BNBUSDC',
      balas: 'USDC',
      trdas: 'BNB',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'BTCUSDC',
      balas: 'USDC',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 5
    },
    {
      tk: 'ETHUSDC',
      balas: 'USDC',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 5
    },
    {
      tk: 'XRPUSDC',
      balas: 'USDC',
      trdas: 'XRP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'EOSUSDC',
      balas: 'USDC',
      trdas: 'EOS',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'XLMUSDC',
      balas: 'USDC',
      trdas: 'XLM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'USDCUSDT',
      balas: 'USDT',
      trdas: 'USDC',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ADATUSD',
      balas: 'TUSD',
      trdas: 'ADA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TRXTUSD',
      balas: 'TUSD',
      trdas: 'TRX',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'NEOTUSD',
      balas: 'TUSD',
      trdas: 'NEO',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'TRXXRP',
      balas: 'XRP',
      trdas: 'TRX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'XZCXRP',
      balas: 'XRP',
      trdas: 'XZC',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'PAXTUSD',
      balas: 'TUSD',
      trdas: 'PAX',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'USDCTUSD',
      balas: 'TUSD',
      trdas: 'USDC',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'USDCPAX',
      balas: 'PAX',
      trdas: 'USDC',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LINKUSDT',
      balas: 'USDT',
      trdas: 'LINK',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'LINKTUSD',
      balas: 'TUSD',
      trdas: 'LINK',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'LINKPAX',
      balas: 'PAX',
      trdas: 'LINK',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LINKUSDC',
      balas: 'USDC',
      trdas: 'LINK',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'WAVESUSDT',
      balas: 'USDT',
      trdas: 'WAVES',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'WAVESTUSD',
      balas: 'TUSD',
      trdas: 'WAVES',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'WAVESPAX',
      balas: 'PAX',
      trdas: 'WAVES',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'WAVESUSDC',
      balas: 'USDC',
      trdas: 'WAVES',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BCHABCTUSD',
      balas: 'TUSD',
      trdas: 'BCHABC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BCHABCPAX',
      balas: 'PAX',
      trdas: 'BCHABC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BCHABCUSDC',
      balas: 'USDC',
      trdas: 'BCHABC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BCHSVTUSD',
      balas: 'TUSD',
      trdas: 'BCHSV',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BCHSVPAX',
      balas: 'PAX',
      trdas: 'BCHSV',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BCHSVUSDC',
      balas: 'USDC',
      trdas: 'BCHSV',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'LTCTUSD',
      balas: 'TUSD',
      trdas: 'LTC',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'LTCPAX',
      balas: 'PAX',
      trdas: 'LTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'LTCUSDC',
      balas: 'USDC',
      trdas: 'LTC',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'TRXPAX',
      balas: 'PAX',
      trdas: 'TRX',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'TRXUSDC',
      balas: 'USDC',
      trdas: 'TRX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BTTBTC',
      balas: 'BTC',
      trdas: 'BTT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BTTBNB',
      balas: 'BNB',
      trdas: 'BTT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'BTTUSDT',
      balas: 'USDT',
      trdas: 'BTT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BNBUSDS',
      balas: 'USDS',
      trdas: 'BNB',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BTCUSDS',
      balas: 'USDS',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 10
    },
    {
      tk: 'USDSUSDT',
      balas: 'USDT',
      trdas: 'USDS',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'USDSPAX',
      balas: 'PAX',
      trdas: 'USDS',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'USDSTUSD',
      balas: 'TUSD',
      trdas: 'USDS',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'USDSUSDC',
      balas: 'USDC',
      trdas: 'USDS',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BTTPAX',
      balas: 'PAX',
      trdas: 'BTT',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BTTTUSD',
      balas: 'TUSD',
      trdas: 'BTT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BTTUSDC',
      balas: 'USDC',
      trdas: 'BTT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ONGBNB',
      balas: 'BNB',
      trdas: 'ONG',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'ONGBTC',
      balas: 'BTC',
      trdas: 'ONG',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ONGUSDT',
      balas: 'USDT',
      trdas: 'ONG',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HOTBNB',
      balas: 'BNB',
      trdas: 'HOT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'HOTUSDT',
      balas: 'USDT',
      trdas: 'HOT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ZILUSDT',
      balas: 'USDT',
      trdas: 'ZIL',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ZRXBNB',
      balas: 'BNB',
      trdas: 'ZRX',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'ZRXUSDT',
      balas: 'USDT',
      trdas: 'ZRX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FETBNB',
      balas: 'BNB',
      trdas: 'FET',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'FETBTC',
      balas: 'BTC',
      trdas: 'FET',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'FETUSDT',
      balas: 'USDT',
      trdas: 'FET',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BATUSDT',
      balas: 'USDT',
      trdas: 'BAT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'XMRBNB',
      balas: 'BNB',
      trdas: 'XMR',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.01
    },
    {
      tk: 'XMRUSDT',
      balas: 'USDT',
      trdas: 'XMR',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'ZECBNB',
      balas: 'BNB',
      trdas: 'ZEC',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'ZECUSDT',
      balas: 'USDT',
      trdas: 'ZEC',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'ZECPAX',
      balas: 'PAX',
      trdas: 'ZEC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'ZECTUSD',
      balas: 'TUSD',
      trdas: 'ZEC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'ZECUSDC',
      balas: 'USDC',
      trdas: 'ZEC',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'IOSTUSDT',
      balas: 'USDT',
      trdas: 'IOST',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CELRBNB',
      balas: 'BNB',
      trdas: 'CELR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'CELRBTC',
      balas: 'BTC',
      trdas: 'CELR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'CELRUSDT',
      balas: 'USDT',
      trdas: 'CELR',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ADAPAX',
      balas: 'PAX',
      trdas: 'ADA',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ADAUSDC',
      balas: 'USDC',
      trdas: 'ADA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'NEOPAX',
      balas: 'PAX',
      trdas: 'NEO',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'NEOUSDC',
      balas: 'USDC',
      trdas: 'NEO',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'DASHBNB',
      balas: 'BNB',
      trdas: 'DASH',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'DASHUSDT',
      balas: 'USDT',
      trdas: 'DASH',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'NANOUSDT',
      balas: 'USDT',
      trdas: 'NANO',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'OMGBNB',
      balas: 'BNB',
      trdas: 'OMG',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'OMGUSDT',
      balas: 'USDT',
      trdas: 'OMG',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'THETAUSDT',
      balas: 'USDT',
      trdas: 'THETA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ENJUSDT',
      balas: 'USDT',
      trdas: 'ENJ',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MITHUSDT',
      balas: 'USDT',
      trdas: 'MITH',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MATICBNB',
      balas: 'BNB',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'MATICBTC',
      balas: 'BTC',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'MATICUSDT',
      balas: 'USDT',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ATOMBNB',
      balas: 'BNB',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'ATOMBTC',
      balas: 'BTC',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ATOMUSDT',
      balas: 'USDT',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ATOMUSDC',
      balas: 'USDC',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ATOMPAX',
      balas: 'PAX',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'ATOMTUSD',
      balas: 'TUSD',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'ETCUSDC',
      balas: 'USDC',
      trdas: 'ETC',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'ETCPAX',
      balas: 'PAX',
      trdas: 'ETC',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'ETCTUSD',
      balas: 'TUSD',
      trdas: 'ETC',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'BATUSDC',
      balas: 'USDC',
      trdas: 'BAT',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BATPAX',
      balas: 'PAX',
      trdas: 'BAT',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BATTUSD',
      balas: 'TUSD',
      trdas: 'BAT',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PHBBNB',
      balas: 'BNB',
      trdas: 'PHB',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'PHBBTC',
      balas: 'BTC',
      trdas: 'PHB',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'PHBUSDC',
      balas: 'USDC',
      trdas: 'PHB',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'PHBTUSD',
      balas: 'TUSD',
      trdas: 'PHB',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'PHBPAX',
      balas: 'PAX',
      trdas: 'PHB',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'TFUELBNB',
      balas: 'BNB',
      trdas: 'TFUEL',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'TFUELBTC',
      balas: 'BTC',
      trdas: 'TFUEL',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'TFUELUSDT',
      balas: 'USDT',
      trdas: 'TFUEL',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TFUELUSDC',
      balas: 'USDC',
      trdas: 'TFUEL',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'TFUELTUSD',
      balas: 'TUSD',
      trdas: 'TFUEL',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'TFUELPAX',
      balas: 'PAX',
      trdas: 'TFUEL',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ONEBNB',
      balas: 'BNB',
      trdas: 'ONE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'ONEBTC',
      balas: 'BTC',
      trdas: 'ONE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ONEUSDT',
      balas: 'USDT',
      trdas: 'ONE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ONETUSD',
      balas: 'TUSD',
      trdas: 'ONE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ONEPAX',
      balas: 'PAX',
      trdas: 'ONE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ONEUSDC',
      balas: 'USDC',
      trdas: 'ONE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'FTMBNB',
      balas: 'BNB',
      trdas: 'FTM',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'FTMBTC',
      balas: 'BTC',
      trdas: 'FTM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'FTMUSDT',
      balas: 'USDT',
      trdas: 'FTM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FTMTUSD',
      balas: 'TUSD',
      trdas: 'FTM',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'FTMPAX',
      balas: 'PAX',
      trdas: 'FTM',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'FTMUSDC',
      balas: 'USDC',
      trdas: 'FTM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BTCBBTC',
      balas: 'BTC',
      trdas: 'BTCB',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'BCPTTUSD',
      balas: 'TUSD',
      trdas: 'BCPT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BCPTPAX',
      balas: 'PAX',
      trdas: 'BCPT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BCPTUSDC',
      balas: 'USDC',
      trdas: 'BCPT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ALGOBNB',
      balas: 'BNB',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'ALGOBTC',
      balas: 'BTC',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ALGOUSDT',
      balas: 'USDT',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ALGOTUSD',
      balas: 'TUSD',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ALGOPAX',
      balas: 'PAX',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ALGOUSDC',
      balas: 'USDC',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'USDSBUSDT',
      balas: 'USDT',
      trdas: 'USDSB',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'USDSBUSDS',
      balas: 'USDS',
      trdas: 'USDSB',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'GTOUSDT',
      balas: 'USDT',
      trdas: 'GTO',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'GTOPAX',
      balas: 'PAX',
      trdas: 'GTO',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'GTOTUSD',
      balas: 'TUSD',
      trdas: 'GTO',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'GTOUSDC',
      balas: 'USDC',
      trdas: 'GTO',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ERDBNB',
      balas: 'BNB',
      trdas: 'ERD',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'ERDBTC',
      balas: 'BTC',
      trdas: 'ERD',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ERDUSDT',
      balas: 'USDT',
      trdas: 'ERD',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ERDPAX',
      balas: 'PAX',
      trdas: 'ERD',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ERDUSDC',
      balas: 'USDC',
      trdas: 'ERD',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'DOGEBNB',
      balas: 'BNB',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'DOGEBTC',
      balas: 'BTC',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'DOGEUSDT',
      balas: 'USDT',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'DOGEPAX',
      balas: 'PAX',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'DOGEUSDC',
      balas: 'USDC',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'DUSKBNB',
      balas: 'BNB',
      trdas: 'DUSK',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'DUSKBTC',
      balas: 'BTC',
      trdas: 'DUSK',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'DUSKUSDT',
      balas: 'USDT',
      trdas: 'DUSK',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DUSKUSDC',
      balas: 'USDC',
      trdas: 'DUSK',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'DUSKPAX',
      balas: 'PAX',
      trdas: 'DUSK',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BGBPUSDC',
      balas: 'USDC',
      trdas: 'BGBP',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ANKRBNB',
      balas: 'BNB',
      trdas: 'ANKR',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'ANKRBTC',
      balas: 'BTC',
      trdas: 'ANKR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ANKRUSDT',
      balas: 'USDT',
      trdas: 'ANKR',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ANKRTUSD',
      balas: 'TUSD',
      trdas: 'ANKR',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ANKRPAX',
      balas: 'PAX',
      trdas: 'ANKR',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ANKRUSDC',
      balas: 'USDC',
      trdas: 'ANKR',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ONTPAX',
      balas: 'PAX',
      trdas: 'ONT',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ONTUSDC',
      balas: 'USDC',
      trdas: 'ONT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'WINBNB',
      balas: 'BNB',
      trdas: 'WIN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'WINBTC',
      balas: 'BTC',
      trdas: 'WIN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'WINUSDT',
      balas: 'USDT',
      trdas: 'WIN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 5
    },
    {
      tk: 'WINUSDC',
      balas: 'USDC',
      trdas: 'WIN',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'COSBNB',
      balas: 'BNB',
      trdas: 'COS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'COSBTC',
      balas: 'BTC',
      trdas: 'COS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'COSUSDT',
      balas: 'USDT',
      trdas: 'COS',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TUSDBTUSD',
      balas: 'TUSD',
      trdas: 'TUSDB',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'NPXSUSDT',
      balas: 'USDT',
      trdas: 'NPXS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'NPXSUSDC',
      balas: 'USDC',
      trdas: 'NPXS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'COCOSBNB',
      balas: 'BNB',
      trdas: 'COCOS',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'COCOSBTC',
      balas: 'BTC',
      trdas: 'COCOS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'COCOSUSDT',
      balas: 'USDT',
      trdas: 'COCOS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'MTLUSDT',
      balas: 'USDT',
      trdas: 'MTL',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TOMOBNB',
      balas: 'BNB',
      trdas: 'TOMO',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'TOMOBTC',
      balas: 'BTC',
      trdas: 'TOMO',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'TOMOUSDT',
      balas: 'USDT',
      trdas: 'TOMO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TOMOUSDC',
      balas: 'USDC',
      trdas: 'TOMO',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PERLBNB',
      balas: 'BNB',
      trdas: 'PERL',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'PERLBTC',
      balas: 'BTC',
      trdas: 'PERL',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'PERLUSDC',
      balas: 'USDC',
      trdas: 'PERL',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'PERLUSDT',
      balas: 'USDT',
      trdas: 'PERL',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DENTUSDT',
      balas: 'USDT',
      trdas: 'DENT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MFTUSDT',
      balas: 'USDT',
      trdas: 'MFT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'KEYUSDT',
      balas: 'USDT',
      trdas: 'KEY',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'STORMUSDT',
      balas: 'USDT',
      trdas: 'STORM',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'DOCKUSDT',
      balas: 'USDT',
      trdas: 'DOCK',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'WANUSDT',
      balas: 'USDT',
      trdas: 'WAN',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FUNUSDT',
      balas: 'USDT',
      trdas: 'FUN',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CVCUSDT',
      balas: 'USDT',
      trdas: 'CVC',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BTTTRX',
      balas: 'TRX',
      trdas: 'BTT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 100
    },
    {
      tk: 'WINTRX',
      balas: 'TRX',
      trdas: 'WIN',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 100
    },
    {
      tk: 'CHZBNB',
      balas: 'BNB',
      trdas: 'CHZ',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'CHZBTC',
      balas: 'BTC',
      trdas: 'CHZ',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'CHZUSDT',
      balas: 'USDT',
      trdas: 'CHZ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BANDBNB',
      balas: 'BNB',
      trdas: 'BAND',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'BANDBTC',
      balas: 'BTC',
      trdas: 'BAND',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'BANDUSDT',
      balas: 'USDT',
      trdas: 'BAND',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BNBBUSD',
      balas: 'BUSD',
      trdas: 'BNB',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'BTCBUSD',
      balas: 'BUSD',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BUSDUSDT',
      balas: 'USDT',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BEAMBNB',
      balas: 'BNB',
      trdas: 'BEAM',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'BEAMBTC',
      balas: 'BTC',
      trdas: 'BEAM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BEAMUSDT',
      balas: 'USDT',
      trdas: 'BEAM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'XTZBNB',
      balas: 'BNB',
      trdas: 'XTZ',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'XTZBTC',
      balas: 'BTC',
      trdas: 'XTZ',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'XTZUSDT',
      balas: 'USDT',
      trdas: 'XTZ',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RENUSDT',
      balas: 'USDT',
      trdas: 'REN',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RVNUSDT',
      balas: 'USDT',
      trdas: 'RVN',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'HCUSDT',
      balas: 'USDT',
      trdas: 'HC',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'HBARBNB',
      balas: 'BNB',
      trdas: 'HBAR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'HBARBTC',
      balas: 'BTC',
      trdas: 'HBAR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'HBARUSDT',
      balas: 'USDT',
      trdas: 'HBAR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NKNBNB',
      balas: 'BNB',
      trdas: 'NKN',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'NKNBTC',
      balas: 'BTC',
      trdas: 'NKN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'NKNUSDT',
      balas: 'USDT',
      trdas: 'NKN',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'XRPBUSD',
      balas: 'BUSD',
      trdas: 'XRP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ETHBUSD',
      balas: 'BUSD',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 10
    },
    {
      tk: 'BCHABCBUSD',
      balas: 'BUSD',
      trdas: 'BCHABC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'LTCBUSD',
      balas: 'BUSD',
      trdas: 'LTC',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'LINKBUSD',
      balas: 'BUSD',
      trdas: 'LINK',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ETCBUSD',
      balas: 'BUSD',
      trdas: 'ETC',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'STXBNB',
      balas: 'BNB',
      trdas: 'STX',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'STXBTC',
      balas: 'BTC',
      trdas: 'STX',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'STXUSDT',
      balas: 'USDT',
      trdas: 'STX',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'KAVABNB',
      balas: 'BNB',
      trdas: 'KAVA',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'KAVABTC',
      balas: 'BTC',
      trdas: 'KAVA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'KAVAUSDT',
      balas: 'USDT',
      trdas: 'KAVA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BUSDNGN',
      balas: 'NGN',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 500
    },
    {
      tk: 'BNBNGN',
      balas: 'NGN',
      trdas: 'BNB',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 500
    },
    {
      tk: 'BTCNGN',
      balas: 'NGN',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 500
    },
    {
      tk: 'ARPABNB',
      balas: 'BNB',
      trdas: 'ARPA',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'ARPABTC',
      balas: 'BTC',
      trdas: 'ARPA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ARPAUSDT',
      balas: 'USDT',
      trdas: 'ARPA',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TRXBUSD',
      balas: 'BUSD',
      trdas: 'TRX',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'EOSBUSD',
      balas: 'BUSD',
      trdas: 'EOS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'IOTXUSDT',
      balas: 'USDT',
      trdas: 'IOTX',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RLCUSDT',
      balas: 'USDT',
      trdas: 'RLC',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MCOUSDT',
      balas: 'USDT',
      trdas: 'MCO',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'XLMBUSD',
      balas: 'BUSD',
      trdas: 'XLM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ADABUSD',
      balas: 'BUSD',
      trdas: 'ADA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CTXCBNB',
      balas: 'BNB',
      trdas: 'CTXC',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'CTXCBTC',
      balas: 'BTC',
      trdas: 'CTXC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'CTXCUSDT',
      balas: 'USDT',
      trdas: 'CTXC',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BCHBNB',
      balas: 'BNB',
      trdas: 'BCH',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 0.01
    },
    {
      tk: 'BCHBTC',
      balas: 'BTC',
      trdas: 'BCH',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'BCHUSDT',
      balas: 'USDT',
      trdas: 'BCH',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'BCHUSDC',
      balas: 'USDC',
      trdas: 'BCH',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'BCHTUSD',
      balas: 'TUSD',
      trdas: 'BCH',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'BCHPAX',
      balas: 'PAX',
      trdas: 'BCH',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BCHBUSD',
      balas: 'BUSD',
      trdas: 'BCH',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'BTCRUB',
      balas: 'RUB',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 100
    },
    {
      tk: 'ETHRUB',
      balas: 'RUB',
      trdas: 'ETH',
      scnt: 1,
      pdec: 1,
      sdec: 4,
      min: 100
    },
    {
      tk: 'XRPRUB',
      balas: 'RUB',
      trdas: 'XRP',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 100
    },
    {
      tk: 'BNBRUB',
      balas: 'RUB',
      trdas: 'BNB',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 100
    },
    {
      tk: 'TROYBNB',
      balas: 'BNB',
      trdas: 'TROY',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'TROYBTC',
      balas: 'BTC',
      trdas: 'TROY',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'TROYUSDT',
      balas: 'USDT',
      trdas: 'TROY',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BUSDRUB',
      balas: 'RUB',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 100
    },
    {
      tk: 'QTUMBUSD',
      balas: 'BUSD',
      trdas: 'QTUM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'VETBUSD',
      balas: 'BUSD',
      trdas: 'VET',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'VITEBNB',
      balas: 'BNB',
      trdas: 'VITE',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'VITEBTC',
      balas: 'BTC',
      trdas: 'VITE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'VITEUSDT',
      balas: 'USDT',
      trdas: 'VITE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'FTTBNB',
      balas: 'BNB',
      trdas: 'FTT',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'FTTBTC',
      balas: 'BTC',
      trdas: 'FTT',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'FTTUSDT',
      balas: 'USDT',
      trdas: 'FTT',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BTCTRY',
      balas: 'TRY',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BNBTRY',
      balas: 'TRY',
      trdas: 'BNB',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 10
    },
    {
      tk: 'BUSDTRY',
      balas: 'TRY',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ETHTRY',
      balas: 'TRY',
      trdas: 'ETH',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 10
    },
    {
      tk: 'XRPTRY',
      balas: 'TRY',
      trdas: 'XRP',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'USDTTRY',
      balas: 'TRY',
      trdas: 'USDT',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'USDTRUB',
      balas: 'RUB',
      trdas: 'USDT',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 100
    },
    {
      tk: 'BTCEUR',
      balas: 'EUR',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 5
    },
    {
      tk: 'ETHEUR',
      balas: 'EUR',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 5
    },
    {
      tk: 'BNBEUR',
      balas: 'EUR',
      trdas: 'BNB',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'XRPEUR',
      balas: 'EUR',
      trdas: 'XRP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'EURBUSD',
      balas: 'BUSD',
      trdas: 'EUR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'EURUSDT',
      balas: 'USDT',
      trdas: 'EUR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'OGNBNB',
      balas: 'BNB',
      trdas: 'OGN',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'OGNBTC',
      balas: 'BTC',
      trdas: 'OGN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'OGNUSDT',
      balas: 'USDT',
      trdas: 'OGN',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DREPBNB',
      balas: 'BNB',
      trdas: 'DREP',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'DREPBTC',
      balas: 'BTC',
      trdas: 'DREP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'DREPUSDT',
      balas: 'USDT',
      trdas: 'DREP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BULLUSDT',
      balas: 'USDT',
      trdas: 'BULL',
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 10
    },
    {
      tk: 'BULLBUSD',
      balas: 'BUSD',
      trdas: 'BULL',
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 10
    },
    {
      tk: 'BEARUSDT',
      balas: 'USDT',
      trdas: 'BEAR',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BEARBUSD',
      balas: 'BUSD',
      trdas: 'BEAR',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'ETHBULLUSDT',
      balas: 'USDT',
      trdas: 'ETHBULL',
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 10
    },
    {
      tk: 'ETHBULLBUSD',
      balas: 'BUSD',
      trdas: 'ETHBULL',
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 10
    },
    {
      tk: 'ETHBEARUSDT',
      balas: 'USDT',
      trdas: 'ETHBEAR',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'ETHBEARBUSD',
      balas: 'BUSD',
      trdas: 'ETHBEAR',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'TCTBNB',
      balas: 'BNB',
      trdas: 'TCT',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'TCTBTC',
      balas: 'BTC',
      trdas: 'TCT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'TCTUSDT',
      balas: 'USDT',
      trdas: 'TCT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'WRXBNB',
      balas: 'BNB',
      trdas: 'WRX',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'WRXBTC',
      balas: 'BTC',
      trdas: 'WRX',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'WRXUSDT',
      balas: 'USDT',
      trdas: 'WRX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ICXBUSD',
      balas: 'BUSD',
      trdas: 'ICX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BTSUSDT',
      balas: 'USDT',
      trdas: 'BTS',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BTSBUSD',
      balas: 'BUSD',
      trdas: 'BTS',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'LSKUSDT',
      balas: 'USDT',
      trdas: 'LSK',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BNTUSDT',
      balas: 'USDT',
      trdas: 'BNT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BNTBUSD',
      balas: 'BUSD',
      trdas: 'BNT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'LTOBNB',
      balas: 'BNB',
      trdas: 'LTO',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'LTOBTC',
      balas: 'BTC',
      trdas: 'LTO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'LTOUSDT',
      balas: 'USDT',
      trdas: 'LTO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ATOMBUSD',
      balas: 'BUSD',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'DASHBUSD',
      balas: 'BUSD',
      trdas: 'DASH',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'NEOBUSD',
      balas: 'BUSD',
      trdas: 'NEO',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'WAVESBUSD',
      balas: 'BUSD',
      trdas: 'WAVES',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XTZBUSD',
      balas: 'BUSD',
      trdas: 'XTZ',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'EOSBULLUSDT',
      balas: 'USDT',
      trdas: 'EOSBULL',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'EOSBULLBUSD',
      balas: 'BUSD',
      trdas: 'EOSBULL',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'EOSBEARUSDT',
      balas: 'USDT',
      trdas: 'EOSBEAR',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'EOSBEARBUSD',
      balas: 'BUSD',
      trdas: 'EOSBEAR',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'XRPBULLUSDT',
      balas: 'USDT',
      trdas: 'XRPBULL',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'XRPBULLBUSD',
      balas: 'BUSD',
      trdas: 'XRPBULL',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'XRPBEARUSDT',
      balas: 'USDT',
      trdas: 'XRPBEAR',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'XRPBEARBUSD',
      balas: 'BUSD',
      trdas: 'XRPBEAR',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BATBUSD',
      balas: 'BUSD',
      trdas: 'BAT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ENJBUSD',
      balas: 'BUSD',
      trdas: 'ENJ',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'NANOBUSD',
      balas: 'BUSD',
      trdas: 'NANO',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ONTBUSD',
      balas: 'BUSD',
      trdas: 'ONT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'RVNBUSD',
      balas: 'BUSD',
      trdas: 'RVN',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'STRATBUSD',
      balas: 'BUSD',
      trdas: 'STRAT',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'STRATBNB',
      balas: 'BNB',
      trdas: 'STRAT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'STRATUSDT',
      balas: 'USDT',
      trdas: 'STRAT',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'AIONBUSD',
      balas: 'BUSD',
      trdas: 'AION',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'AIONUSDT',
      balas: 'USDT',
      trdas: 'AION',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'MBLBNB',
      balas: 'BNB',
      trdas: 'MBL',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'MBLBTC',
      balas: 'BTC',
      trdas: 'MBL',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'MBLUSDT',
      balas: 'USDT',
      trdas: 'MBL',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'COTIBNB',
      balas: 'BNB',
      trdas: 'COTI',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'COTIBTC',
      balas: 'BTC',
      trdas: 'COTI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'COTIUSDT',
      balas: 'USDT',
      trdas: 'COTI',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ALGOBUSD',
      balas: 'BUSD',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BTTBUSD',
      balas: 'BUSD',
      trdas: 'BTT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'TOMOBUSD',
      balas: 'BUSD',
      trdas: 'TOMO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'XMRBUSD',
      balas: 'BUSD',
      trdas: 'XMR',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'ZECBUSD',
      balas: 'BUSD',
      trdas: 'ZEC',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'BNBBULLUSDT',
      balas: 'USDT',
      trdas: 'BNBBULL',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BNBBULLBUSD',
      balas: 'BUSD',
      trdas: 'BNBBULL',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BNBBEARUSDT',
      balas: 'USDT',
      trdas: 'BNBBEAR',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BNBBEARBUSD',
      balas: 'BUSD',
      trdas: 'BNBBEAR',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'STPTBNB',
      balas: 'BNB',
      trdas: 'STPT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'STPTBTC',
      balas: 'BTC',
      trdas: 'STPT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'STPTUSDT',
      balas: 'USDT',
      trdas: 'STPT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BTCZAR',
      balas: 'ZAR',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 100
    },
    {
      tk: 'ETHZAR',
      balas: 'ZAR',
      trdas: 'ETH',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 100
    },
    {
      tk: 'BNBZAR',
      balas: 'ZAR',
      trdas: 'BNB',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 100
    },
    {
      tk: 'USDTZAR',
      balas: 'ZAR',
      trdas: 'USDT',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 100
    },
    {
      tk: 'BUSDZAR',
      balas: 'ZAR',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 100
    },
    {
      tk: 'BTCBKRW',
      balas: 'BKRW',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 6,
      min: 1000
    },
    {
      tk: 'ETHBKRW',
      balas: 'BKRW',
      trdas: 'ETH',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 1000
    },
    {
      tk: 'BNBBKRW',
      balas: 'BKRW',
      trdas: 'BNB',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 1000
    },
    {
      tk: 'WTCUSDT',
      balas: 'USDT',
      trdas: 'WTC',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DATABUSD',
      balas: 'BUSD',
      trdas: 'DATA',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'DATAUSDT',
      balas: 'USDT',
      trdas: 'DATA',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'XZCUSDT',
      balas: 'USDT',
      trdas: 'XZC',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'SOLBNB',
      balas: 'BNB',
      trdas: 'SOL',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.01
    },
    {
      tk: 'SOLBTC',
      balas: 'BTC',
      trdas: 'SOL',
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'SOLUSDT',
      balas: 'USDT',
      trdas: 'SOL',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'SOLBUSD',
      balas: 'BUSD',
      trdas: 'SOL',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BTCIDRT',
      balas: 'IDRT',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 20000
    },
    {
      tk: 'BNBIDRT',
      balas: 'IDRT',
      trdas: 'BNB',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 20000
    },
    {
      tk: 'USDTIDRT',
      balas: 'IDRT',
      trdas: 'USDT',
      scnt: 1,
      pdec: 0,
      sdec: 0,
      min: 20000
    },
    {
      tk: 'BUSDIDRT',
      balas: 'IDRT',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 0,
      sdec: 2,
      min: 20000
    },
    {
      tk: 'CTSIBTC',
      balas: 'BTC',
      trdas: 'CTSI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'CTSIUSDT',
      balas: 'USDT',
      trdas: 'CTSI',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CTSIBNB',
      balas: 'BNB',
      trdas: 'CTSI',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'CTSIBUSD',
      balas: 'BUSD',
      trdas: 'CTSI',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'HIVEBNB',
      balas: 'BNB',
      trdas: 'HIVE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'HIVEBTC',
      balas: 'BTC',
      trdas: 'HIVE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'HIVEUSDT',
      balas: 'USDT',
      trdas: 'HIVE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CHRBNB',
      balas: 'BNB',
      trdas: 'CHR',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'CHRBTC',
      balas: 'BTC',
      trdas: 'CHR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'CHRUSDT',
      balas: 'USDT',
      trdas: 'CHR',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BTCUPUSDT',
      balas: 'USDT',
      trdas: 'BTCUP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BTCDOWNUSDT',
      balas: 'USDT',
      trdas: 'BTCDOWN',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 10
    },
    {
      tk: 'GXSUSDT',
      balas: 'USDT',
      trdas: 'GXS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ARDRUSDT',
      balas: 'USDT',
      trdas: 'ARDR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ERDBUSD',
      balas: 'BUSD',
      trdas: 'ERD',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'LENDUSDT',
      balas: 'USDT',
      trdas: 'LEND',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'HBARBUSD',
      balas: 'BUSD',
      trdas: 'HBAR',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'MATICBUSD',
      balas: 'BUSD',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'WRXBUSD',
      balas: 'BUSD',
      trdas: 'WRX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ZILBUSD',
      balas: 'BUSD',
      trdas: 'ZIL',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MDTBNB',
      balas: 'BNB',
      trdas: 'MDT',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'MDTBTC',
      balas: 'BTC',
      trdas: 'MDT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'MDTUSDT',
      balas: 'USDT',
      trdas: 'MDT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'STMXBTC',
      balas: 'BTC',
      trdas: 'STMX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'STMXETH',
      balas: 'ETH',
      trdas: 'STMX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'STMXUSDT',
      balas: 'USDT',
      trdas: 'STMX',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'KNCBUSD',
      balas: 'BUSD',
      trdas: 'KNC',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'KNCUSDT',
      balas: 'USDT',
      trdas: 'KNC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'REPBUSD',
      balas: 'BUSD',
      trdas: 'REP',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'REPUSDT',
      balas: 'USDT',
      trdas: 'REP',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LRCBUSD',
      balas: 'BUSD',
      trdas: 'LRC',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'LRCUSDT',
      balas: 'USDT',
      trdas: 'LRC',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'IQBNB',
      balas: 'BNB',
      trdas: 'IQ',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'IQBUSD',
      balas: 'BUSD',
      trdas: 'IQ',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'PNTBTC',
      balas: 'BTC',
      trdas: 'PNT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'PNTUSDT',
      balas: 'USDT',
      trdas: 'PNT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BTCGBP',
      balas: 'GBP',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 5
    },
    {
      tk: 'ETHGBP',
      balas: 'GBP',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 5
    },
    {
      tk: 'XRPGBP',
      balas: 'GBP',
      trdas: 'XRP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BNBGBP',
      balas: 'GBP',
      trdas: 'BNB',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'GBPBUSD',
      balas: 'BUSD',
      trdas: 'GBP',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'DGBBTC',
      balas: 'BTC',
      trdas: 'DGB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'DGBBUSD',
      balas: 'BUSD',
      trdas: 'DGB',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BTCUAH',
      balas: 'UAH',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 100
    },
    {
      tk: 'USDTUAH',
      balas: 'UAH',
      trdas: 'USDT',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 100
    },
    {
      tk: 'COMPBTC',
      balas: 'BTC',
      trdas: 'COMP',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'COMPBNB',
      balas: 'BNB',
      trdas: 'COMP',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'COMPBUSD',
      balas: 'BUSD',
      trdas: 'COMP',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'COMPUSDT',
      balas: 'USDT',
      trdas: 'COMP',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'BTCBIDR',
      balas: 'BIDR',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 20000
    },
    {
      tk: 'ETHBIDR',
      balas: 'BIDR',
      trdas: 'ETH',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 20000
    },
    {
      tk: 'BNBBIDR',
      balas: 'BIDR',
      trdas: 'BNB',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 20000
    },
    {
      tk: 'BUSDBIDR',
      balas: 'BIDR',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 0,
      sdec: 1,
      min: 20000
    },
    {
      tk: 'USDTBIDR',
      balas: 'BIDR',
      trdas: 'USDT',
      scnt: 1,
      pdec: 0,
      sdec: 1,
      min: 20000
    },
    {
      tk: 'BKRWUSDT',
      balas: 'USDT',
      trdas: 'BKRW',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BKRWBUSD',
      balas: 'BUSD',
      trdas: 'BKRW',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SCUSDT',
      balas: 'USDT',
      trdas: 'SC',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ZENUSDT',
      balas: 'USDT',
      trdas: 'ZEN',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'SXPBTC',
      balas: 'BTC',
      trdas: 'SXP',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SXPBNB',
      balas: 'BNB',
      trdas: 'SXP',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'SXPBUSD',
      balas: 'BUSD',
      trdas: 'SXP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SNXBTC',
      balas: 'BTC',
      trdas: 'SNX',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SNXBNB',
      balas: 'BNB',
      trdas: 'SNX',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'SNXBUSD',
      balas: 'BUSD',
      trdas: 'SNX',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SNXUSDT',
      balas: 'USDT',
      trdas: 'SNX',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ETHUPUSDT',
      balas: 'USDT',
      trdas: 'ETHUP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ETHDOWNUSDT',
      balas: 'USDT',
      trdas: 'ETHDOWN',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ADAUPUSDT',
      balas: 'USDT',
      trdas: 'ADAUP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ADADOWNUSDT',
      balas: 'USDT',
      trdas: 'ADADOWN',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LINKUPUSDT',
      balas: 'USDT',
      trdas: 'LINKUP',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LINKDOWNUSDT',
      balas: 'USDT',
      trdas: 'LINKDOWN',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 10
    },
    {
      tk: 'VTHOBNB',
      balas: 'BNB',
      trdas: 'VTHO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'VTHOBUSD',
      balas: 'BUSD',
      trdas: 'VTHO',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'VTHOUSDT',
      balas: 'USDT',
      trdas: 'VTHO',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: 'DCRBUSD',
      balas: 'BUSD',
      trdas: 'DCR',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'DGBUSDT',
      balas: 'USDT',
      trdas: 'DGB',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'GBPUSDT',
      balas: 'USDT',
      trdas: 'GBP',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'STORJBUSD',
      balas: 'BUSD',
      trdas: 'STORJ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SXPUSDT',
      balas: 'USDT',
      trdas: 'SXP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'IRISBNB',
      balas: 'BNB',
      trdas: 'IRIS',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'IRISBTC',
      balas: 'BTC',
      trdas: 'IRIS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'IRISBUSD',
      balas: 'BUSD',
      trdas: 'IRIS',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MKRBNB',
      balas: 'BNB',
      trdas: 'MKR',
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.05
    },
    {
      tk: 'MKRBTC',
      balas: 'BTC',
      trdas: 'MKR',
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 0.0001
    },
    {
      tk: 'MKRUSDT',
      balas: 'USDT',
      trdas: 'MKR',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 5
    },
    {
      tk: 'MKRBUSD',
      balas: 'BUSD',
      trdas: 'MKR',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 10
    },
    {
      tk: 'DAIBNB',
      balas: 'BNB',
      trdas: 'DAI',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'DAIBTC',
      balas: 'BTC',
      trdas: 'DAI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'DAIUSDT',
      balas: 'USDT',
      trdas: 'DAI',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'DAIBUSD',
      balas: 'BUSD',
      trdas: 'DAI',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'RUNEBNB',
      balas: 'BNB',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'RUNEBTC',
      balas: 'BTC',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'RUNEBUSD',
      balas: 'BUSD',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MANABUSD',
      balas: 'BUSD',
      trdas: 'MANA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DOGEBUSD',
      balas: 'BUSD',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LENDBUSD',
      balas: 'BUSD',
      trdas: 'LEND',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ZRXBUSD',
      balas: 'BUSD',
      trdas: 'ZRX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'DCRUSDT',
      balas: 'USDT',
      trdas: 'DCR',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'STORJUSDT',
      balas: 'USDT',
      trdas: 'STORJ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'XRPBKRW',
      balas: 'BKRW',
      trdas: 'XRP',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 1000
    },
    {
      tk: 'ADABKRW',
      balas: 'BKRW',
      trdas: 'ADA',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 1000
    },
    {
      tk: 'BTCAUD',
      balas: 'AUD',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'ETHAUD',
      balas: 'AUD',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 10
    },
    {
      tk: 'AUDBUSD',
      balas: 'BUSD',
      trdas: 'AUD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'FIOBNB',
      balas: 'BNB',
      trdas: 'FIO',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'FIOBTC',
      balas: 'BTC',
      trdas: 'FIO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'FIOBUSD',
      balas: 'BUSD',
      trdas: 'FIO',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BNBUPUSDT',
      balas: 'USDT',
      trdas: 'BNBUP',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BNBDOWNUSDT',
      balas: 'USDT',
      trdas: 'BNBDOWN',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XTZUPUSDT',
      balas: 'USDT',
      trdas: 'XTZUP',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XTZDOWNUSDT',
      balas: 'USDT',
      trdas: 'XTZDOWN',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'AVABNB',
      balas: 'BNB',
      trdas: 'AVA',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'AVABTC',
      balas: 'BTC',
      trdas: 'AVA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'AVABUSD',
      balas: 'BUSD',
      trdas: 'AVA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'USDTBKRW',
      balas: 'BKRW',
      trdas: 'USDT',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 1000
    },
    {
      tk: 'BUSDBKRW',
      balas: 'BKRW',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 1000
    },
    {
      tk: 'IOTABUSD',
      balas: 'BUSD',
      trdas: 'IOTA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'MANAUSDT',
      balas: 'USDT',
      trdas: 'MANA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'XRPAUD',
      balas: 'AUD',
      trdas: 'XRP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BNBAUD',
      balas: 'AUD',
      trdas: 'BNB',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'AUDUSDT',
      balas: 'USDT',
      trdas: 'AUD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BALBNB',
      balas: 'BNB',
      trdas: 'BAL',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'BALBTC',
      balas: 'BTC',
      trdas: 'BAL',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'BALBUSD',
      balas: 'BUSD',
      trdas: 'BAL',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'YFIBNB',
      balas: 'BNB',
      trdas: 'YFI',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 0.05
    },
    {
      tk: 'YFIBTC',
      balas: 'BTC',
      trdas: 'YFI',
      scnt: 1,
      pdec: 4,
      sdec: 5,
      min: 0.0001
    },
    {
      tk: 'YFIBUSD',
      balas: 'BUSD',
      trdas: 'YFI',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 10
    },
    {
      tk: 'YFIUSDT',
      balas: 'USDT',
      trdas: 'YFI',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 5
    },
    {
      tk: 'BLZBUSD',
      balas: 'BUSD',
      trdas: 'BLZ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'KMDBUSD',
      balas: 'BUSD',
      trdas: 'KMD',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BALUSDT',
      balas: 'USDT',
      trdas: 'BAL',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BLZUSDT',
      balas: 'USDT',
      trdas: 'BLZ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'IRISUSDT',
      balas: 'USDT',
      trdas: 'IRIS',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'KMDUSDT',
      balas: 'USDT',
      trdas: 'KMD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BTCDAI',
      balas: 'DAI',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 5
    },
    {
      tk: 'ETHDAI',
      balas: 'DAI',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 5
    },
    {
      tk: 'BNBDAI',
      balas: 'DAI',
      trdas: 'BNB',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'USDTDAI',
      balas: 'DAI',
      trdas: 'USDT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BUSDDAI',
      balas: 'DAI',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'JSTBNB',
      balas: 'BNB',
      trdas: 'JST',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'JSTBTC',
      balas: 'BTC',
      trdas: 'JST',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'JSTBUSD',
      balas: 'BUSD',
      trdas: 'JST',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'JSTUSDT',
      balas: 'USDT',
      trdas: 'JST',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SRMBNB',
      balas: 'BNB',
      trdas: 'SRM',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'SRMBTC',
      balas: 'BTC',
      trdas: 'SRM',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SRMBUSD',
      balas: 'BUSD',
      trdas: 'SRM',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SRMUSDT',
      balas: 'USDT',
      trdas: 'SRM',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ANTBNB',
      balas: 'BNB',
      trdas: 'ANT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'ANTBTC',
      balas: 'BTC',
      trdas: 'ANT',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ANTBUSD',
      balas: 'BUSD',
      trdas: 'ANT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ANTUSDT',
      balas: 'USDT',
      trdas: 'ANT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CRVBNB',
      balas: 'BNB',
      trdas: 'CRV',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'CRVBTC',
      balas: 'BTC',
      trdas: 'CRV',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'CRVBUSD',
      balas: 'BUSD',
      trdas: 'CRV',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'CRVUSDT',
      balas: 'USDT',
      trdas: 'CRV',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SANDBNB',
      balas: 'BNB',
      trdas: 'SAND',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'SANDBTC',
      balas: 'BTC',
      trdas: 'SAND',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'SANDUSDT',
      balas: 'USDT',
      trdas: 'SAND',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SANDBUSD',
      balas: 'BUSD',
      trdas: 'SAND',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'OCEANBNB',
      balas: 'BNB',
      trdas: 'OCEAN',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'OCEANBTC',
      balas: 'BTC',
      trdas: 'OCEAN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'OCEANBUSD',
      balas: 'BUSD',
      trdas: 'OCEAN',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'OCEANUSDT',
      balas: 'USDT',
      trdas: 'OCEAN',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NMRBTC',
      balas: 'BTC',
      trdas: 'NMR',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'NMRBUSD',
      balas: 'BUSD',
      trdas: 'NMR',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'NMRUSDT',
      balas: 'USDT',
      trdas: 'NMR',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'DOTBNB',
      balas: 'BNB',
      trdas: 'DOT',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'DOTBTC',
      balas: 'BTC',
      trdas: 'DOT',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'DOTBUSD',
      balas: 'BUSD',
      trdas: 'DOT',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'DOTUSDT',
      balas: 'USDT',
      trdas: 'DOT',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'LUNABNB',
      balas: 'BNB',
      trdas: 'LUNA',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'LUNABTC',
      balas: 'BTC',
      trdas: 'LUNA',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'LUNABUSD',
      balas: 'BUSD',
      trdas: 'LUNA',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LUNAUSDT',
      balas: 'USDT',
      trdas: 'LUNA',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'IDEXBTC',
      balas: 'BTC',
      trdas: 'IDEX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'IDEXBUSD',
      balas: 'BUSD',
      trdas: 'IDEX',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'RSRBNB',
      balas: 'BNB',
      trdas: 'RSR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'RSRBTC',
      balas: 'BTC',
      trdas: 'RSR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'RSRBUSD',
      balas: 'BUSD',
      trdas: 'RSR',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 10
    },
    {
      tk: 'RSRUSDT',
      balas: 'USDT',
      trdas: 'RSR',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PAXGBNB',
      balas: 'BNB',
      trdas: 'PAXG',
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.01
    },
    {
      tk: 'PAXGBTC',
      balas: 'BTC',
      trdas: 'PAXG',
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 0.0001
    },
    {
      tk: 'PAXGBUSD',
      balas: 'BUSD',
      trdas: 'PAXG',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 10
    },
    {
      tk: 'PAXGUSDT',
      balas: 'USDT',
      trdas: 'PAXG',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 5
    },
    {
      tk: 'WNXMBNB',
      balas: 'BNB',
      trdas: 'WNXM',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'WNXMBTC',
      balas: 'BTC',
      trdas: 'WNXM',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'WNXMBUSD',
      balas: 'BUSD',
      trdas: 'WNXM',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'WNXMUSDT',
      balas: 'USDT',
      trdas: 'WNXM',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'TRBBNB',
      balas: 'BNB',
      trdas: 'TRB',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'TRBBTC',
      balas: 'BTC',
      trdas: 'TRB',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'TRBBUSD',
      balas: 'BUSD',
      trdas: 'TRB',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'TRBUSDT',
      balas: 'USDT',
      trdas: 'TRB',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'ETHNGN',
      balas: 'NGN',
      trdas: 'ETH',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 500
    },
    {
      tk: 'DOTBIDR',
      balas: 'BIDR',
      trdas: 'DOT',
      scnt: 1,
      pdec: 0,
      sdec: 2,
      min: 20000
    },
    {
      tk: 'LINKAUD',
      balas: 'AUD',
      trdas: 'LINK',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'SXPAUD',
      balas: 'AUD',
      trdas: 'SXP',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BZRXBNB',
      balas: 'BNB',
      trdas: 'BZRX',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'BZRXBTC',
      balas: 'BTC',
      trdas: 'BZRX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BZRXBUSD',
      balas: 'BUSD',
      trdas: 'BZRX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BZRXUSDT',
      balas: 'USDT',
      trdas: 'BZRX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'WBTCBTC',
      balas: 'BTC',
      trdas: 'WBTC',
      scnt: 1,
      pdec: 4,
      sdec: 5,
      min: 0.0001
    },
    {
      tk: 'WBTCETH',
      balas: 'ETH',
      trdas: 'WBTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 0.001
    },
    {
      tk: 'SUSHIBNB',
      balas: 'BNB',
      trdas: 'SUSHI',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'SUSHIBTC',
      balas: 'BTC',
      trdas: 'SUSHI',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SUSHIBUSD',
      balas: 'BUSD',
      trdas: 'SUSHI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SUSHIUSDT',
      balas: 'USDT',
      trdas: 'SUSHI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'YFIIBNB',
      balas: 'BNB',
      trdas: 'YFII',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 0.05
    },
    {
      tk: 'YFIIBTC',
      balas: 'BTC',
      trdas: 'YFII',
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 0.0001
    },
    {
      tk: 'YFIIBUSD',
      balas: 'BUSD',
      trdas: 'YFII',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 10
    },
    {
      tk: 'YFIIUSDT',
      balas: 'USDT',
      trdas: 'YFII',
      scnt: 1,
      pdec: 1,
      sdec: 4,
      min: 10
    },
    {
      tk: 'KSMBNB',
      balas: 'BNB',
      trdas: 'KSM',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'KSMBTC',
      balas: 'BTC',
      trdas: 'KSM',
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'KSMBUSD',
      balas: 'BUSD',
      trdas: 'KSM',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'KSMUSDT',
      balas: 'USDT',
      trdas: 'KSM',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'EGLDBNB',
      balas: 'BNB',
      trdas: 'EGLD',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'EGLDBTC',
      balas: 'BTC',
      trdas: 'EGLD',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'EGLDBUSD',
      balas: 'BUSD',
      trdas: 'EGLD',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'EGLDUSDT',
      balas: 'USDT',
      trdas: 'EGLD',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'DIABNB',
      balas: 'BNB',
      trdas: 'DIA',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'DIABTC',
      balas: 'BTC',
      trdas: 'DIA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'DIABUSD',
      balas: 'BUSD',
      trdas: 'DIA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'DIAUSDT',
      balas: 'USDT',
      trdas: 'DIA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RUNEUSDT',
      balas: 'USDT',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'FIOUSDT',
      balas: 'USDT',
      trdas: 'FIO',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'UMABTC',
      balas: 'BTC',
      trdas: 'UMA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'UMAUSDT',
      balas: 'USDT',
      trdas: 'UMA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'EOSUPUSDT',
      balas: 'USDT',
      trdas: 'EOSUP',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'EOSDOWNUSDT',
      balas: 'USDT',
      trdas: 'EOSDOWN',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 10
    },
    {
      tk: 'TRXUPUSDT',
      balas: 'USDT',
      trdas: 'TRXUP',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'TRXDOWNUSDT',
      balas: 'USDT',
      trdas: 'TRXDOWN',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XRPUPUSDT',
      balas: 'USDT',
      trdas: 'XRPUP',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XRPDOWNUSDT',
      balas: 'USDT',
      trdas: 'XRPDOWN',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 10
    },
    {
      tk: 'DOTUPUSDT',
      balas: 'USDT',
      trdas: 'DOTUP',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'DOTDOWNUSDT',
      balas: 'USDT',
      trdas: 'DOTDOWN',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'SRMBIDR',
      balas: 'BIDR',
      trdas: 'SRM',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 20000
    },
    {
      tk: 'ONEBIDR',
      balas: 'BIDR',
      trdas: 'ONE',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 20000
    },
    {
      tk: 'LINKTRY',
      balas: 'TRY',
      trdas: 'LINK',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'USDTNGN',
      balas: 'NGN',
      trdas: 'USDT',
      scnt: 1,
      pdec: 1,
      sdec: 1,
      min: 500
    },
    {
      tk: 'BELBNB',
      balas: 'BNB',
      trdas: 'BEL',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'BELBTC',
      balas: 'BTC',
      trdas: 'BEL',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'BELBUSD',
      balas: 'BUSD',
      trdas: 'BEL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BELUSDT',
      balas: 'USDT',
      trdas: 'BEL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'WINGBNB',
      balas: 'BNB',
      trdas: 'WING',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'WINGBTC',
      balas: 'BTC',
      trdas: 'WING',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'SWRVBNB',
      balas: 'BNB',
      trdas: 'SWRV',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'SWRVBUSD',
      balas: 'BUSD',
      trdas: 'SWRV',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'WINGBUSD',
      balas: 'BUSD',
      trdas: 'WING',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'WINGUSDT',
      balas: 'USDT',
      trdas: 'WING',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'LTCUPUSDT',
      balas: 'USDT',
      trdas: 'LTCUP',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LTCDOWNUSDT',
      balas: 'USDT',
      trdas: 'LTCDOWN',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LENDBKRW',
      balas: 'BKRW',
      trdas: 'LEND',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 1000
    },
    {
      tk: 'SXPEUR',
      balas: 'EUR',
      trdas: 'SXP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'CREAMBNB',
      balas: 'BNB',
      trdas: 'CREAM',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'CREAMBUSD',
      balas: 'BUSD',
      trdas: 'CREAM',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'UNIBNB',
      balas: 'BNB',
      trdas: 'UNI',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'UNIBTC',
      balas: 'BTC',
      trdas: 'UNI',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'UNIBUSD',
      balas: 'BUSD',
      trdas: 'UNI',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'UNIUSDT',
      balas: 'USDT',
      trdas: 'UNI',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'NBSBTC',
      balas: 'BTC',
      trdas: 'NBS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'NBSUSDT',
      balas: 'USDT',
      trdas: 'NBS',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'OXTBTC',
      balas: 'BTC',
      trdas: 'OXT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'OXTUSDT',
      balas: 'USDT',
      trdas: 'OXT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SUNBTC',
      balas: 'BTC',
      trdas: 'SUN',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'SUNUSDT',
      balas: 'USDT',
      trdas: 'SUN',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AVAXBNB',
      balas: 'BNB',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'AVAXBTC',
      balas: 'BTC',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'AVAXBUSD',
      balas: 'BUSD',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AVAXUSDT',
      balas: 'USDT',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'HNTBTC',
      balas: 'BTC',
      trdas: 'HNT',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'HNTUSDT',
      balas: 'USDT',
      trdas: 'HNT',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BAKEBNB',
      balas: 'BNB',
      trdas: 'BAKE',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'BURGERBNB',
      balas: 'BNB',
      trdas: 'BURGER',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'SXPBIDR',
      balas: 'BIDR',
      trdas: 'SXP',
      scnt: 1,
      pdec: 0,
      sdec: 1,
      min: 20000
    },
    {
      tk: 'LINKBKRW',
      balas: 'BKRW',
      trdas: 'LINK',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 1000
    },
    {
      tk: 'FLMBNB',
      balas: 'BNB',
      trdas: 'FLM',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'FLMBTC',
      balas: 'BTC',
      trdas: 'FLM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'FLMBUSD',
      balas: 'BUSD',
      trdas: 'FLM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'FLMUSDT',
      balas: 'USDT',
      trdas: 'FLM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SCRTBTC',
      balas: 'BTC',
      trdas: 'SCRT',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SCRTETH',
      balas: 'ETH',
      trdas: 'SCRT',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'CAKEBNB',
      balas: 'BNB',
      trdas: 'CAKE',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'CAKEBUSD',
      balas: 'BUSD',
      trdas: 'CAKE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'SPARTABNB',
      balas: 'BNB',
      trdas: 'SPARTA',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'UNIUPUSDT',
      balas: 'USDT',
      trdas: 'UNIUP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'UNIDOWNUSDT',
      balas: 'USDT',
      trdas: 'UNIDOWN',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ORNBTC',
      balas: 'BTC',
      trdas: 'ORN',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ORNUSDT',
      balas: 'USDT',
      trdas: 'ORN',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TRXNGN',
      balas: 'NGN',
      trdas: 'TRX',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 500
    },
    {
      tk: 'SXPTRY',
      balas: 'TRY',
      trdas: 'SXP',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'UTKBTC',
      balas: 'BTC',
      trdas: 'UTK',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'UTKUSDT',
      balas: 'USDT',
      trdas: 'UTK',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'XVSBNB',
      balas: 'BNB',
      trdas: 'XVS',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'XVSBTC',
      balas: 'BTC',
      trdas: 'XVS',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'XVSBUSD',
      balas: 'BUSD',
      trdas: 'XVS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XVSUSDT',
      balas: 'USDT',
      trdas: 'XVS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ALPHABNB',
      balas: 'BNB',
      trdas: 'ALPHA',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'ALPHABTC',
      balas: 'BTC',
      trdas: 'ALPHA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ALPHABUSD',
      balas: 'BUSD',
      trdas: 'ALPHA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ALPHAUSDT',
      balas: 'USDT',
      trdas: 'ALPHA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'VIDTBTC',
      balas: 'BTC',
      trdas: 'VIDT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'VIDTBUSD',
      balas: 'BUSD',
      trdas: 'VIDT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AAVEBNB',
      balas: 'BNB',
      trdas: 'AAVE',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.01
    },
    {
      tk: 'BTCBRL',
      balas: 'BRL',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 10
    },
    {
      tk: 'USDTBRL',
      balas: 'BRL',
      trdas: 'USDT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'AAVEBTC',
      balas: 'BTC',
      trdas: 'AAVE',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'AAVEETH',
      balas: 'ETH',
      trdas: 'AAVE',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'AAVEBUSD',
      balas: 'BUSD',
      trdas: 'AAVE',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'AAVEUSDT',
      balas: 'USDT',
      trdas: 'AAVE',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'AAVEBKRW',
      balas: 'BKRW',
      trdas: 'AAVE',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 1000
    },
    {
      tk: 'NEARBNB',
      balas: 'BNB',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'NEARBTC',
      balas: 'BTC',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'NEARBUSD',
      balas: 'BUSD',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'NEARUSDT',
      balas: 'USDT',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SXPUPUSDT',
      balas: 'USDT',
      trdas: 'SXPUP',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'SXPDOWNUSDT',
      balas: 'USDT',
      trdas: 'SXPDOWN',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'DOTBKRW',
      balas: 'BKRW',
      trdas: 'DOT',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 1000
    },
    {
      tk: 'SXPGBP',
      balas: 'GBP',
      trdas: 'SXP',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'FILBNB',
      balas: 'BNB',
      trdas: 'FIL',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'FILBTC',
      balas: 'BTC',
      trdas: 'FIL',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'FILBUSD',
      balas: 'BUSD',
      trdas: 'FIL',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'FILUSDT',
      balas: 'USDT',
      trdas: 'FIL',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'FILUPUSDT',
      balas: 'USDT',
      trdas: 'FILUP',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'FILDOWNUSDT',
      balas: 'USDT',
      trdas: 'FILDOWN',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 10
    },
    {
      tk: 'YFIUPUSDT',
      balas: 'USDT',
      trdas: 'YFIUP',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'YFIDOWNUSDT',
      balas: 'USDT',
      trdas: 'YFIDOWN',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'INJBNB',
      balas: 'BNB',
      trdas: 'INJ',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'INJBTC',
      balas: 'BTC',
      trdas: 'INJ',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'INJBUSD',
      balas: 'BUSD',
      trdas: 'INJ',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'INJUSDT',
      balas: 'USDT',
      trdas: 'INJ',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AERGOBTC',
      balas: 'BTC',
      trdas: 'AERGO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'AERGOBUSD',
      balas: 'BUSD',
      trdas: 'AERGO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'LINKEUR',
      balas: 'EUR',
      trdas: 'LINK',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ONEBUSD',
      balas: 'BUSD',
      trdas: 'ONE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'EASYETH',
      balas: 'ETH',
      trdas: 'EASY',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'AUDIOBTC',
      balas: 'BTC',
      trdas: 'AUDIO',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'AUDIOBUSD',
      balas: 'BUSD',
      trdas: 'AUDIO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'AUDIOUSDT',
      balas: 'USDT',
      trdas: 'AUDIO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CTKBNB',
      balas: 'BNB',
      trdas: 'CTK',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'CTKBTC',
      balas: 'BTC',
      trdas: 'CTK',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'CTKBUSD',
      balas: 'BUSD',
      trdas: 'CTK',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'CTKUSDT',
      balas: 'USDT',
      trdas: 'CTK',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BCHUPUSDT',
      balas: 'USDT',
      trdas: 'BCHUP',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BCHDOWNUSDT',
      balas: 'USDT',
      trdas: 'BCHDOWN',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BOTBTC',
      balas: 'BTC',
      trdas: 'BOT',
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 0.0001
    },
    {
      tk: 'BOTBUSD',
      balas: 'BUSD',
      trdas: 'BOT',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'ETHBRL',
      balas: 'BRL',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 10
    },
    {
      tk: 'DOTEUR',
      balas: 'EUR',
      trdas: 'DOT',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AKROBTC',
      balas: 'BTC',
      trdas: 'AKRO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'AKROUSDT',
      balas: 'USDT',
      trdas: 'AKRO',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: 'KP3RBNB',
      balas: 'BNB',
      trdas: 'KP3R',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'KP3RBUSD',
      balas: 'BUSD',
      trdas: 'KP3R',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'AXSBNB',
      balas: 'BNB',
      trdas: 'AXS',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'AXSBTC',
      balas: 'BTC',
      trdas: 'AXS',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'AXSBUSD',
      balas: 'BUSD',
      trdas: 'AXS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AXSUSDT',
      balas: 'USDT',
      trdas: 'AXS',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'HARDBNB',
      balas: 'BNB',
      trdas: 'HARD',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'HARDBTC',
      balas: 'BTC',
      trdas: 'HARD',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'HARDBUSD',
      balas: 'BUSD',
      trdas: 'HARD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'HARDUSDT',
      balas: 'USDT',
      trdas: 'HARD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BNBBRL',
      balas: 'BRL',
      trdas: 'BNB',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 10
    },
    {
      tk: 'LTCEUR',
      balas: 'EUR',
      trdas: 'LTC',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'RENBTCBTC',
      balas: 'BTC',
      trdas: 'RENBTC',
      scnt: 1,
      pdec: 4,
      sdec: 5,
      min: 0.0001
    },
    {
      tk: 'RENBTCETH',
      balas: 'ETH',
      trdas: 'RENBTC',
      scnt: 1,
      pdec: 3,
      sdec: 5,
      min: 0.005
    },
    {
      tk: 'DNTBUSD',
      balas: 'BUSD',
      trdas: 'DNT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'DNTUSDT',
      balas: 'USDT',
      trdas: 'DNT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SLPETH',
      balas: 'ETH',
      trdas: 'SLP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'ADAEUR',
      balas: 'EUR',
      trdas: 'ADA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'LTCNGN',
      balas: 'NGN',
      trdas: 'LTC',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 500
    },
    {
      tk: 'CVPETH',
      balas: 'ETH',
      trdas: 'CVP',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'CVPBUSD',
      balas: 'BUSD',
      trdas: 'CVP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'STRAXBTC',
      balas: 'BTC',
      trdas: 'STRAX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'STRAXETH',
      balas: 'ETH',
      trdas: 'STRAX',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'STRAXBUSD',
      balas: 'BUSD',
      trdas: 'STRAX',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'STRAXUSDT',
      balas: 'USDT',
      trdas: 'STRAX',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FORBTC',
      balas: 'BTC',
      trdas: 'FOR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'FORBUSD',
      balas: 'BUSD',
      trdas: 'FOR',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'UNFIBNB',
      balas: 'BNB',
      trdas: 'UNFI',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'UNFIBTC',
      balas: 'BTC',
      trdas: 'UNFI',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'UNFIBUSD',
      balas: 'BUSD',
      trdas: 'UNFI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'UNFIUSDT',
      balas: 'USDT',
      trdas: 'UNFI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'FRONTETH',
      balas: 'ETH',
      trdas: 'FRONT',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'FRONTBUSD',
      balas: 'BUSD',
      trdas: 'FRONT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BCHABUSD',
      balas: 'BUSD',
      trdas: 'BCHA',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'ROSEBTC',
      balas: 'BTC',
      trdas: 'ROSE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ROSEBUSD',
      balas: 'BUSD',
      trdas: 'ROSE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ROSEUSDT',
      balas: 'USDT',
      trdas: 'ROSE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AVAXTRY',
      balas: 'TRY',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BUSDBRL',
      balas: 'BRL',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'AVAUSDT',
      balas: 'USDT',
      trdas: 'AVA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SYSBUSD',
      balas: 'BUSD',
      trdas: 'SYS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'XEMUSDT',
      balas: 'USDT',
      trdas: 'XEM',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HEGICETH',
      balas: 'ETH',
      trdas: 'HEGIC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'HEGICBUSD',
      balas: 'BUSD',
      trdas: 'HEGIC',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AAVEUPUSDT',
      balas: 'USDT',
      trdas: 'AAVEUP',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'AAVEDOWNUSDT',
      balas: 'USDT',
      trdas: 'AAVEDOWN',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PROMBNB',
      balas: 'BNB',
      trdas: 'PROM',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'PROMBUSD',
      balas: 'BUSD',
      trdas: 'PROM',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XRPBRL',
      balas: 'BRL',
      trdas: 'XRP',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'XRPNGN',
      balas: 'NGN',
      trdas: 'XRP',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 500
    },
    {
      tk: 'SKLBTC',
      balas: 'BTC',
      trdas: 'SKL',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'SKLBUSD',
      balas: 'BUSD',
      trdas: 'SKL',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SKLUSDT',
      balas: 'USDT',
      trdas: 'SKL',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BCHEUR',
      balas: 'EUR',
      trdas: 'BCH',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'YFIEUR',
      balas: 'EUR',
      trdas: 'YFI',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 5
    },
    {
      tk: 'ZILBIDR',
      balas: 'BIDR',
      trdas: 'ZIL',
      scnt: 1,
      pdec: 1,
      sdec: 0,
      min: 20000
    },
    {
      tk: 'SUSDBTC',
      balas: 'BTC',
      trdas: 'SUSD',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SUSDETH',
      balas: 'ETH',
      trdas: 'SUSD',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'SUSDUSDT',
      balas: 'USDT',
      trdas: 'SUSD',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'COVERETH',
      balas: 'ETH',
      trdas: 'COVER',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.005
    },
    {
      tk: 'COVERBUSD',
      balas: 'BUSD',
      trdas: 'COVER',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'GLMBTC',
      balas: 'BTC',
      trdas: 'GLM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'GLMETH',
      balas: 'ETH',
      trdas: 'GLM',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'GHSTETH',
      balas: 'ETH',
      trdas: 'GHST',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'GHSTBUSD',
      balas: 'BUSD',
      trdas: 'GHST',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SUSHIUPUSDT',
      balas: 'USDT',
      trdas: 'SUSHIUP',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 10
    },
    {
      tk: 'SUSHIDOWNUSDT',
      balas: 'USDT',
      trdas: 'SUSHIDOWN',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XLMUPUSDT',
      balas: 'USDT',
      trdas: 'XLMUP',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XLMDOWNUSDT',
      balas: 'USDT',
      trdas: 'XLMDOWN',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LINKBRL',
      balas: 'BRL',
      trdas: 'LINK',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LINKNGN',
      balas: 'NGN',
      trdas: 'LINK',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 500
    },
    {
      tk: 'LTCRUB',
      balas: 'RUB',
      trdas: 'LTC',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 100
    },
    {
      tk: 'TRXTRY',
      balas: 'TRY',
      trdas: 'TRX',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'XLMEUR',
      balas: 'EUR',
      trdas: 'XLM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DFETH',
      balas: 'ETH',
      trdas: 'DF',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'DFBUSD',
      balas: 'BUSD',
      trdas: 'DF',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'GRTBTC',
      balas: 'BTC',
      trdas: 'GRT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'GRTETH',
      balas: 'ETH',
      trdas: 'GRT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'GRTUSDT',
      balas: 'USDT',
      trdas: 'GRT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'JUVBTC',
      balas: 'BTC',
      trdas: 'JUV',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'JUVBUSD',
      balas: 'BUSD',
      trdas: 'JUV',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'JUVUSDT',
      balas: 'USDT',
      trdas: 'JUV',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'PSGBTC',
      balas: 'BTC',
      trdas: 'PSG',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'PSGBUSD',
      balas: 'BUSD',
      trdas: 'PSG',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PSGUSDT',
      balas: 'USDT',
      trdas: 'PSG',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BUSDBVND',
      balas: 'BVND',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 0,
      sdec: 0,
      min: 30000
    },
    {
      tk: 'USDTBVND',
      balas: 'BVND',
      trdas: 'USDT',
      scnt: 1,
      pdec: 0,
      sdec: 0,
      min: 30000
    },
    {
      tk: '1INCHBTC',
      balas: 'BTC',
      trdas: '1INCH',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: '1INCHUSDT',
      balas: 'USDT',
      trdas: '1INCH',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'REEFBTC',
      balas: 'BTC',
      trdas: 'REEF',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'REEFUSDT',
      balas: 'USDT',
      trdas: 'REEF',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'OGBTC',
      balas: 'BTC',
      trdas: 'OG',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'OGUSDT',
      balas: 'USDT',
      trdas: 'OG',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ATMBTC',
      balas: 'BTC',
      trdas: 'ATM',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ATMUSDT',
      balas: 'USDT',
      trdas: 'ATM',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ASRBTC',
      balas: 'BTC',
      trdas: 'ASR',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ASRUSDT',
      balas: 'USDT',
      trdas: 'ASR',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CELOBTC',
      balas: 'BTC',
      trdas: 'CELO',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'CELOUSDT',
      balas: 'USDT',
      trdas: 'CELO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RIFBTC',
      balas: 'BTC',
      trdas: 'RIF',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'RIFUSDT',
      balas: 'USDT',
      trdas: 'RIF',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CHZTRY',
      balas: 'TRY',
      trdas: 'CHZ',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'XLMTRY',
      balas: 'TRY',
      trdas: 'XLM',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'LINKGBP',
      balas: 'GBP',
      trdas: 'LINK',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'GRTEUR',
      balas: 'EUR',
      trdas: 'GRT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BTCSTBTC',
      balas: 'BTC',
      trdas: 'BTCST',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'BTCSTBUSD',
      balas: 'BUSD',
      trdas: 'BTCST',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BTCSTUSDT',
      balas: 'USDT',
      trdas: 'BTCST',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'TRUBTC',
      balas: 'BTC',
      trdas: 'TRU',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'TRUBUSD',
      balas: 'BUSD',
      trdas: 'TRU',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'TRUUSDT',
      balas: 'USDT',
      trdas: 'TRU',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DEXEETH',
      balas: 'ETH',
      trdas: 'DEXE',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'DEXEBUSD',
      balas: 'BUSD',
      trdas: 'DEXE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'EOSEUR',
      balas: 'EUR',
      trdas: 'EOS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'LTCBRL',
      balas: 'BRL',
      trdas: 'LTC',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'USDCBUSD',
      balas: 'BUSD',
      trdas: 'USDC',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'TUSDBUSD',
      balas: 'BUSD',
      trdas: 'TUSD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'PAXBUSD',
      balas: 'BUSD',
      trdas: 'PAX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'CKBBTC',
      balas: 'BTC',
      trdas: 'CKB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'CKBBUSD',
      balas: 'BUSD',
      trdas: 'CKB',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'CKBUSDT',
      balas: 'USDT',
      trdas: 'CKB',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TWTBTC',
      balas: 'BTC',
      trdas: 'TWT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'TWTBUSD',
      balas: 'BUSD',
      trdas: 'TWT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TWTUSDT',
      balas: 'USDT',
      trdas: 'TWT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FIROBTC',
      balas: 'BTC',
      trdas: 'FIRO',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'FIROETH',
      balas: 'ETH',
      trdas: 'FIRO',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'FIROUSDT',
      balas: 'USDT',
      trdas: 'FIRO',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BETHETH',
      balas: 'ETH',
      trdas: 'BETH',
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 0.001
    },
    {
      tk: 'DOGEEUR',
      balas: 'EUR',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'DOGETRY',
      balas: 'TRY',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'DOGEAUD',
      balas: 'AUD',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DOGEBRL',
      balas: 'BRL',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'DOTNGN',
      balas: 'NGN',
      trdas: 'DOT',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 500
    },
    {
      tk: 'PROSETH',
      balas: 'ETH',
      trdas: 'PROS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'LITBTC',
      balas: 'BTC',
      trdas: 'LIT',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'LITBUSD',
      balas: 'BUSD',
      trdas: 'LIT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'LITUSDT',
      balas: 'USDT',
      trdas: 'LIT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BTCVAI',
      balas: 'VAI',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BUSDVAI',
      balas: 'VAI',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SFPBTC',
      balas: 'BTC',
      trdas: 'SFP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'SFPBUSD',
      balas: 'BUSD',
      trdas: 'SFP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SFPUSDT',
      balas: 'USDT',
      trdas: 'SFP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DOGEGBP',
      balas: 'GBP',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DOTTRY',
      balas: 'TRY',
      trdas: 'DOT',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'FXSBTC',
      balas: 'BTC',
      trdas: 'FXS',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'FXSBUSD',
      balas: 'BUSD',
      trdas: 'FXS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'DODOBTC',
      balas: 'BTC',
      trdas: 'DODO',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'DODOBUSD',
      balas: 'BUSD',
      trdas: 'DODO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'DODOUSDT',
      balas: 'USDT',
      trdas: 'DODO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'FRONTBTC',
      balas: 'BTC',
      trdas: 'FRONT',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'EASYBTC',
      balas: 'BTC',
      trdas: 'EASY',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'CAKEBTC',
      balas: 'BTC',
      trdas: 'CAKE',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'CAKEUSDT',
      balas: 'USDT',
      trdas: 'CAKE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BAKEBUSD',
      balas: 'BUSD',
      trdas: 'BAKE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'UFTETH',
      balas: 'ETH',
      trdas: 'UFT',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'UFTBUSD',
      balas: 'BUSD',
      trdas: 'UFT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: '1INCHBUSD',
      balas: 'BUSD',
      trdas: '1INCH',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BANDBUSD',
      balas: 'BUSD',
      trdas: 'BAND',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'GRTBUSD',
      balas: 'BUSD',
      trdas: 'GRT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'IOSTBUSD',
      balas: 'BUSD',
      trdas: 'IOST',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'OMGBUSD',
      balas: 'BUSD',
      trdas: 'OMG',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'REEFBUSD',
      balas: 'BUSD',
      trdas: 'REEF',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ACMBTC',
      balas: 'BTC',
      trdas: 'ACM',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ACMBUSD',
      balas: 'BUSD',
      trdas: 'ACM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ACMUSDT',
      balas: 'USDT',
      trdas: 'ACM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AUCTIONBTC',
      balas: 'BTC',
      trdas: 'AUCTION',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'AUCTIONBUSD',
      balas: 'BUSD',
      trdas: 'AUCTION',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PHABTC',
      balas: 'BTC',
      trdas: 'PHA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'PHABUSD',
      balas: 'BUSD',
      trdas: 'PHA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'DOTGBP',
      balas: 'GBP',
      trdas: 'DOT',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ADATRY',
      balas: 'TRY',
      trdas: 'ADA',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ADABRL',
      balas: 'BRL',
      trdas: 'ADA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ADAGBP',
      balas: 'GBP',
      trdas: 'ADA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TVKBTC',
      balas: 'BTC',
      trdas: 'TVK',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'TVKBUSD',
      balas: 'BUSD',
      trdas: 'TVK',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BADGERBTC',
      balas: 'BTC',
      trdas: 'BADGER',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'BADGERBUSD',
      balas: 'BUSD',
      trdas: 'BADGER',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BADGERUSDT',
      balas: 'USDT',
      trdas: 'BADGER',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'FISBTC',
      balas: 'BTC',
      trdas: 'FIS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'FISBUSD',
      balas: 'BUSD',
      trdas: 'FIS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'FISUSDT',
      balas: 'USDT',
      trdas: 'FIS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DOTBRL',
      balas: 'BRL',
      trdas: 'DOT',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ADAAUD',
      balas: 'AUD',
      trdas: 'ADA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'HOTTRY',
      balas: 'TRY',
      trdas: 'HOT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'EGLDEUR',
      balas: 'EUR',
      trdas: 'EGLD',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'OMBTC',
      balas: 'BTC',
      trdas: 'OM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'OMBUSD',
      balas: 'BUSD',
      trdas: 'OM',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'OMUSDT',
      balas: 'USDT',
      trdas: 'OM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PONDBTC',
      balas: 'BTC',
      trdas: 'POND',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'PONDBUSD',
      balas: 'BUSD',
      trdas: 'POND',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PONDUSDT',
      balas: 'USDT',
      trdas: 'POND',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DEGOBTC',
      balas: 'BTC',
      trdas: 'DEGO',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'DEGOBUSD',
      balas: 'BUSD',
      trdas: 'DEGO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'DEGOUSDT',
      balas: 'USDT',
      trdas: 'DEGO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AVAXEUR',
      balas: 'EUR',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BTTTRY',
      balas: 'TRY',
      trdas: 'BTT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'CHZBRL',
      balas: 'BRL',
      trdas: 'CHZ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'UNIEUR',
      balas: 'EUR',
      trdas: 'UNI',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ALICEBTC',
      balas: 'BTC',
      trdas: 'ALICE',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ALICEBUSD',
      balas: 'BUSD',
      trdas: 'ALICE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ALICEUSDT',
      balas: 'USDT',
      trdas: 'ALICE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'CHZBUSD',
      balas: 'BUSD',
      trdas: 'CHZ',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CHZEUR',
      balas: 'EUR',
      trdas: 'CHZ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CHZGBP',
      balas: 'GBP',
      trdas: 'CHZ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BIFIBNB',
      balas: 'BNB',
      trdas: 'BIFI',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'BIFIBUSD',
      balas: 'BUSD',
      trdas: 'BIFI',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'LINABTC',
      balas: 'BTC',
      trdas: 'LINA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'LINABUSD',
      balas: 'BUSD',
      trdas: 'LINA',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LINAUSDT',
      balas: 'USDT',
      trdas: 'LINA',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ADARUB',
      balas: 'RUB',
      trdas: 'ADA',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 100
    },
    {
      tk: 'ENJBRL',
      balas: 'BRL',
      trdas: 'ENJ',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ENJEUR',
      balas: 'EUR',
      trdas: 'ENJ',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MATICEUR',
      balas: 'EUR',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'NEOTRY',
      balas: 'TRY',
      trdas: 'NEO',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PERPBTC',
      balas: 'BTC',
      trdas: 'PERP',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'PERPBUSD',
      balas: 'BUSD',
      trdas: 'PERP',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PERPUSDT',
      balas: 'USDT',
      trdas: 'PERP',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'RAMPBTC',
      balas: 'BTC',
      trdas: 'RAMP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'RAMPBUSD',
      balas: 'BUSD',
      trdas: 'RAMP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'RAMPUSDT',
      balas: 'USDT',
      trdas: 'RAMP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SUPERBTC',
      balas: 'BTC',
      trdas: 'SUPER',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'SUPERBUSD',
      balas: 'BUSD',
      trdas: 'SUPER',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SUPERUSDT',
      balas: 'USDT',
      trdas: 'SUPER',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CFXBTC',
      balas: 'BTC',
      trdas: 'CFX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'CFXBUSD',
      balas: 'BUSD',
      trdas: 'CFX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'CFXUSDT',
      balas: 'USDT',
      trdas: 'CFX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ENJGBP',
      balas: 'GBP',
      trdas: 'ENJ',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'EOSTRY',
      balas: 'TRY',
      trdas: 'EOS',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'LTCGBP',
      balas: 'GBP',
      trdas: 'LTC',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'LUNAEUR',
      balas: 'EUR',
      trdas: 'LUNA',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'RVNTRY',
      balas: 'TRY',
      trdas: 'RVN',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'THETAEUR',
      balas: 'EUR',
      trdas: 'THETA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'XVGBUSD',
      balas: 'BUSD',
      trdas: 'XVG',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 10
    },
    {
      tk: 'EPSBTC',
      balas: 'BTC',
      trdas: 'EPS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'EPSBUSD',
      balas: 'BUSD',
      trdas: 'EPS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'EPSUSDT',
      balas: 'USDT',
      trdas: 'EPS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AUTOBTC',
      balas: 'BTC',
      trdas: 'AUTO',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'AUTOBUSD',
      balas: 'BUSD',
      trdas: 'AUTO',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'AUTOUSDT',
      balas: 'USDT',
      trdas: 'AUTO',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'TKOBTC',
      balas: 'BTC',
      trdas: 'TKO',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'TKOBIDR',
      balas: 'BIDR',
      trdas: 'TKO',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 20000
    },
    {
      tk: 'TKOBUSD',
      balas: 'BUSD',
      trdas: 'TKO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'TKOUSDT',
      balas: 'USDT',
      trdas: 'TKO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PUNDIXETH',
      balas: 'ETH',
      trdas: 'PUNDIX',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'PUNDIXUSDT',
      balas: 'USDT',
      trdas: 'PUNDIX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BTTBRL',
      balas: 'BRL',
      trdas: 'BTT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BTTEUR',
      balas: 'EUR',
      trdas: 'BTT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'HOTEUR',
      balas: 'EUR',
      trdas: 'HOT',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 10
    },
    {
      tk: 'WINEUR',
      balas: 'EUR',
      trdas: 'WIN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TLMBTC',
      balas: 'BTC',
      trdas: 'TLM',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'TLMBUSD',
      balas: 'BUSD',
      trdas: 'TLM',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'TLMUSDT',
      balas: 'USDT',
      trdas: 'TLM',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: '1INCHUPUSDT',
      balas: 'USDT',
      trdas: '1INCHUP',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 10
    },
    {
      tk: '1INCHDOWNUSDT',
      balas: 'USDT',
      trdas: '1INCHDOWN',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BTGBUSD',
      balas: 'BUSD',
      trdas: 'BTG',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BTGUSDT',
      balas: 'USDT',
      trdas: 'BTG',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'HOTBUSD',
      balas: 'BUSD',
      trdas: 'HOT',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BNBUAH',
      balas: 'UAH',
      trdas: 'BNB',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 100
    },
    {
      tk: 'ONTTRY',
      balas: 'TRY',
      trdas: 'ONT',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'VETEUR',
      balas: 'EUR',
      trdas: 'VET',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 5
    },
    {
      tk: 'VETGBP',
      balas: 'GBP',
      trdas: 'VET',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'WINBRL',
      balas: 'BRL',
      trdas: 'WIN',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'MIRBTC',
      balas: 'BTC',
      trdas: 'MIR',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'MIRBUSD',
      balas: 'BUSD',
      trdas: 'MIR',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MIRUSDT',
      balas: 'USDT',
      trdas: 'MIR',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BARBTC',
      balas: 'BTC',
      trdas: 'BAR',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'BARBUSD',
      balas: 'BUSD',
      trdas: 'BAR',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BARUSDT',
      balas: 'USDT',
      trdas: 'BAR',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'FORTHBTC',
      balas: 'BTC',
      trdas: 'FORTH',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'FORTHBUSD',
      balas: 'BUSD',
      trdas: 'FORTH',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'FORTHUSDT',
      balas: 'USDT',
      trdas: 'FORTH',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'CAKEGBP',
      balas: 'GBP',
      trdas: 'CAKE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'DOGERUB',
      balas: 'RUB',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 100
    },
    {
      tk: 'HOTBRL',
      balas: 'BRL',
      trdas: 'HOT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'WRXEUR',
      balas: 'EUR',
      trdas: 'WRX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'EZBTC',
      balas: 'BTC',
      trdas: 'EZ',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'EZETH',
      balas: 'ETH',
      trdas: 'EZ',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'BAKEUSDT',
      balas: 'USDT',
      trdas: 'BAKE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BURGERBUSD',
      balas: 'BUSD',
      trdas: 'BURGER',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BURGERUSDT',
      balas: 'USDT',
      trdas: 'BURGER',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SLPBUSD',
      balas: 'BUSD',
      trdas: 'SLP',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SLPUSDT',
      balas: 'USDT',
      trdas: 'SLP',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TRXAUD',
      balas: 'AUD',
      trdas: 'TRX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'TRXEUR',
      balas: 'EUR',
      trdas: 'TRX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'VETTRY',
      balas: 'TRY',
      trdas: 'VET',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SHIBUSDT',
      balas: 'USDT',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'SHIBBUSD',
      balas: 'BUSD',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ICPBTC',
      balas: 'BTC',
      trdas: 'ICP',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ICPBNB',
      balas: 'BNB',
      trdas: 'ICP',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'ICPBUSD',
      balas: 'BUSD',
      trdas: 'ICP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ICPUSDT',
      balas: 'USDT',
      trdas: 'ICP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'SHIBEUR',
      balas: 'EUR',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'SHIBRUB',
      balas: 'RUB',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 100
    },
    {
      tk: 'ETCEUR',
      balas: 'EUR',
      trdas: 'ETC',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ETCBRL',
      balas: 'BRL',
      trdas: 'ETC',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'DOGEBIDR',
      balas: 'BIDR',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 0,
      sdec: 0,
      min: 20000
    },
    {
      tk: 'ARBTC',
      balas: 'BTC',
      trdas: 'AR',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ARBNB',
      balas: 'BNB',
      trdas: 'AR',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'ARBUSD',
      balas: 'BUSD',
      trdas: 'AR',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ARUSDT',
      balas: 'USDT',
      trdas: 'AR',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'POLSBTC',
      balas: 'BTC',
      trdas: 'POLS',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'POLSBNB',
      balas: 'BNB',
      trdas: 'POLS',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'POLSBUSD',
      balas: 'BUSD',
      trdas: 'POLS',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'POLSUSDT',
      balas: 'USDT',
      trdas: 'POLS',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MDXBTC',
      balas: 'BTC',
      trdas: 'MDX',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'MDXBNB',
      balas: 'BNB',
      trdas: 'MDX',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'MDXBUSD',
      balas: 'BUSD',
      trdas: 'MDX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MDXUSDT',
      balas: 'USDT',
      trdas: 'MDX',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MASKBNB',
      balas: 'BNB',
      trdas: 'MASK',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'MASKBUSD',
      balas: 'BUSD',
      trdas: 'MASK',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MASKUSDT',
      balas: 'USDT',
      trdas: 'MASK',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'LPTBTC',
      balas: 'BTC',
      trdas: 'LPT',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'LPTBNB',
      balas: 'BNB',
      trdas: 'LPT',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'LPTBUSD',
      balas: 'BUSD',
      trdas: 'LPT',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LPTUSDT',
      balas: 'USDT',
      trdas: 'LPT',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ETHUAH',
      balas: 'UAH',
      trdas: 'ETH',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 100
    },
    {
      tk: 'MATICBRL',
      balas: 'BRL',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SOLEUR',
      balas: 'EUR',
      trdas: 'SOL',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'SHIBBRL',
      balas: 'BRL',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AGIXBTC',
      balas: 'BTC',
      trdas: 'AGIX',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ICPEUR',
      balas: 'EUR',
      trdas: 'ICP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'MATICGBP',
      balas: 'GBP',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SHIBTRY',
      balas: 'TRY',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'MATICBIDR',
      balas: 'BIDR',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 0,
      sdec: 1,
      min: 20000
    },
    {
      tk: 'MATICRUB',
      balas: 'RUB',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 100
    },
    {
      tk: 'NUBTC',
      balas: 'BTC',
      trdas: 'NU',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'NUBNB',
      balas: 'BNB',
      trdas: 'NU',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'NUBUSD',
      balas: 'BUSD',
      trdas: 'NU',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'NUUSDT',
      balas: 'USDT',
      trdas: 'NU',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'XVGUSDT',
      balas: 'USDT',
      trdas: 'XVG',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RLCBUSD',
      balas: 'BUSD',
      trdas: 'RLC',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'CELRBUSD',
      balas: 'BUSD',
      trdas: 'CELR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ATMBUSD',
      balas: 'BUSD',
      trdas: 'ATM',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ZENBUSD',
      balas: 'BUSD',
      trdas: 'ZEN',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'FTMBUSD',
      balas: 'BUSD',
      trdas: 'FTM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'THETABUSD',
      balas: 'BUSD',
      trdas: 'THETA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'WINBUSD',
      balas: 'BUSD',
      trdas: 'WIN',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'KAVABUSD',
      balas: 'BUSD',
      trdas: 'KAVA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'XEMBUSD',
      balas: 'BUSD',
      trdas: 'XEM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ATABTC',
      balas: 'BTC',
      trdas: 'ATA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ATABNB',
      balas: 'BNB',
      trdas: 'ATA',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'ATABUSD',
      balas: 'BUSD',
      trdas: 'ATA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ATAUSDT',
      balas: 'USDT',
      trdas: 'ATA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GTCBTC',
      balas: 'BTC',
      trdas: 'GTC',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'GTCBNB',
      balas: 'BNB',
      trdas: 'GTC',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'GTCBUSD',
      balas: 'BUSD',
      trdas: 'GTC',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'GTCUSDT',
      balas: 'USDT',
      trdas: 'GTC',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TORNBTC',
      balas: 'BTC',
      trdas: 'TORN',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'TORNBNB',
      balas: 'BNB',
      trdas: 'TORN',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'TORNBUSD',
      balas: 'BUSD',
      trdas: 'TORN',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'TORNUSDT',
      balas: 'USDT',
      trdas: 'TORN',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'MATICTRY',
      balas: 'TRY',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ETCGBP',
      balas: 'GBP',
      trdas: 'ETC',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'SOLGBP',
      balas: 'GBP',
      trdas: 'SOL',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BAKEBTC',
      balas: 'BTC',
      trdas: 'BAKE',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'COTIBUSD',
      balas: 'BUSD',
      trdas: 'COTI',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'KEEPBTC',
      balas: 'BTC',
      trdas: 'KEEP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'KEEPBNB',
      balas: 'BNB',
      trdas: 'KEEP',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'KEEPBUSD',
      balas: 'BUSD',
      trdas: 'KEEP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'KEEPUSDT',
      balas: 'USDT',
      trdas: 'KEEP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SOLTRY',
      balas: 'TRY',
      trdas: 'SOL',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'RUNEGBP',
      balas: 'GBP',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SOLBRL',
      balas: 'BRL',
      trdas: 'SOL',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'SCBUSD',
      balas: 'BUSD',
      trdas: 'SC',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'CHRBUSD',
      balas: 'BUSD',
      trdas: 'CHR',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'STMXBUSD',
      balas: 'BUSD',
      trdas: 'STMX',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'HNTBUSD',
      balas: 'BUSD',
      trdas: 'HNT',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'FTTBUSD',
      balas: 'BUSD',
      trdas: 'FTT',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'DOCKBUSD',
      balas: 'BUSD',
      trdas: 'DOCK',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ADABIDR',
      balas: 'BIDR',
      trdas: 'ADA',
      scnt: 1,
      pdec: 0,
      sdec: 1,
      min: 20000
    },
    {
      tk: 'ERNBNB',
      balas: 'BNB',
      trdas: 'ERN',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'ERNBUSD',
      balas: 'BUSD',
      trdas: 'ERN',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ERNUSDT',
      balas: 'USDT',
      trdas: 'ERN',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'KLAYBTC',
      balas: 'BTC',
      trdas: 'KLAY',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'KLAYBNB',
      balas: 'BNB',
      trdas: 'KLAY',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'KLAYBUSD',
      balas: 'BUSD',
      trdas: 'KLAY',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'KLAYUSDT',
      balas: 'USDT',
      trdas: 'KLAY',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RUNEEUR',
      balas: 'EUR',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MATICAUD',
      balas: 'AUD',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DOTRUB',
      balas: 'RUB',
      trdas: 'DOT',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 100
    },
    {
      tk: 'UTKBUSD',
      balas: 'BUSD',
      trdas: 'UTK',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'IOTXBUSD',
      balas: 'BUSD',
      trdas: 'IOTX',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'PHAUSDT',
      balas: 'USDT',
      trdas: 'PHA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SOLRUB',
      balas: 'RUB',
      trdas: 'SOL',
      scnt: 1,
      pdec: 0,
      sdec: 2,
      min: 100
    },
    {
      tk: 'RUNEAUD',
      balas: 'AUD',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 10
    },
    {
      tk: 'BUSDUAH',
      balas: 'UAH',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 100
    },
    {
      tk: 'BONDBTC',
      balas: 'BTC',
      trdas: 'BOND',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'BONDBNB',
      balas: 'BNB',
      trdas: 'BOND',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'BONDBUSD',
      balas: 'BUSD',
      trdas: 'BOND',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BONDUSDT',
      balas: 'USDT',
      trdas: 'BOND',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'MLNBTC',
      balas: 'BTC',
      trdas: 'MLN',
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'MLNBNB',
      balas: 'BNB',
      trdas: 'MLN',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'MLNBUSD',
      balas: 'BUSD',
      trdas: 'MLN',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'MLNUSDT',
      balas: 'USDT',
      trdas: 'MLN',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'GRTTRY',
      balas: 'TRY',
      trdas: 'GRT',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'CAKEBRL',
      balas: 'BRL',
      trdas: 'CAKE',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ICPRUB',
      balas: 'RUB',
      trdas: 'ICP',
      scnt: 1,
      pdec: 0,
      sdec: 2,
      min: 100
    },
    {
      tk: 'DOTAUD',
      balas: 'AUD',
      trdas: 'DOT',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AAVEBRL',
      balas: 'BRL',
      trdas: 'AAVE',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 10
    },
    {
      tk: 'EOSAUD',
      balas: 'AUD',
      trdas: 'EOS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'DEXEUSDT',
      balas: 'USDT',
      trdas: 'DEXE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'LTOBUSD',
      balas: 'BUSD',
      trdas: 'LTO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ADXBUSD',
      balas: 'BUSD',
      trdas: 'ADX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'QUICKBTC',
      balas: 'BTC',
      trdas: 'QUICK',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'QUICKBNB',
      balas: 'BNB',
      trdas: 'QUICK',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'QUICKBUSD',
      balas: 'BUSD',
      trdas: 'QUICK',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'C98USDT',
      balas: 'USDT',
      trdas: 'C98',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'C98BUSD',
      balas: 'BUSD',
      trdas: 'C98',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'C98BNB',
      balas: 'BNB',
      trdas: 'C98',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'C98BTC',
      balas: 'BTC',
      trdas: 'C98',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'CLVBTC',
      balas: 'BTC',
      trdas: 'CLV',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'CLVBNB',
      balas: 'BNB',
      trdas: 'CLV',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'CLVBUSD',
      balas: 'BUSD',
      trdas: 'CLV',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'CLVUSDT',
      balas: 'USDT',
      trdas: 'CLV',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'QNTBTC',
      balas: 'BTC',
      trdas: 'QNT',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'QNTBNB',
      balas: 'BNB',
      trdas: 'QNT',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'QNTBUSD',
      balas: 'BUSD',
      trdas: 'QNT',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'QNTUSDT',
      balas: 'USDT',
      trdas: 'QNT',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'FLOWBTC',
      balas: 'BTC',
      trdas: 'FLOW',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'FLOWBNB',
      balas: 'BNB',
      trdas: 'FLOW',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'FLOWBUSD',
      balas: 'BUSD',
      trdas: 'FLOW',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'FLOWUSDT',
      balas: 'USDT',
      trdas: 'FLOW',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'XECBUSD',
      balas: 'BUSD',
      trdas: 'XEC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AXSBRL',
      balas: 'BRL',
      trdas: 'AXS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'AXSAUD',
      balas: 'AUD',
      trdas: 'AXS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'TVKUSDT',
      balas: 'USDT',
      trdas: 'TVK',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MINABTC',
      balas: 'BTC',
      trdas: 'MINA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'MINABNB',
      balas: 'BNB',
      trdas: 'MINA',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'MINABUSD',
      balas: 'BUSD',
      trdas: 'MINA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MINAUSDT',
      balas: 'USDT',
      trdas: 'MINA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RAYBNB',
      balas: 'BNB',
      trdas: 'RAY',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'RAYBUSD',
      balas: 'BUSD',
      trdas: 'RAY',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'RAYUSDT',
      balas: 'USDT',
      trdas: 'RAY',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'FARMBTC',
      balas: 'BTC',
      trdas: 'FARM',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'FARMBNB',
      balas: 'BNB',
      trdas: 'FARM',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'FARMBUSD',
      balas: 'BUSD',
      trdas: 'FARM',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'FARMUSDT',
      balas: 'USDT',
      trdas: 'FARM',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'ALPACABTC',
      balas: 'BTC',
      trdas: 'ALPACA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ALPACABNB',
      balas: 'BNB',
      trdas: 'ALPACA',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'ALPACABUSD',
      balas: 'BUSD',
      trdas: 'ALPACA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ALPACAUSDT',
      balas: 'USDT',
      trdas: 'ALPACA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TLMTRY',
      balas: 'TRY',
      trdas: 'TLM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'QUICKUSDT',
      balas: 'USDT',
      trdas: 'QUICK',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ORNBUSD',
      balas: 'BUSD',
      trdas: 'ORN',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MBOXBTC',
      balas: 'BTC',
      trdas: 'MBOX',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'MBOXBNB',
      balas: 'BNB',
      trdas: 'MBOX',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'MBOXBUSD',
      balas: 'BUSD',
      trdas: 'MBOX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MBOXUSDT',
      balas: 'USDT',
      trdas: 'MBOX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'VGXBTC',
      balas: 'BTC',
      trdas: 'VGX',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'VGXETH',
      balas: 'ETH',
      trdas: 'VGX',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'FORUSDT',
      balas: 'USDT',
      trdas: 'FOR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'REQUSDT',
      balas: 'USDT',
      trdas: 'REQ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GHSTUSDT',
      balas: 'USDT',
      trdas: 'GHST',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TRURUB',
      balas: 'RUB',
      trdas: 'TRU',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 100
    },
    {
      tk: 'FISBRL',
      balas: 'BRL',
      trdas: 'FIS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'WAXPUSDT',
      balas: 'USDT',
      trdas: 'WAXP',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'WAXPBUSD',
      balas: 'BUSD',
      trdas: 'WAXP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'WAXPBNB',
      balas: 'BNB',
      trdas: 'WAXP',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'WAXPBTC',
      balas: 'BTC',
      trdas: 'WAXP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'TRIBEBTC',
      balas: 'BTC',
      trdas: 'TRIBE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'TRIBEBNB',
      balas: 'BNB',
      trdas: 'TRIBE',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'TRIBEBUSD',
      balas: 'BUSD',
      trdas: 'TRIBE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'TRIBEUSDT',
      balas: 'USDT',
      trdas: 'TRIBE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'GNOUSDT',
      balas: 'USDT',
      trdas: 'GNO',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'GNOBUSD',
      balas: 'BUSD',
      trdas: 'GNO',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'GNOBNB',
      balas: 'BNB',
      trdas: 'GNO',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'GNOBTC',
      balas: 'BTC',
      trdas: 'GNO',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'ARPATRY',
      balas: 'TRY',
      trdas: 'ARPA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'PROMBTC',
      balas: 'BTC',
      trdas: 'PROM',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'MTLBUSD',
      balas: 'BUSD',
      trdas: 'MTL',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'OGNBUSD',
      balas: 'BUSD',
      trdas: 'OGN',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'XECUSDT',
      balas: 'USDT',
      trdas: 'XEC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 5
    },
    {
      tk: 'C98BRL',
      balas: 'BRL',
      trdas: 'C98',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SOLAUD',
      balas: 'AUD',
      trdas: 'SOL',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'XRPBIDR',
      balas: 'BIDR',
      trdas: 'XRP',
      scnt: 1,
      pdec: 0,
      sdec: 1,
      min: 20000
    },
    {
      tk: 'POLYBUSD',
      balas: 'BUSD',
      trdas: 'POLY',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ELFUSDT',
      balas: 'USDT',
      trdas: 'ELF',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DYDXUSDT',
      balas: 'USDT',
      trdas: 'DYDX',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'DYDXBUSD',
      balas: 'BUSD',
      trdas: 'DYDX',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'DYDXBNB',
      balas: 'BNB',
      trdas: 'DYDX',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'DYDXBTC',
      balas: 'BTC',
      trdas: 'DYDX',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ELFBUSD',
      balas: 'BUSD',
      trdas: 'ELF',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'POLYUSDT',
      balas: 'USDT',
      trdas: 'POLY',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'IDEXUSDT',
      balas: 'USDT',
      trdas: 'IDEX',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'VIDTUSDT',
      balas: 'USDT',
      trdas: 'VIDT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SOLBIDR',
      balas: 'BIDR',
      trdas: 'SOL',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 20000
    },
    {
      tk: 'BTCUSDP',
      balas: 'USDP',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'ETHUSDP',
      balas: 'USDP',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 10
    },
    {
      tk: 'BNBUSDP',
      balas: 'USDP',
      trdas: 'BNB',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'USDPBUSD',
      balas: 'BUSD',
      trdas: 'USDP',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'USDPUSDT',
      balas: 'USDT',
      trdas: 'USDP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GALAUSDT',
      balas: 'USDT',
      trdas: 'GALA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GALABUSD',
      balas: 'BUSD',
      trdas: 'GALA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GALABNB',
      balas: 'BNB',
      trdas: 'GALA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'GALABTC',
      balas: 'BTC',
      trdas: 'GALA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'FTMBIDR',
      balas: 'BIDR',
      trdas: 'FTM',
      scnt: 1,
      pdec: 0,
      sdec: 1,
      min: 20000
    },
    {
      tk: 'ALGOBIDR',
      balas: 'BIDR',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 0,
      sdec: 2,
      min: 20000
    },
    {
      tk: 'CAKEAUD',
      balas: 'AUD',
      trdas: 'CAKE',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'KSMAUD',
      balas: 'AUD',
      trdas: 'KSM',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'WAVESRUB',
      balas: 'RUB',
      trdas: 'WAVES',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 100
    },
    {
      tk: 'SUNBUSD',
      balas: 'BUSD',
      trdas: 'SUN',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ILVUSDT',
      balas: 'USDT',
      trdas: 'ILV',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'ILVBUSD',
      balas: 'BUSD',
      trdas: 'ILV',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'ILVBNB',
      balas: 'BNB',
      trdas: 'ILV',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'ILVBTC',
      balas: 'BTC',
      trdas: 'ILV',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'RENBUSD',
      balas: 'BUSD',
      trdas: 'REN',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'YGGUSDT',
      balas: 'USDT',
      trdas: 'YGG',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'YGGBUSD',
      balas: 'BUSD',
      trdas: 'YGG',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'YGGBNB',
      balas: 'BNB',
      trdas: 'YGG',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'YGGBTC',
      balas: 'BTC',
      trdas: 'YGG',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'STXBUSD',
      balas: 'BUSD',
      trdas: 'STX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SYSUSDT',
      balas: 'USDT',
      trdas: 'SYS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DFUSDT',
      balas: 'USDT',
      trdas: 'DF',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SOLUSDC',
      balas: 'USDC',
      trdas: 'SOL',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'ARPARUB',
      balas: 'RUB',
      trdas: 'ARPA',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 100
    },
    {
      tk: 'LTCUAH',
      balas: 'UAH',
      trdas: 'LTC',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 100
    },
    {
      tk: 'FETBUSD',
      balas: 'BUSD',
      trdas: 'FET',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ARPABUSD',
      balas: 'BUSD',
      trdas: 'ARPA',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'LSKBUSD',
      balas: 'BUSD',
      trdas: 'LSK',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'AVAXBIDR',
      balas: 'BIDR',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 0,
      sdec: 2,
      min: 20000
    },
    {
      tk: 'ALICEBIDR',
      balas: 'BIDR',
      trdas: 'ALICE',
      scnt: 1,
      pdec: 0,
      sdec: 2,
      min: 20000
    },
    {
      tk: 'FIDAUSDT',
      balas: 'USDT',
      trdas: 'FIDA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'FIDABUSD',
      balas: 'BUSD',
      trdas: 'FIDA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'FIDABNB',
      balas: 'BNB',
      trdas: 'FIDA',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'FIDABTC',
      balas: 'BTC',
      trdas: 'FIDA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'DENTBUSD',
      balas: 'BUSD',
      trdas: 'DENT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'FRONTUSDT',
      balas: 'USDT',
      trdas: 'FRONT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CVPUSDT',
      balas: 'USDT',
      trdas: 'CVP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AGLDBTC',
      balas: 'BTC',
      trdas: 'AGLD',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'AGLDBNB',
      balas: 'BNB',
      trdas: 'AGLD',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'AGLDBUSD',
      balas: 'BUSD',
      trdas: 'AGLD',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'AGLDUSDT',
      balas: 'USDT',
      trdas: 'AGLD',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RADBTC',
      balas: 'BTC',
      trdas: 'RAD',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'RADBNB',
      balas: 'BNB',
      trdas: 'RAD',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'RADBUSD',
      balas: 'BUSD',
      trdas: 'RAD',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'RADUSDT',
      balas: 'USDT',
      trdas: 'RAD',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'UNIAUD',
      balas: 'AUD',
      trdas: 'UNI',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'HIVEBUSD',
      balas: 'BUSD',
      trdas: 'HIVE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'STPTBUSD',
      balas: 'BUSD',
      trdas: 'STPT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BETABTC',
      balas: 'BTC',
      trdas: 'BETA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BETABNB',
      balas: 'BNB',
      trdas: 'BETA',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'BETABUSD',
      balas: 'BUSD',
      trdas: 'BETA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BETAUSDT',
      balas: 'USDT',
      trdas: 'BETA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SHIBAUD',
      balas: 'AUD',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RAREBTC',
      balas: 'BTC',
      trdas: 'RARE',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'RAREBNB',
      balas: 'BNB',
      trdas: 'RARE',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'RAREBUSD',
      balas: 'BUSD',
      trdas: 'RARE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'RAREUSDT',
      balas: 'USDT',
      trdas: 'RARE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AVAXBRL',
      balas: 'BRL',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'AVAXAUD',
      balas: 'AUD',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'LUNAAUD',
      balas: 'AUD',
      trdas: 'LUNA',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'TROYBUSD',
      balas: 'BUSD',
      trdas: 'TROY',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AXSETH',
      balas: 'ETH',
      trdas: 'AXS',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'FTMETH',
      balas: 'ETH',
      trdas: 'FTM',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'SOLETH',
      balas: 'ETH',
      trdas: 'SOL',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'SSVBTC',
      balas: 'BTC',
      trdas: 'SSV',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'SSVETH',
      balas: 'ETH',
      trdas: 'SSV',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'LAZIOTRY',
      balas: 'TRY',
      trdas: 'LAZIO',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LAZIOEUR',
      balas: 'EUR',
      trdas: 'LAZIO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'LAZIOBTC',
      balas: 'BTC',
      trdas: 'LAZIO',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'LAZIOUSDT',
      balas: 'USDT',
      trdas: 'LAZIO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'CHESSBTC',
      balas: 'BTC',
      trdas: 'CHESS',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'CHESSBNB',
      balas: 'BNB',
      trdas: 'CHESS',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'CHESSBUSD',
      balas: 'BUSD',
      trdas: 'CHESS',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'CHESSUSDT',
      balas: 'USDT',
      trdas: 'CHESS',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'FTMAUD',
      balas: 'AUD',
      trdas: 'FTM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FTMBRL',
      balas: 'BRL',
      trdas: 'FTM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SCRTBUSD',
      balas: 'BUSD',
      trdas: 'SCRT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ADXUSDT',
      balas: 'USDT',
      trdas: 'ADX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AUCTIONUSDT',
      balas: 'USDT',
      trdas: 'AUCTION',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'CELOBUSD',
      balas: 'BUSD',
      trdas: 'CELO',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'FTMRUB',
      balas: 'RUB',
      trdas: 'FTM',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 100
    },
    {
      tk: 'NUAUD',
      balas: 'AUD',
      trdas: 'NU',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'NURUB',
      balas: 'RUB',
      trdas: 'NU',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 100
    },
    {
      tk: 'REEFTRY',
      balas: 'TRY',
      trdas: 'REEF',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'REEFBIDR',
      balas: 'BIDR',
      trdas: 'REEF',
      scnt: 1,
      pdec: 1,
      sdec: 0,
      min: 20000
    },
    {
      tk: 'SHIBDOGE',
      balas: 'DOGE',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 30
    },
    {
      tk: 'DARUSDT',
      balas: 'USDT',
      trdas: 'DAR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DARBUSD',
      balas: 'BUSD',
      trdas: 'DAR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'DARBNB',
      balas: 'BNB',
      trdas: 'DAR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'DARBTC',
      balas: 'BTC',
      trdas: 'DAR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BNXBTC',
      balas: 'BTC',
      trdas: 'BNX',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'BNXBNB',
      balas: 'BNB',
      trdas: 'BNX',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'BNXBUSD',
      balas: 'BUSD',
      trdas: 'BNX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BNXUSDT',
      balas: 'USDT',
      trdas: 'BNX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RGTUSDT',
      balas: 'USDT',
      trdas: 'RGT',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'RGTBTC',
      balas: 'BTC',
      trdas: 'RGT',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'RGTBUSD',
      balas: 'BUSD',
      trdas: 'RGT',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'RGTBNB',
      balas: 'BNB',
      trdas: 'RGT',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'LAZIOBUSD',
      balas: 'BUSD',
      trdas: 'LAZIO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'OXTBUSD',
      balas: 'BUSD',
      trdas: 'OXT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'MANATRY',
      balas: 'TRY',
      trdas: 'MANA',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ALGORUB',
      balas: 'RUB',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 100
    },
    {
      tk: 'SHIBUAH',
      balas: 'UAH',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 100
    },
    {
      tk: 'LUNABIDR',
      balas: 'BIDR',
      trdas: 'LUNA',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 20000
    },
    {
      tk: 'AUDUSDC',
      balas: 'USDC',
      trdas: 'AUD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'MOVRBTC',
      balas: 'BTC',
      trdas: 'MOVR',
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'MOVRBNB',
      balas: 'BNB',
      trdas: 'MOVR',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'MOVRBUSD',
      balas: 'BUSD',
      trdas: 'MOVR',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'MOVRUSDT',
      balas: 'USDT',
      trdas: 'MOVR',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 5
    },
    {
      tk: 'CITYBTC',
      balas: 'BTC',
      trdas: 'CITY',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'CITYBNB',
      balas: 'BNB',
      trdas: 'CITY',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'CITYBUSD',
      balas: 'BUSD',
      trdas: 'CITY',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'CITYUSDT',
      balas: 'USDT',
      trdas: 'CITY',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ENSBTC',
      balas: 'BTC',
      trdas: 'ENS',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ENSBNB',
      balas: 'BNB',
      trdas: 'ENS',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'ENSBUSD',
      balas: 'BUSD',
      trdas: 'ENS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ENSUSDT',
      balas: 'USDT',
      trdas: 'ENS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'SANDETH',
      balas: 'ETH',
      trdas: 'SAND',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'DOTETH',
      balas: 'ETH',
      trdas: 'DOT',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'MATICETH',
      balas: 'ETH',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'ANKRBUSD',
      balas: 'BUSD',
      trdas: 'ANKR',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SANDTRY',
      balas: 'TRY',
      trdas: 'SAND',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MANABRL',
      balas: 'BRL',
      trdas: 'MANA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'KP3RUSDT',
      balas: 'USDT',
      trdas: 'KP3R',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'QIUSDT',
      balas: 'USDT',
      trdas: 'QI',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'QIBUSD',
      balas: 'BUSD',
      trdas: 'QI',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'QIBNB',
      balas: 'BNB',
      trdas: 'QI',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'QIBTC',
      balas: 'BTC',
      trdas: 'QI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'PORTOBTC',
      balas: 'BTC',
      trdas: 'PORTO',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'PORTOUSDT',
      balas: 'USDT',
      trdas: 'PORTO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'PORTOTRY',
      balas: 'TRY',
      trdas: 'PORTO',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PORTOEUR',
      balas: 'EUR',
      trdas: 'PORTO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'POWRUSDT',
      balas: 'USDT',
      trdas: 'POWR',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'POWRBUSD',
      balas: 'BUSD',
      trdas: 'POWR',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AVAXETH',
      balas: 'ETH',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'SLPTRY',
      balas: 'TRY',
      trdas: 'SLP',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'FISTRY',
      balas: 'TRY',
      trdas: 'FIS',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'LRCTRY',
      balas: 'TRY',
      trdas: 'LRC',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'CHRETH',
      balas: 'ETH',
      trdas: 'CHR',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'FISBIDR',
      balas: 'BIDR',
      trdas: 'FIS',
      scnt: 1,
      pdec: 0,
      sdec: 1,
      min: 20000
    },
    {
      tk: 'VGXUSDT',
      balas: 'USDT',
      trdas: 'VGX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'GALAETH',
      balas: 'ETH',
      trdas: 'GALA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'JASMYUSDT',
      balas: 'USDT',
      trdas: 'JASMY',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'JASMYBUSD',
      balas: 'BUSD',
      trdas: 'JASMY',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 10
    },
    {
      tk: 'JASMYBNB',
      balas: 'BNB',
      trdas: 'JASMY',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'JASMYBTC',
      balas: 'BTC',
      trdas: 'JASMY',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'AMPBTC',
      balas: 'BTC',
      trdas: 'AMP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'AMPBNB',
      balas: 'BNB',
      trdas: 'AMP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'AMPBUSD',
      balas: 'BUSD',
      trdas: 'AMP',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AMPUSDT',
      balas: 'USDT',
      trdas: 'AMP',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PLABTC',
      balas: 'BTC',
      trdas: 'PLA',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'PLABNB',
      balas: 'BNB',
      trdas: 'PLA',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'PLABUSD',
      balas: 'BUSD',
      trdas: 'PLA',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PLAUSDT',
      balas: 'USDT',
      trdas: 'PLA',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'PYRBTC',
      balas: 'BTC',
      trdas: 'PYR',
      scnt: 1,
      pdec: 8,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'PYRBUSD',
      balas: 'BUSD',
      trdas: 'PYR',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'PYRUSDT',
      balas: 'USDT',
      trdas: 'PYR',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 5
    },
    {
      tk: 'RNDRBTC',
      balas: 'BTC',
      trdas: 'RNDR',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'RNDRUSDT',
      balas: 'USDT',
      trdas: 'RNDR',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'RNDRBUSD',
      balas: 'BUSD',
      trdas: 'RNDR',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ALCXBTC',
      balas: 'BTC',
      trdas: 'ALCX',
      scnt: 1,
      pdec: 7,
      sdec: 4,
      min: 0.0001
    },
    {
      tk: 'ALCXBUSD',
      balas: 'BUSD',
      trdas: 'ALCX',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 10
    },
    {
      tk: 'ALCXUSDT',
      balas: 'USDT',
      trdas: 'ALCX',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 5
    },
    {
      tk: 'SANTOSBTC',
      balas: 'BTC',
      trdas: 'SANTOS',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'SANTOSUSDT',
      balas: 'USDT',
      trdas: 'SANTOS',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'SANTOSBRL',
      balas: 'BRL',
      trdas: 'SANTOS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'SANTOSTRY',
      balas: 'TRY',
      trdas: 'SANTOS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'MCBTC',
      balas: 'BTC',
      trdas: 'MC',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'MCBUSD',
      balas: 'BUSD',
      trdas: 'MC',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'MCUSDT',
      balas: 'USDT',
      trdas: 'MC',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BELTRY',
      balas: 'TRY',
      trdas: 'BEL',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'COCOSBUSD',
      balas: 'BUSD',
      trdas: 'COCOS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'DENTTRY',
      balas: 'TRY',
      trdas: 'DENT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ENJTRY',
      balas: 'TRY',
      trdas: 'ENJ',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'NEORUB',
      balas: 'RUB',
      trdas: 'NEO',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 100
    },
    {
      tk: 'SANDAUD',
      balas: 'AUD',
      trdas: 'SAND',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SLPBIDR',
      balas: 'BIDR',
      trdas: 'SLP',
      scnt: 1,
      pdec: 1,
      sdec: 0,
      min: 20000
    },
    {
      tk: 'ANYBTC',
      balas: 'BTC',
      trdas: 'ANY',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ANYBUSD',
      balas: 'BUSD',
      trdas: 'ANY',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ANYUSDT',
      balas: 'USDT',
      trdas: 'ANY',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BICOBTC',
      balas: 'BTC',
      trdas: 'BICO',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'BICOBUSD',
      balas: 'BUSD',
      trdas: 'BICO',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BICOUSDT',
      balas: 'USDT',
      trdas: 'BICO',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'FLUXBTC',
      balas: 'BTC',
      trdas: 'FLUX',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'FLUXBUSD',
      balas: 'BUSD',
      trdas: 'FLUX',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'FLUXUSDT',
      balas: 'USDT',
      trdas: 'FLUX',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ALICETRY',
      balas: 'TRY',
      trdas: 'ALICE',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'FXSUSDT',
      balas: 'USDT',
      trdas: 'FXS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'GALABRL',
      balas: 'BRL',
      trdas: 'GALA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'GALATRY',
      balas: 'TRY',
      trdas: 'GALA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'LUNATRY',
      balas: 'TRY',
      trdas: 'LUNA',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'REQBUSD',
      balas: 'BUSD',
      trdas: 'REQ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SANDBRL',
      balas: 'BRL',
      trdas: 'SAND',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'MANABIDR',
      balas: 'BIDR',
      trdas: 'MANA',
      scnt: 1,
      pdec: 0,
      sdec: 2,
      min: 20000
    },
    {
      tk: 'SANDBIDR',
      balas: 'BIDR',
      trdas: 'SAND',
      scnt: 1,
      pdec: 0,
      sdec: 2,
      min: 20000
    },
    {
      tk: 'VOXELBTC',
      balas: 'BTC',
      trdas: 'VOXEL',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'VOXELBNB',
      balas: 'BNB',
      trdas: 'VOXEL',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'VOXELBUSD',
      balas: 'BUSD',
      trdas: 'VOXEL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'VOXELUSDT',
      balas: 'USDT',
      trdas: 'VOXEL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'COSBUSD',
      balas: 'BUSD',
      trdas: 'COS',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'CTXCBUSD',
      balas: 'BUSD',
      trdas: 'CTXC',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'FTMTRY',
      balas: 'TRY',
      trdas: 'FTM',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'MANABNB',
      balas: 'BNB',
      trdas: 'MANA',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'MINATRY',
      balas: 'TRY',
      trdas: 'MINA',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XTZTRY',
      balas: 'TRY',
      trdas: 'XTZ',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'HIGHBTC',
      balas: 'BTC',
      trdas: 'HIGH',
      scnt: 1,
      pdec: 8,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'HIGHBUSD',
      balas: 'BUSD',
      trdas: 'HIGH',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'HIGHUSDT',
      balas: 'USDT',
      trdas: 'HIGH',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 5
    },
    {
      tk: 'CVXBTC',
      balas: 'BTC',
      trdas: 'CVX',
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'CVXBUSD',
      balas: 'BUSD',
      trdas: 'CVX',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'CVXUSDT',
      balas: 'USDT',
      trdas: 'CVX',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 5
    },
    {
      tk: 'PEOPLEBTC',
      balas: 'BTC',
      trdas: 'PEOPLE',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'PEOPLEBUSD',
      balas: 'BUSD',
      trdas: 'PEOPLE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'PEOPLEUSDT',
      balas: 'USDT',
      trdas: 'PEOPLE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 1
    },
    {
      tk: 'OOKIBUSD',
      balas: 'BUSD',
      trdas: 'OOKI',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'OOKIUSDT',
      balas: 'USDT',
      trdas: 'OOKI',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'COCOSTRY',
      balas: 'TRY',
      trdas: 'COCOS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'GXSBNB',
      balas: 'BNB',
      trdas: 'GXS',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'LINKBNB',
      balas: 'BNB',
      trdas: 'LINK',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.01
    },
    {
      tk: 'LUNAETH',
      balas: 'ETH',
      trdas: 'LUNA',
      scnt: 1,
      pdec: 8,
      sdec: 3,
      min: 0.005
    },
    {
      tk: 'MDTBUSD',
      balas: 'BUSD',
      trdas: 'MDT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'NULSBUSD',
      balas: 'BUSD',
      trdas: 'NULS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SPELLBTC',
      balas: 'BTC',
      trdas: 'SPELL',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'SPELLUSDT',
      balas: 'USDT',
      trdas: 'SPELL',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SPELLBUSD',
      balas: 'BUSD',
      trdas: 'SPELL',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'USTBTC',
      balas: 'BTC',
      trdas: 'UST',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'USTBUSD',
      balas: 'BUSD',
      trdas: 'UST',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10
    },
    {
      tk: 'USTUSDT',
      balas: 'USDT',
      trdas: 'UST',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'JOEBTC',
      balas: 'BTC',
      trdas: 'JOE',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'JOEBUSD',
      balas: 'BUSD',
      trdas: 'JOE',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'JOEUSDT',
      balas: 'USDT',
      trdas: 'JOE',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ATOMETH',
      balas: 'ETH',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'DUSKBUSD',
      balas: 'BUSD',
      trdas: 'DUSK',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'EGLDETH',
      balas: 'ETH',
      trdas: 'EGLD',
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 0.001
    },
    {
      tk: 'ICPETH',
      balas: 'ETH',
      trdas: 'ICP',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'LUNABRL',
      balas: 'BRL',
      trdas: 'LUNA',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: 'LUNAUST',
      balas: 'UST',
      trdas: 'LUNA',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 10
    },
    {
      tk: 'NEARETH',
      balas: 'ETH',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'ROSEBNB',
      balas: 'BNB',
      trdas: 'ROSE',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'VOXELETH',
      balas: 'ETH',
      trdas: 'VOXEL',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'ALICEBNB',
      balas: 'BNB',
      trdas: 'ALICE',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'ATOMTRY',
      balas: 'TRY',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'ETHUST',
      balas: 'UST',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 10
    },
    {
      tk: 'GALAAUD',
      balas: 'AUD',
      trdas: 'GALA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LRCBNB',
      balas: 'BNB',
      trdas: 'LRC',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'ONEETH',
      balas: 'ETH',
      trdas: 'ONE',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'OOKIBNB',
      balas: 'BNB',
      trdas: 'OOKI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'ACHBTC',
      balas: 'BTC',
      trdas: 'ACH',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ACHBUSD',
      balas: 'BUSD',
      trdas: 'ACH',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ACHUSDT',
      balas: 'USDT',
      trdas: 'ACH',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'IMXBTC',
      balas: 'BTC',
      trdas: 'IMX',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'IMXBUSD',
      balas: 'BUSD',
      trdas: 'IMX',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'IMXUSDT',
      balas: 'USDT',
      trdas: 'IMX',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'GLMRBTC',
      balas: 'BTC',
      trdas: 'GLMR',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'GLMRBUSD',
      balas: 'BUSD',
      trdas: 'GLMR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'GLMRUSDT',
      balas: 'USDT',
      trdas: 'GLMR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ATOMBIDR',
      balas: 'BIDR',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 20000
    },
    {
      tk: 'DYDXETH',
      balas: 'ETH',
      trdas: 'DYDX',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'FARMETH',
      balas: 'ETH',
      trdas: 'FARM',
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 0.005
    },
    {
      tk: 'FORBNB',
      balas: 'BNB',
      trdas: 'FOR',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'ICPTRY',
      balas: 'TRY',
      trdas: 'ICP',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'JASMYETH',
      balas: 'ETH',
      trdas: 'JASMY',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'LINABNB',
      balas: 'BNB',
      trdas: 'LINA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'OOKIETH',
      balas: 'ETH',
      trdas: 'OOKI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'ROSEETH',
      balas: 'ETH',
      trdas: 'ROSE',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'UMABUSD',
      balas: 'BUSD',
      trdas: 'UMA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'UNIETH',
      balas: 'ETH',
      trdas: 'UNI',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'XTZETH',
      balas: 'ETH',
      trdas: 'XTZ',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'LOKABTC',
      balas: 'BTC',
      trdas: 'LOKA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'LOKABNB',
      balas: 'BNB',
      trdas: 'LOKA',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'LOKABUSD',
      balas: 'BUSD',
      trdas: 'LOKA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'LOKAUSDT',
      balas: 'USDT',
      trdas: 'LOKA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ATOMBRL',
      balas: 'BRL',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'BNBUST',
      balas: 'UST',
      trdas: 'BNB',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'CRVETH',
      balas: 'ETH',
      trdas: 'CRV',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'HIGHBNB',
      balas: 'BNB',
      trdas: 'HIGH',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'NEARRUB',
      balas: 'RUB',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 100
    },
    {
      tk: 'ROSETRY',
      balas: 'TRY',
      trdas: 'ROSE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SCRTUSDT',
      balas: 'USDT',
      trdas: 'SCRT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'API3BTC',
      balas: 'BTC',
      trdas: 'API3',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'API3BUSD',
      balas: 'BUSD',
      trdas: 'API3',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'API3USDT',
      balas: 'USDT',
      trdas: 'API3',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BTTCUSDT',
      balas: 'USDT',
      trdas: 'BTTC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BTTCUSDC',
      balas: 'USDC',
      trdas: 'BTTC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BTTCTRY',
      balas: 'TRY',
      trdas: 'BTTC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ACABTC',
      balas: 'BTC',
      trdas: 'ACA',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ACABUSD',
      balas: 'BUSD',
      trdas: 'ACA',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ACAUSDT',
      balas: 'USDT',
      trdas: 'ACA',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ANCBTC',
      balas: 'BTC',
      trdas: 'ANC',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ANCBUSD',
      balas: 'BUSD',
      trdas: 'ANC',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ANCUSDT',
      balas: 'USDT',
      trdas: 'ANC',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BDOTDOT',
      balas: 'DOT',
      trdas: 'BDOT',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.3
    },
    {
      tk: 'XNOBTC',
      balas: 'BTC',
      trdas: 'XNO',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'XNOETH',
      balas: 'ETH',
      trdas: 'XNO',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'XNOBUSD',
      balas: 'BUSD',
      trdas: 'XNO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XNOUSDT',
      balas: 'USDT',
      trdas: 'XNO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'COSTRY',
      balas: 'TRY',
      trdas: 'COS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'KAVAETH',
      balas: 'ETH',
      trdas: 'KAVA',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'MCBNB',
      balas: 'BNB',
      trdas: 'MC',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'ONETRY',
      balas: 'TRY',
      trdas: 'ONE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'WOOBTC',
      balas: 'BTC',
      trdas: 'WOO',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'WOOBNB',
      balas: 'BNB',
      trdas: 'WOO',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'WOOBUSD',
      balas: 'BUSD',
      trdas: 'WOO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'WOOUSDT',
      balas: 'USDT',
      trdas: 'WOO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CELRETH',
      balas: 'ETH',
      trdas: 'CELR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'PEOPLEBNB',
      balas: 'BNB',
      trdas: 'PEOPLE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'SLPBNB',
      balas: 'BNB',
      trdas: 'SLP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'SPELLBNB',
      balas: 'BNB',
      trdas: 'SPELL',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'SPELLTRY',
      balas: 'TRY',
      trdas: 'SPELL',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'TFUELBUSD',
      balas: 'BUSD',
      trdas: 'TFUEL',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AXSTRY',
      balas: 'TRY',
      trdas: 'AXS',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'DARTRY',
      balas: 'TRY',
      trdas: 'DAR',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'NEARTRY',
      balas: 'TRY',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'IDEXBNB',
      balas: 'BNB',
      trdas: 'IDEX',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'ALPINEEUR',
      balas: 'EUR',
      trdas: 'ALPINE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ALPINETRY',
      balas: 'TRY',
      trdas: 'ALPINE',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ALPINEUSDT',
      balas: 'USDT',
      trdas: 'ALPINE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ALPINEBTC',
      balas: 'BTC',
      trdas: 'ALPINE',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'TUSDT',
      balas: 'USDT',
      trdas: 'T',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TBUSD',
      balas: 'BUSD',
      trdas: 'T',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'API3BNB',
      balas: 'BNB',
      trdas: 'API3',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'BETAETH',
      balas: 'ETH',
      trdas: 'BETA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'INJTRY',
      balas: 'TRY',
      trdas: 'INJ',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'TLMBNB',
      balas: 'BNB',
      trdas: 'TLM',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.05
    },
    {
      tk: 'ASTRBUSD',
      balas: 'BUSD',
      trdas: 'ASTR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ASTRUSDT',
      balas: 'USDT',
      trdas: 'ASTR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'API3TRY',
      balas: 'TRY',
      trdas: 'API3',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'GLMRBNB',
      balas: 'BNB',
      trdas: 'GLMR',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'MBOXTRY',
      balas: 'TRY',
      trdas: 'MBOX',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'GMTBTC',
      balas: 'BTC',
      trdas: 'GMT',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'GMTBNB',
      balas: 'BNB',
      trdas: 'GMT',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'GMTBUSD',
      balas: 'BUSD',
      trdas: 'GMT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'GMTUSDT',
      balas: 'USDT',
      trdas: 'GMT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ANCBNB',
      balas: 'BNB',
      trdas: 'ANC',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.05
    },
    {
      tk: 'ATOMEUR',
      balas: 'EUR',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'GALAEUR',
      balas: 'EUR',
      trdas: 'GALA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'KSMETH',
      balas: 'ETH',
      trdas: 'KSM',
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 0.005
    },
    {
      tk: 'UMATRY',
      balas: 'TRY',
      trdas: 'UMA',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'KDABTC',
      balas: 'BTC',
      trdas: 'KDA',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'KDABUSD',
      balas: 'BUSD',
      trdas: 'KDA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'KDAUSDT',
      balas: 'USDT',
      trdas: 'KDA',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'APEUSDT',
      balas: 'USDT',
      trdas: 'APE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'APEBUSD',
      balas: 'BUSD',
      trdas: 'APE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'APEBTC',
      balas: 'BTC',
      trdas: 'APE',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ALPINEBUSD',
      balas: 'BUSD',
      trdas: 'ALPINE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LUNAGBP',
      balas: 'GBP',
      trdas: 'LUNA',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: 'NEAREUR',
      balas: 'EUR',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TWTTRY',
      balas: 'TRY',
      trdas: 'TWT',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'WAVESEUR',
      balas: 'EUR',
      trdas: 'WAVES',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'APEEUR',
      balas: 'EUR',
      trdas: 'APE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'APEGBP',
      balas: 'GBP',
      trdas: 'APE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'APETRY',
      balas: 'TRY',
      trdas: 'APE',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'BSWUSDT',
      balas: 'USDT',
      trdas: 'BSW',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BSWBUSD',
      balas: 'BUSD',
      trdas: 'BSW',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BSWBNB',
      balas: 'BNB',
      trdas: 'BSW',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'APEBNB',
      balas: 'BNB',
      trdas: 'APE',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.01
    },
    {
      tk: 'GMTBRL',
      balas: 'BRL',
      trdas: 'GMT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'GMTETH',
      balas: 'ETH',
      trdas: 'GMT',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'JASMYTRY',
      balas: 'TRY',
      trdas: 'JASMY',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SANTOSBUSD',
      balas: 'BUSD',
      trdas: 'SANTOS',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'APEAUD',
      balas: 'AUD',
      trdas: 'APE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BIFIUSDT',
      balas: 'USDT',
      trdas: 'BIFI',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'GMTEUR',
      balas: 'EUR',
      trdas: 'GMT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'IMXBNB',
      balas: 'BNB',
      trdas: 'IMX',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'RUNEETH',
      balas: 'ETH',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'AVAXGBP',
      balas: 'GBP',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'MULTIBTC',
      balas: 'BTC',
      trdas: 'MULTI',
      scnt: 1,
      pdec: 8,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'MULTIBUSD',
      balas: 'BUSD',
      trdas: 'MULTI',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'MULTIUSDT',
      balas: 'USDT',
      trdas: 'MULTI',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: 'APEETH',
      balas: 'ETH',
      trdas: 'APE',
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 0.001
    },
    {
      tk: 'BSWETH',
      balas: 'ETH',
      trdas: 'BSW',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'FILTRY',
      balas: 'TRY',
      trdas: 'FIL',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'FTMEUR',
      balas: 'EUR',
      trdas: 'FTM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GMTGBP',
      balas: 'GBP',
      trdas: 'GMT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ZILTRY',
      balas: 'TRY',
      trdas: 'ZIL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'GMTTRY',
      balas: 'TRY',
      trdas: 'GMT',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'WAVESTRY',
      balas: 'TRY',
      trdas: 'WAVES',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'BTCUST',
      balas: 'UST',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'ASTRBTC',
      balas: 'BTC',
      trdas: 'ASTR',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ASTRETH',
      balas: 'ETH',
      trdas: 'ASTR',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'BSWTRY',
      balas: 'TRY',
      trdas: 'BSW',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'FTTETH',
      balas: 'ETH',
      trdas: 'FTT',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.005
    },
    {
      tk: 'FUNBNB',
      balas: 'BNB',
      trdas: 'FUN',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'PORTOBUSD',
      balas: 'BUSD',
      trdas: 'PORTO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'STEEMUSDT',
      balas: 'USDT',
      trdas: 'STEEM',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ZILEUR',
      balas: 'EUR',
      trdas: 'ZIL',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'APEBRL',
      balas: 'BRL',
      trdas: 'APE',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'AUDIOTRY',
      balas: 'TRY',
      trdas: 'AUDIO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BTTCBUSD',
      balas: 'BUSD',
      trdas: 'BTTC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10
    },
    {
      tk: 'GMTAUD',
      balas: 'AUD',
      trdas: 'GMT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MBLBUSD',
      balas: 'BUSD',
      trdas: 'MBL',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'MOBUSDT',
      balas: 'USDT',
      trdas: 'MOB',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'MOBBUSD',
      balas: 'BUSD',
      trdas: 'MOB',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'MOBBTC',
      balas: 'BTC',
      trdas: 'MOB',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'NEXOUSDT',
      balas: 'USDT',
      trdas: 'NEXO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'NEXOBUSD',
      balas: 'BUSD',
      trdas: 'NEXO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'NEXOBTC',
      balas: 'BTC',
      trdas: 'NEXO',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'REIUSDT',
      balas: 'USDT',
      trdas: 'REI',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 1
    },
    {
      tk: 'REIBNB',
      balas: 'BNB',
      trdas: 'REI',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.05
    },
    {
      tk: 'REIETH',
      balas: 'ETH',
      trdas: 'REI',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'GALUSDT',
      balas: 'USDT',
      trdas: 'GAL',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 5
    },
    {
      tk: 'GALBUSD',
      balas: 'BUSD',
      trdas: 'GAL',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'GALBNB',
      balas: 'BNB',
      trdas: 'GAL',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'GALBTC',
      balas: 'BTC',
      trdas: 'GAL',
      scnt: 1,
      pdec: 8,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'JASMYEUR',
      balas: 'EUR',
      trdas: 'JASMY',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 10
    },
    {
      tk: 'KNCBNB',
      balas: 'BNB',
      trdas: 'KNC',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'SHIBGBP',
      balas: 'GBP',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10
    },
    {
      tk: 'GALEUR',
      balas: 'EUR',
      trdas: 'GAL',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 10
    },
    {
      tk: 'GALTRY',
      balas: 'TRY',
      trdas: 'GAL',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'LDOBUSD',
      balas: 'BUSD',
      trdas: 'LDO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LDOUSDT',
      balas: 'USDT',
      trdas: 'LDO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'LDOBTC',
      balas: 'BTC',
      trdas: 'LDO',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ENSTRY',
      balas: 'TRY',
      trdas: 'ENS',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'DAREUR',
      balas: 'EUR',
      trdas: 'DAR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DARETH',
      balas: 'ETH',
      trdas: 'DAR',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'ALGOETH',
      balas: 'ETH',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'ALGOTRY',
      balas: 'TRY',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'GALETH',
      balas: 'ETH',
      trdas: 'GAL',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'EPXUSDT',
      balas: 'USDT',
      trdas: 'EPX',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: 'EPXBUSD',
      balas: 'BUSD',
      trdas: 'EPX',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'RUNETRY',
      balas: 'TRY',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'GALBRL',
      balas: 'BRL',
      trdas: 'GAL',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'STEEMBUSD',
      balas: 'BUSD',
      trdas: 'STEEM',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'CVCBUSD',
      balas: 'BUSD',
      trdas: 'CVC',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'REIBUSD',
      balas: 'BUSD',
      trdas: 'REI',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'DREPBUSD',
      balas: 'BUSD',
      trdas: 'DREP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AKROBUSD',
      balas: 'BUSD',
      trdas: 'AKRO',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'PUNDIXBUSD',
      balas: 'BUSD',
      trdas: 'PUNDIX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'LUNCBUSD',
      balas: 'BUSD',
      trdas: 'LUNC',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 5
    },
    {
      tk: 'USTCBUSD',
      balas: 'BUSD',
      trdas: 'USTC',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10
    },
    {
      tk: 'OPBTC',
      balas: 'BTC',
      trdas: 'OP',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'OPBUSD',
      balas: 'BUSD',
      trdas: 'OP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'OPUSDT',
      balas: 'USDT',
      trdas: 'OP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'OGBUSD',
      balas: 'BUSD',
      trdas: 'OG',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'KEYBUSD',
      balas: 'BUSD',
      trdas: 'KEY',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ASRBUSD',
      balas: 'BUSD',
      trdas: 'ASR',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'FIROBUSD',
      balas: 'BUSD',
      trdas: 'FIRO',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'NKNBUSD',
      balas: 'BUSD',
      trdas: 'NKN',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'OPBNB',
      balas: 'BNB',
      trdas: 'OP',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'OPEUR',
      balas: 'EUR',
      trdas: 'OP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'GTOBUSD',
      balas: 'BUSD',
      trdas: 'GTO',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SNXETH',
      balas: 'ETH',
      trdas: 'SNX',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'WBTCBUSD',
      balas: 'BUSD',
      trdas: 'WBTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 10
    },
    {
      tk: 'BELETH',
      balas: 'ETH',
      trdas: 'BEL',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'LITETH',
      balas: 'ETH',
      trdas: 'LIT',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.005
    },
    {
      tk: 'LEVERUSDT',
      balas: 'USDT',
      trdas: 'LEVER',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LEVERBUSD',
      balas: 'BUSD',
      trdas: 'LEVER',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BURGERETH',
      balas: 'ETH',
      trdas: 'BURGER',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'PEOPLEETH',
      balas: 'ETH',
      trdas: 'PEOPLE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.005
    },
    {
      tk: 'UNFIETH',
      balas: 'ETH',
      trdas: 'UNFI',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.005
    },
    {
      tk: 'BONDETH',
      balas: 'ETH',
      trdas: 'BOND',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.005
    },
    {
      tk: 'STORJTRY',
      balas: 'TRY',
      trdas: 'STORJ',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'OPETH',
      balas: 'ETH',
      trdas: 'OP',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'ETCTRY',
      balas: 'TRY',
      trdas: 'ETC',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'WINGETH',
      balas: 'ETH',
      trdas: 'WING',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.005
    },
    {
      tk: 'FILETH',
      balas: 'ETH',
      trdas: 'FIL',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'GLMBUSD',
      balas: 'BUSD',
      trdas: 'GLM',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SSVBUSD',
      balas: 'BUSD',
      trdas: 'SSV',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'STGBTC',
      balas: 'BTC',
      trdas: 'STG',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'STGBUSD',
      balas: 'BUSD',
      trdas: 'STG',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'STGUSDT',
      balas: 'USDT',
      trdas: 'STG',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ANKRTRY',
      balas: 'TRY',
      trdas: 'ANKR',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ARKBUSD',
      balas: 'BUSD',
      trdas: 'ARK',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BETHBUSD',
      balas: 'BUSD',
      trdas: 'BETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 10
    },
    {
      tk: 'LOOMBUSD',
      balas: 'BUSD',
      trdas: 'LOOM',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SNMBUSD',
      balas: 'BUSD',
      trdas: 'SNM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AMBBUSD',
      balas: 'BUSD',
      trdas: 'AMB',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'LUNCUSDT',
      balas: 'USDT',
      trdas: 'LUNC',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 5
    },
    {
      tk: 'PHBBUSD',
      balas: 'BUSD',
      trdas: 'PHB',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'GASBUSD',
      balas: 'BUSD',
      trdas: 'GAS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'NEBLBUSD',
      balas: 'BUSD',
      trdas: 'NEBL',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'PROSBUSD',
      balas: 'BUSD',
      trdas: 'PROS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'VIBBUSD',
      balas: 'BUSD',
      trdas: 'VIB',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'GMXBTC',
      balas: 'BTC',
      trdas: 'GMX',
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'GMXBUSD',
      balas: 'BUSD',
      trdas: 'GMX',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: 'GMXUSDT',
      balas: 'USDT',
      trdas: 'GMX',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'AGIXBUSD',
      balas: 'BUSD',
      trdas: 'AGIX',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'NEBLUSDT',
      balas: 'USDT',
      trdas: 'NEBL',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SNTBUSD',
      balas: 'BUSD',
      trdas: 'SNT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'POLYXBTC',
      balas: 'BTC',
      trdas: 'POLYX',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'POLYXBUSD',
      balas: 'BUSD',
      trdas: 'POLYX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'POLYXUSDT',
      balas: 'USDT',
      trdas: 'POLYX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'APTBTC',
      balas: 'BTC',
      trdas: 'APT',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'APTUSDT',
      balas: 'USDT',
      trdas: 'APT',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'APTBUSD',
      balas: 'BUSD',
      trdas: 'APT',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BTCPLN',
      balas: 'PLN',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 25
    },
    {
      tk: 'ETHPLN',
      balas: 'PLN',
      trdas: 'ETH',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 25
    },
    {
      tk: 'BUSDPLN',
      balas: 'PLN',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 25
    },
    {
      tk: 'APTEUR',
      balas: 'EUR',
      trdas: 'APT',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'APTTRY',
      balas: 'TRY',
      trdas: 'APT',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'APTBRL',
      balas: 'BRL',
      trdas: 'APT',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'QKCBUSD',
      balas: 'BUSD',
      trdas: 'QKC',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'OSMOBTC',
      balas: 'BTC',
      trdas: 'OSMO',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'OSMOUSDT',
      balas: 'USDT',
      trdas: 'OSMO',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'OSMOBUSD',
      balas: 'BUSD',
      trdas: 'OSMO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'HFTBTC',
      balas: 'BTC',
      trdas: 'HFT',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'HFTBUSD',
      balas: 'BUSD',
      trdas: 'HFT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'HFTUSDT',
      balas: 'USDT',
      trdas: 'HFT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ARPAETH',
      balas: 'ETH',
      trdas: 'ARPA',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'PHBUSDT',
      balas: 'USDT',
      trdas: 'PHB',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'VITEBUSD',
      balas: 'BUSD',
      trdas: 'VITE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 10
    },
    {
      tk: 'HOOKBTC',
      balas: 'BTC',
      trdas: 'HOOK',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'HOOKUSDT',
      balas: 'USDT',
      trdas: 'HOOK',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'HOOKBUSD',
      balas: 'BUSD',
      trdas: 'HOOK',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'HOOKBNB',
      balas: 'BNB',
      trdas: 'HOOK',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'MAGICBTC',
      balas: 'BTC',
      trdas: 'MAGIC',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'MAGICBUSD',
      balas: 'BUSD',
      trdas: 'MAGIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MAGICUSDT',
      balas: 'USDT',
      trdas: 'MAGIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BUSDRON',
      balas: 'RON',
      trdas: 'BUSD',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 20
    },
    {
      tk: 'HIFIETH',
      balas: 'ETH',
      trdas: 'HIFI',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'HIFIUSDT',
      balas: 'USDT',
      trdas: 'HIFI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RPLBTC',
      balas: 'BTC',
      trdas: 'RPL',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'RPLBUSD',
      balas: 'BUSD',
      trdas: 'RPL',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'RPLUSDT',
      balas: 'USDT',
      trdas: 'RPL',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'PROSUSDT',
      balas: 'USDT',
      trdas: 'PROS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FETTRY',
      balas: 'TRY',
      trdas: 'FET',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'GFTBUSD',
      balas: 'BUSD',
      trdas: 'GFT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AGIXUSDT',
      balas: 'USDT',
      trdas: 'AGIX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'APTETH',
      balas: 'ETH',
      trdas: 'APT',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'BTCRON',
      balas: 'RON',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 20
    },
    {
      tk: 'GNSUSDT',
      balas: 'USDT',
      trdas: 'GNS',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'GNSBTC',
      balas: 'BTC',
      trdas: 'GNS',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'SYNBTC',
      balas: 'BTC',
      trdas: 'SYN',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SYNUSDT',
      balas: 'USDT',
      trdas: 'SYN',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'VIBUSDT',
      balas: 'USDT',
      trdas: 'VIB',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SSVUSDT',
      balas: 'USDT',
      trdas: 'SSV',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'LQTYUSDT',
      balas: 'USDT',
      trdas: 'LQTY',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'LQTYBTC',
      balas: 'BTC',
      trdas: 'LQTY',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'AMBUSDT',
      balas: 'USDT',
      trdas: 'AMB',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BETHUSDT',
      balas: 'USDT',
      trdas: 'BETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 5
    },
    {
      tk: 'CFXTRY',
      balas: 'TRY',
      trdas: 'CFX',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'STXTRY',
      balas: 'TRY',
      trdas: 'STX',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'USTCUSDT',
      balas: 'USDT',
      trdas: 'USTC',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GASUSDT',
      balas: 'USDT',
      trdas: 'GAS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'GLMUSDT',
      balas: 'USDT',
      trdas: 'GLM',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PROMUSDT',
      balas: 'USDT',
      trdas: 'PROM',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'QKCUSDT',
      balas: 'USDT',
      trdas: 'QKC',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'UFTUSDT',
      balas: 'USDT',
      trdas: 'UFT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'IDBTC',
      balas: 'BTC',
      trdas: 'ID',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'IDBNB',
      balas: 'BNB',
      trdas: 'ID',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'IDUSDT',
      balas: 'USDT',
      trdas: 'ID',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ARBBTC',
      balas: 'BTC',
      trdas: 'ARB',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ARBUSDT',
      balas: 'USDT',
      trdas: 'ARB',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AGIXTRY',
      balas: 'TRY',
      trdas: 'AGIX',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'LOOMUSDT',
      balas: 'USDT',
      trdas: 'LOOM',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'OAXUSDT',
      balas: 'USDT',
      trdas: 'OAX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ARBTUSD',
      balas: 'TUSD',
      trdas: 'ARB',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ARBTRY',
      balas: 'TRY',
      trdas: 'ARB',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ARBEUR',
      balas: 'EUR',
      trdas: 'ARB',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'IDTUSD',
      balas: 'TUSD',
      trdas: 'ID',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'IDTRY',
      balas: 'TRY',
      trdas: 'ID',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'IDEUR',
      balas: 'EUR',
      trdas: 'ID',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LDOTUSD',
      balas: 'TUSD',
      trdas: 'LDO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'MATICTUSD',
      balas: 'TUSD',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'OPTUSD',
      balas: 'TUSD',
      trdas: 'OP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'SOLTUSD',
      balas: 'TUSD',
      trdas: 'SOL',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'SSVTUSD',
      balas: 'TUSD',
      trdas: 'SSV',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'RDNTBTC',
      balas: 'BTC',
      trdas: 'RDNT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'RDNTUSDT',
      balas: 'USDT',
      trdas: 'RDNT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RDNTTUSD',
      balas: 'TUSD',
      trdas: 'RDNT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ARBRUB',
      balas: 'RUB',
      trdas: 'ARB',
      scnt: 1,
      pdec: 1,
      sdec: 1,
      min: 100
    },
    {
      tk: 'JOETRY',
      balas: 'TRY',
      trdas: 'JOE',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MAGICTRY',
      balas: 'TRY',
      trdas: 'MAGIC',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'USDTPLN',
      balas: 'PLN',
      trdas: 'USDT',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 25
    },
    {
      tk: 'ACHTRY',
      balas: 'TRY',
      trdas: 'ACH',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'XVSTRY',
      balas: 'TRY',
      trdas: 'XVS',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'EGLDRON',
      balas: 'RON',
      trdas: 'EGLD',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 20
    },
    {
      tk: 'USDTRON',
      balas: 'RON',
      trdas: 'USDT',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 20
    },
    {
      tk: 'USDTARS',
      balas: 'ARS',
      trdas: 'USDT',
      scnt: 1,
      pdec: 1,
      sdec: 0,
      min: 2000
    },
    {
      tk: 'DOGETUSD',
      balas: 'TUSD',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'WBTCUSDT',
      balas: 'USDT',
      trdas: 'WBTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 5
    },
    {
      tk: 'EDUUSDT',
      balas: 'USDT',
      trdas: 'EDU',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'EDUTUSD',
      balas: 'TUSD',
      trdas: 'EDU',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'EDUBNB',
      balas: 'BNB',
      trdas: 'EDU',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'EDUBTC',
      balas: 'BTC',
      trdas: 'EDU',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'EDUEUR',
      balas: 'EUR',
      trdas: 'EDU',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'EDUTRY',
      balas: 'TRY',
      trdas: 'EDU',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SUIUSDT',
      balas: 'USDT',
      trdas: 'SUI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SUITUSD',
      balas: 'TUSD',
      trdas: 'SUI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SUIBTC',
      balas: 'BTC',
      trdas: 'SUI',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SUIBNB',
      balas: 'BNB',
      trdas: 'SUI',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'SUIEUR',
      balas: 'EUR',
      trdas: 'SUI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SUITRY',
      balas: 'TRY',
      trdas: 'SUI',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'AERGOUSDT',
      balas: 'USDT',
      trdas: 'AERGO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RNDRTRY',
      balas: 'TRY',
      trdas: 'RNDR',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'PEPEUSDT',
      balas: 'USDT',
      trdas: 'PEPE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'PEPETUSD',
      balas: 'TUSD',
      trdas: 'PEPE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'FLOKIUSDT',
      balas: 'USDT',
      trdas: 'FLOKI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'FLOKITUSD',
      balas: 'TUSD',
      trdas: 'FLOKI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'OGTRY',
      balas: 'TRY',
      trdas: 'OG',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PEPETRY',
      balas: 'TRY',
      trdas: 'PEPE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10
    },
    {
      tk: 'WBETHETH',
      balas: 'ETH',
      trdas: 'WBETH',
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 0.001
    },
    {
      tk: 'ASTUSDT',
      balas: 'USDT',
      trdas: 'AST',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SNTUSDT',
      balas: 'USDT',
      trdas: 'SNT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FLOKITRY',
      balas: 'TRY',
      trdas: 'FLOKI',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'CITYTRY',
      balas: 'TRY',
      trdas: 'CITY',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'COMBOUSDT',
      balas: 'USDT',
      trdas: 'COMBO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'COMBOBNB',
      balas: 'BNB',
      trdas: 'COMBO',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'COMBOTRY',
      balas: 'TRY',
      trdas: 'COMBO',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'LTCTRY',
      balas: 'TRY',
      trdas: 'LTC',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 10
    },
    {
      tk: 'RADTRY',
      balas: 'TRY',
      trdas: 'RAD',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BTCARS',
      balas: 'ARS',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 2000
    },
    {
      tk: 'OPTRY',
      balas: 'TRY',
      trdas: 'OP',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PAXGTRY',
      balas: 'TRY',
      trdas: 'PAXG',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 10
    },
    {
      tk: 'MAVBTC',
      balas: 'BTC',
      trdas: 'MAV',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'MAVUSDT',
      balas: 'USDT',
      trdas: 'MAV',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MAVTUSD',
      balas: 'TUSD',
      trdas: 'MAV',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CFXTUSD',
      balas: 'TUSD',
      trdas: 'CFX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PENDLEBTC',
      balas: 'BTC',
      trdas: 'PENDLE',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'PENDLEUSDT',
      balas: 'USDT',
      trdas: 'PENDLE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PENDLETUSD',
      balas: 'TUSD',
      trdas: 'PENDLE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MAVTRY',
      balas: 'TRY',
      trdas: 'MAV',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'OCEANTRY',
      balas: 'TRY',
      trdas: 'OCEAN',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'TUSDTRY',
      balas: 'TRY',
      trdas: 'TUSD',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ARBETH',
      balas: 'ETH',
      trdas: 'ARB',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'BCHTRY',
      balas: 'TRY',
      trdas: 'BCH',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 10
    },
    {
      tk: 'XVGTRY',
      balas: 'TRY',
      trdas: 'XVG',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'XVGTUSD',
      balas: 'TUSD',
      trdas: 'XVG',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ARKMUSDT',
      balas: 'USDT',
      trdas: 'ARKM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ARKMTUSD',
      balas: 'TUSD',
      trdas: 'ARKM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ARKMTRY',
      balas: 'TRY',
      trdas: 'ARKM',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ARKMBNB',
      balas: 'BNB',
      trdas: 'ARKM',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'ARKMBTC',
      balas: 'BTC',
      trdas: 'ARKM',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'WBETHUSDT',
      balas: 'USDT',
      trdas: 'WBETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 5
    },
    {
      tk: 'ACATRY',
      balas: 'TRY',
      trdas: 'ACA',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'AVAXTUSD',
      balas: 'TUSD',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'COMPTUSD',
      balas: 'TUSD',
      trdas: 'COMP',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'COMPTRY',
      balas: 'TRY',
      trdas: 'COMP',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 10
    },
    {
      tk: 'XECTRY',
      balas: 'TRY',
      trdas: 'XEC',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'QUICKTUSD',
      balas: 'TUSD',
      trdas: 'QUICK',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'WLDUSDT',
      balas: 'USDT',
      trdas: 'WLD',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'WLDBTC',
      balas: 'BTC',
      trdas: 'WLD',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'BNBFDUSD',
      balas: 'FDUSD',
      trdas: 'BNB',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'FDUSDBUSD',
      balas: 'BUSD',
      trdas: 'FDUSD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'FDUSDUSDT',
      balas: 'USDT',
      trdas: 'FDUSD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ARKMRUB',
      balas: 'RUB',
      trdas: 'ARKM',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 100
    },
    {
      tk: 'WLDTRY',
      balas: 'TRY',
      trdas: 'WLD',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'WLDRUB',
      balas: 'RUB',
      trdas: 'WLD',
      scnt: 1,
      pdec: 1,
      sdec: 1,
      min: 100
    },
    {
      tk: 'AMPTRY',
      balas: 'TRY',
      trdas: 'AMP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'OGNTRY',
      balas: 'TRY',
      trdas: 'OGN',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BTCFDUSD',
      balas: 'FDUSD',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 5
    },
    {
      tk: 'ETHFDUSD',
      balas: 'FDUSD',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 5
    },
    {
      tk: 'ASRTRY',
      balas: 'TRY',
      trdas: 'ASR',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ATMTRY',
      balas: 'TRY',
      trdas: 'ATM',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ACMTRY',
      balas: 'TRY',
      trdas: 'ACM',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BARTRY',
      balas: 'TRY',
      trdas: 'BAR',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'JUVTRY',
      balas: 'TRY',
      trdas: 'JUV',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'PSGTRY',
      balas: 'TRY',
      trdas: 'PSG',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SEIBNB',
      balas: 'BNB',
      trdas: 'SEI',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'SEIBTC',
      balas: 'BTC',
      trdas: 'SEI',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SEIFDUSD',
      balas: 'FDUSD',
      trdas: 'SEI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SEITRY',
      balas: 'TRY',
      trdas: 'SEI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SEIUSDT',
      balas: 'USDT',
      trdas: 'SEI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CYBERBNB',
      balas: 'BNB',
      trdas: 'CYBER',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'CYBERBTC',
      balas: 'BTC',
      trdas: 'CYBER',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'CYBERFDUSD',
      balas: 'FDUSD',
      trdas: 'CYBER',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'CYBERTRY',
      balas: 'TRY',
      trdas: 'CYBER',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'CYBERUSDT',
      balas: 'USDT',
      trdas: 'CYBER',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'CYBERTUSD',
      balas: 'TUSD',
      trdas: 'CYBER',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'SEITUSD',
      balas: 'TUSD',
      trdas: 'SEI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'LPTTRY',
      balas: 'TRY',
      trdas: 'LPT',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'UNITRY',
      balas: 'TRY',
      trdas: 'UNI',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'SOLFDUSD',
      balas: 'FDUSD',
      trdas: 'SOL',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'TOMOTRY',
      balas: 'TRY',
      trdas: 'TOMO',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'UNFITRY',
      balas: 'TRY',
      trdas: 'UNFI',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'XRPFDUSD',
      balas: 'FDUSD',
      trdas: 'XRP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DOGEFDUSD',
      balas: 'FDUSD',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'CYBERETH',
      balas: 'ETH',
      trdas: 'CYBER',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'MTLTRY',
      balas: 'TRY',
      trdas: 'MTL',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ARKUSDT',
      balas: 'USDT',
      trdas: 'ARK',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CREAMUSDT',
      balas: 'USDT',
      trdas: 'CREAM',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 1
    },
    {
      tk: 'GFTUSDT',
      balas: 'USDT',
      trdas: 'GFT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'IQUSDT',
      balas: 'USDT',
      trdas: 'IQ',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'USDTVAI',
      balas: 'VAI',
      trdas: 'USDT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ARBFDUSD',
      balas: 'FDUSD',
      trdas: 'ARB',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'FDUSDTRY',
      balas: 'TRY',
      trdas: 'FDUSD',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'FRONTTRY',
      balas: 'TRY',
      trdas: 'FRONT',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SUIFDUSD',
      balas: 'FDUSD',
      trdas: 'SUI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'NTRNBTC',
      balas: 'BTC',
      trdas: 'NTRN',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'NTRNUSDT',
      balas: 'USDT',
      trdas: 'NTRN',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'NTRNBNB',
      balas: 'BNB',
      trdas: 'NTRN',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'FILFDUSD',
      balas: 'FDUSD',
      trdas: 'FIL',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'FRONTTUSD',
      balas: 'TUSD',
      trdas: 'FRONT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LEVERTRY',
      balas: 'TRY',
      trdas: 'LEVER',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'LTCFDUSD',
      balas: 'FDUSD',
      trdas: 'LTC',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'ADAFDUSD',
      balas: 'FDUSD',
      trdas: 'ADA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RUNETUSD',
      balas: 'TUSD',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TRBTRY',
      balas: 'TRY',
      trdas: 'TRB',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 10
    },
    {
      tk: 'ATOMFDUSD',
      balas: 'FDUSD',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AVAXFDUSD',
      balas: 'FDUSD',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BANDTRY',
      balas: 'TRY',
      trdas: 'BAND',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BCHFDUSD',
      balas: 'FDUSD',
      trdas: 'BCH',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'LOOMTRY',
      balas: 'TRY',
      trdas: 'LOOM',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'MATICFDUSD',
      balas: 'FDUSD',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ALGOFDUSD',
      balas: 'FDUSD',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DOTFDUSD',
      balas: 'FDUSD',
      trdas: 'DOT',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'FTMFDUSD',
      balas: 'FDUSD',
      trdas: 'FTM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LINKFDUSD',
      balas: 'FDUSD',
      trdas: 'LINK',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'NEARFDUSD',
      balas: 'FDUSD',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'STRAXTRY',
      balas: 'TRY',
      trdas: 'STRAX',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'TIABTC',
      balas: 'BTC',
      trdas: 'TIA',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'TIAUSDT',
      balas: 'USDT',
      trdas: 'TIA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'TIATRY',
      balas: 'TRY',
      trdas: 'TIA',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'MEMEBNB',
      balas: 'BNB',
      trdas: 'MEME',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'MEMEUSDT',
      balas: 'USDT',
      trdas: 'MEME',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'MEMEFDUSD',
      balas: 'FDUSD',
      trdas: 'MEME',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'MEMETUSD',
      balas: 'TUSD',
      trdas: 'MEME',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'MEMETRY',
      balas: 'TRY',
      trdas: 'MEME',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ORDIBTC',
      balas: 'BTC',
      trdas: 'ORDI',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ORDIUSDT',
      balas: 'USDT',
      trdas: 'ORDI',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ORDITRY',
      balas: 'TRY',
      trdas: 'ORDI',
      scnt: 1,
      pdec: 0,
      sdec: 2,
      min: 10
    },
    {
      tk: 'EGLDFDUSD',
      balas: 'FDUSD',
      trdas: 'EGLD',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'FETFDUSD',
      balas: 'FDUSD',
      trdas: 'FET',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'GASFDUSD',
      balas: 'FDUSD',
      trdas: 'GAS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'INJETH',
      balas: 'ETH',
      trdas: 'INJ',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'INJTUSD',
      balas: 'TUSD',
      trdas: 'INJ',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'OPFDUSD',
      balas: 'FDUSD',
      trdas: 'OP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ORDIFDUSD',
      balas: 'FDUSD',
      trdas: 'ORDI',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ORDITUSD',
      balas: 'TUSD',
      trdas: 'ORDI',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'RNDRFDUSD',
      balas: 'FDUSD',
      trdas: 'RNDR',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'SHIBTUSD',
      balas: 'TUSD',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BEAMXUSDT',
      balas: 'USDT',
      trdas: 'BEAMX',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ARKTRY',
      balas: 'TRY',
      trdas: 'ARK',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BEAMXTRY',
      balas: 'TRY',
      trdas: 'BEAMX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'CAKETRY',
      balas: 'TRY',
      trdas: 'CAKE',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'CAKETUSD',
      balas: 'TUSD',
      trdas: 'CAKE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'DYDXFDUSD',
      balas: 'FDUSD',
      trdas: 'DYDX',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'PIVXUSDT',
      balas: 'USDT',
      trdas: 'PIVX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RUNEFDUSD',
      balas: 'FDUSD',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TIATUSD',
      balas: 'TUSD',
      trdas: 'TIA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'DOTTUSD',
      balas: 'TUSD',
      trdas: 'DOT',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'GALAFDUSD',
      balas: 'FDUSD',
      trdas: 'GALA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'WLDFDUSD',
      balas: 'FDUSD',
      trdas: 'WLD',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'GASTRY',
      balas: 'TRY',
      trdas: 'GAS',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'NTRNTRY',
      balas: 'TRY',
      trdas: 'NTRN',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'VICBTC',
      balas: 'BTC',
      trdas: 'VIC',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'VICUSDT',
      balas: 'USDT',
      trdas: 'VIC',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'VICTRY',
      balas: 'TRY',
      trdas: 'VIC',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BLURBTC',
      balas: 'BTC',
      trdas: 'BLUR',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'BLURUSDT',
      balas: 'USDT',
      trdas: 'BLUR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BLURTRY',
      balas: 'TRY',
      trdas: 'BLUR',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BLURFDUSD',
      balas: 'FDUSD',
      trdas: 'BLUR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SUPERFDUSD',
      balas: 'FDUSD',
      trdas: 'SUPER',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'USTCFDUSD',
      balas: 'FDUSD',
      trdas: 'USTC',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'USTCTRY',
      balas: 'TRY',
      trdas: 'USTC',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'DYDXTRY',
      balas: 'TRY',
      trdas: 'DYDX',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'VANRYUSDT',
      balas: 'USDT',
      trdas: 'VANRY',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'VANRYBTC',
      balas: 'BTC',
      trdas: 'VANRY',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BTCAEUR',
      balas: 'AEUR',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 5
    },
    {
      tk: 'AEURUSDT',
      balas: 'USDT',
      trdas: 'AEUR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ETHAEUR',
      balas: 'AEUR',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 5
    },
    {
      tk: 'EURAEUR',
      balas: 'AEUR',
      trdas: 'EUR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AUCTIONFDUSD',
      balas: 'FDUSD',
      trdas: 'AUCTION',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'IOTAFDUSD',
      balas: 'FDUSD',
      trdas: 'IOTA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LUNCTRY',
      balas: 'TRY',
      trdas: 'LUNC',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SUPERTRY',
      balas: 'TRY',
      trdas: 'SUPER',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'JTOUSDT',
      balas: 'USDT',
      trdas: 'JTO',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'JTOFDUSD',
      balas: 'FDUSD',
      trdas: 'JTO',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'JTOTRY',
      balas: 'TRY',
      trdas: 'JTO',
      scnt: 1,
      pdec: 1,
      sdec: 1,
      min: 10
    },
    {
      tk: '1000SATSUSDT',
      balas: 'USDT',
      trdas: '1000SATS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: '1000SATSFDUSD',
      balas: 'FDUSD',
      trdas: '1000SATS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: '1000SATSTRY',
      balas: 'TRY',
      trdas: '1000SATS',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SHIBFDUSD',
      balas: 'FDUSD',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'SANDFDUSD',
      balas: 'FDUSD',
      trdas: 'SAND',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MEMEETH',
      balas: 'ETH',
      trdas: 'MEME',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.001
    },
    {
      tk: 'IOTATRY',
      balas: 'TRY',
      trdas: 'IOTA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'INJFDUSD',
      balas: 'FDUSD',
      trdas: 'INJ',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'FIDATRY',
      balas: 'TRY',
      trdas: 'FIDA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BONKUSDT',
      balas: 'USDT',
      trdas: 'BONK',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BONKFDUSD',
      balas: 'FDUSD',
      trdas: 'BONK',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BONKTRY',
      balas: 'TRY',
      trdas: 'BONK',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ACEFDUSD',
      balas: 'FDUSD',
      trdas: 'ACE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ACEUSDT',
      balas: 'USDT',
      trdas: 'ACE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ACEBNB',
      balas: 'BNB',
      trdas: 'ACE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'ACEBTC',
      balas: 'BTC',
      trdas: 'ACE',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ACETRY',
      balas: 'TRY',
      trdas: 'ACE',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BLZFDUSD',
      balas: 'FDUSD',
      trdas: 'BLZ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RARETRY',
      balas: 'TRY',
      trdas: 'RARE',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'VANRYTRY',
      balas: 'TRY',
      trdas: 'VANRY',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'NFPBTC',
      balas: 'BTC',
      trdas: 'NFP',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'NFPUSDT',
      balas: 'USDT',
      trdas: 'NFP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NFPBNB',
      balas: 'BNB',
      trdas: 'NFP',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'NFPFDUSD',
      balas: 'FDUSD',
      trdas: 'NFP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NFPTUSD',
      balas: 'TUSD',
      trdas: 'NFP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NFPTRY',
      balas: 'TRY',
      trdas: 'NFP',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ARBUSDC',
      balas: 'USDC',
      trdas: 'ARB',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AVAXUSDC',
      balas: 'USDC',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'DOTUSDC',
      balas: 'USDC',
      trdas: 'DOT',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'INJUSDC',
      balas: 'USDC',
      trdas: 'INJ',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'MATICUSDC',
      balas: 'USDC',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'OPUSDC',
      balas: 'USDC',
      trdas: 'OP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ORDIUSDC',
      balas: 'USDC',
      trdas: 'ORDI',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AIBTC',
      balas: 'BTC',
      trdas: 'AI',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'AIUSDT',
      balas: 'USDT',
      trdas: 'AI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AIBNB',
      balas: 'BNB',
      trdas: 'AI',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'AIFDUSD',
      balas: 'FDUSD',
      trdas: 'AI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AITUSD',
      balas: 'TUSD',
      trdas: 'AI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AITRY',
      balas: 'TRY',
      trdas: 'AI',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ICPFDUSD',
      balas: 'FDUSD',
      trdas: 'ICP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'LDOFDUSD',
      balas: 'FDUSD',
      trdas: 'LDO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'MOVRTRY',
      balas: 'TRY',
      trdas: 'MOVR',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'XAIBTC',
      balas: 'BTC',
      trdas: 'XAI',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'XAIUSDT',
      balas: 'USDT',
      trdas: 'XAI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'XAIBNB',
      balas: 'BNB',
      trdas: 'XAI',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'XAIFDUSD',
      balas: 'FDUSD',
      trdas: 'XAI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'XAITUSD',
      balas: 'TUSD',
      trdas: 'XAI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'XAITRY',
      balas: 'TRY',
      trdas: 'XAI',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SKLTRY',
      balas: 'TRY',
      trdas: 'SKL',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'STXFDUSD',
      balas: 'FDUSD',
      trdas: 'STX',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TIAFDUSD',
      balas: 'FDUSD',
      trdas: 'TIA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'MANTABTC',
      balas: 'BTC',
      trdas: 'MANTA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'MANTAUSDT',
      balas: 'USDT',
      trdas: 'MANTA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MANTABNB',
      balas: 'BNB',
      trdas: 'MANTA',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'MANTAFDUSD',
      balas: 'FDUSD',
      trdas: 'MANTA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MANTATRY',
      balas: 'TRY',
      trdas: 'MANTA',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ENSFDUSD',
      balas: 'FDUSD',
      trdas: 'ENS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ETCFDUSD',
      balas: 'FDUSD',
      trdas: 'ETC',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'SUIUSDC',
      balas: 'USDC',
      trdas: 'SUI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TIAUSDC',
      balas: 'USDC',
      trdas: 'TIA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'CHZFDUSD',
      balas: 'FDUSD',
      trdas: 'CHZ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MANTAUSDC',
      balas: 'USDC',
      trdas: 'MANTA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ALTBTC',
      balas: 'BTC',
      trdas: 'ALT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'ALTUSDT',
      balas: 'USDT',
      trdas: 'ALT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ALTBNB',
      balas: 'BNB',
      trdas: 'ALT',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'ALTFDUSD',
      balas: 'FDUSD',
      trdas: 'ALT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ALTTRY',
      balas: 'TRY',
      trdas: 'ALT',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'APTFDUSD',
      balas: 'FDUSD',
      trdas: 'APT',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BLURUSDC',
      balas: 'USDC',
      trdas: 'BLUR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'JUPUSDT',
      balas: 'USDT',
      trdas: 'JUP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'JUPFDUSD',
      balas: 'FDUSD',
      trdas: 'JUP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'JUPTRY',
      balas: 'TRY',
      trdas: 'JUP',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ALTUSDC',
      balas: 'USDC',
      trdas: 'ALT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MAGICFDUSD',
      balas: 'FDUSD',
      trdas: 'MAGIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SEIUSDC',
      balas: 'USDC',
      trdas: 'SEI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PYTHBTC',
      balas: 'BTC',
      trdas: 'PYTH',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'PYTHUSDT',
      balas: 'USDT',
      trdas: 'PYTH',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PYTHFDUSD',
      balas: 'FDUSD',
      trdas: 'PYTH',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PYTHTRY',
      balas: 'TRY',
      trdas: 'PYTH',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'RONINBTC',
      balas: 'BTC',
      trdas: 'RONIN',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'RONINUSDT',
      balas: 'USDT',
      trdas: 'RONIN',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'RONINFDUSD',
      balas: 'FDUSD',
      trdas: 'RONIN',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'RONINTRY',
      balas: 'TRY',
      trdas: 'RONIN',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'DYMBTC',
      balas: 'BTC',
      trdas: 'DYM',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'DYMUSDT',
      balas: 'USDT',
      trdas: 'DYM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DYMFDUSD',
      balas: 'FDUSD',
      trdas: 'DYM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DYMTRY',
      balas: 'TRY',
      trdas: 'DYM',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'JUPUSDC',
      balas: 'USDC',
      trdas: 'JUP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PENDLEFDUSD',
      balas: 'FDUSD',
      trdas: 'PENDLE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PIXELBTC',
      balas: 'BTC',
      trdas: 'PIXEL',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'PIXELBNB',
      balas: 'BNB',
      trdas: 'PIXEL',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'PIXELUSDT',
      balas: 'USDT',
      trdas: 'PIXEL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PIXELFDUSD',
      balas: 'FDUSD',
      trdas: 'PIXEL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PIXELTRY',
      balas: 'TRY',
      trdas: 'PIXEL',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'STRKBTC',
      balas: 'BTC',
      trdas: 'STRK',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'STRKUSDT',
      balas: 'USDT',
      trdas: 'STRK',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'STRKFDUSD',
      balas: 'FDUSD',
      trdas: 'STRK',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'STRKTRY',
      balas: 'TRY',
      trdas: 'STRK',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'FILUSDC',
      balas: 'USDC',
      trdas: 'FIL',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'HBARTRY',
      balas: 'TRY',
      trdas: 'HBAR',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'PENDLETRY',
      balas: 'TRY',
      trdas: 'PENDLE',
      scnt: 1,
      pdec: 1,
      sdec: 1,
      min: 10
    },
    {
      tk: 'WLDUSDC',
      balas: 'USDC',
      trdas: 'WLD',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CKBTRY',
      balas: 'TRY',
      trdas: 'CKB',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'COTITRY',
      balas: 'TRY',
      trdas: 'COTI',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'LDOTRY',
      balas: 'TRY',
      trdas: 'LDO',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'UNIUSDC',
      balas: 'USDC',
      trdas: 'UNI',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'PORTALBTC',
      balas: 'BTC',
      trdas: 'PORTAL',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'PORTALUSDT',
      balas: 'USDT',
      trdas: 'PORTAL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PORTALBNB',
      balas: 'BNB',
      trdas: 'PORTAL',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'PORTALFDUSD',
      balas: 'FDUSD',
      trdas: 'PORTAL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PORTALTRY',
      balas: 'TRY',
      trdas: 'PORTAL',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'PDABTC',
      balas: 'BTC',
      trdas: 'PDA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'PDAUSDT',
      balas: 'USDT',
      trdas: 'PDA',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AXLBTC',
      balas: 'BTC',
      trdas: 'AXL',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'AXLUSDT',
      balas: 'USDT',
      trdas: 'AXL',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AXLFDUSD',
      balas: 'FDUSD',
      trdas: 'AXL',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AXLTRY',
      balas: 'TRY',
      trdas: 'AXL',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PEPEFDUSD',
      balas: 'FDUSD',
      trdas: 'PEPE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'PIXELUSDC',
      balas: 'USDC',
      trdas: 'PIXEL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'STRKUSDC',
      balas: 'USDC',
      trdas: 'STRK',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'UNIFDUSD',
      balas: 'FDUSD',
      trdas: 'UNI',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'OMTRY',
      balas: 'TRY',
      trdas: 'OM',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'THETATRY',
      balas: 'TRY',
      trdas: 'THETA',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'WIFBTC',
      balas: 'BTC',
      trdas: 'WIF',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'WIFUSDT',
      balas: 'USDT',
      trdas: 'WIF',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 1
    },
    {
      tk: 'WIFFDUSD',
      balas: 'FDUSD',
      trdas: 'WIF',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 1
    },
    {
      tk: 'WIFTRY',
      balas: 'TRY',
      trdas: 'WIF',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'AGIXFDUSD',
      balas: 'FDUSD',
      trdas: 'AGIX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PEPEUSDC',
      balas: 'USDC',
      trdas: 'PEPE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'SHIBUSDC',
      balas: 'USDC',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'THETAFDUSD',
      balas: 'FDUSD',
      trdas: 'THETA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ARTRY',
      balas: 'TRY',
      trdas: 'AR',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'METISBTC',
      balas: 'BTC',
      trdas: 'METIS',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'METISUSDT',
      balas: 'USDT',
      trdas: 'METIS',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'METISFDUSD',
      balas: 'FDUSD',
      trdas: 'METIS',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'METISTRY',
      balas: 'TRY',
      trdas: 'METIS',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 10
    },
    {
      tk: 'BNBJPY',
      balas: 'JPY',
      trdas: 'BNB',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 100
    },
    {
      tk: 'BTCJPY',
      balas: 'JPY',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 6,
      min: 100
    },
    {
      tk: 'ETHJPY',
      balas: 'JPY',
      trdas: 'ETH',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 100
    },
    {
      tk: 'FLOKIFDUSD',
      balas: 'FDUSD',
      trdas: 'FLOKI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'GRTFDUSD',
      balas: 'FDUSD',
      trdas: 'GRT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NEARUSDC',
      balas: 'USDC',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SNXTRY',
      balas: 'TRY',
      trdas: 'SNX',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'AEVOBTC',
      balas: 'BTC',
      trdas: 'AEVO',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'AEVOUSDT',
      balas: 'USDT',
      trdas: 'AEVO',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AEVOBNB',
      balas: 'BNB',
      trdas: 'AEVO',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'AEVOFDUSD',
      balas: 'FDUSD',
      trdas: 'AEVO',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AEVOTRY',
      balas: 'TRY',
      trdas: 'AEVO',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'FETUSDC',
      balas: 'USDC',
      trdas: 'FET',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'IMXTRY',
      balas: 'TRY',
      trdas: 'IMX',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'EURUSDC',
      balas: 'USDC',
      trdas: 'EUR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BOMETRY',
      balas: 'TRY',
      trdas: 'BOME',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BOMEBTC',
      balas: 'BTC',
      trdas: 'BOME',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.0001
    },
    {
      tk: 'BOMEUSDT',
      balas: 'USDT',
      trdas: 'BOME',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BOMEFDUSD',
      balas: 'FDUSD',
      trdas: 'BOME',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ETHFIBTC',
      balas: 'BTC',
      trdas: 'ETHFI',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ETHFIUSDT',
      balas: 'USDT',
      trdas: 'ETHFI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ETHFIBNB',
      balas: 'BNB',
      trdas: 'ETHFI',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'ETHFIFDUSD',
      balas: 'FDUSD',
      trdas: 'ETHFI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ETHFITRY',
      balas: 'TRY',
      trdas: 'ETHFI',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'AAVETRY',
      balas: 'TRY',
      trdas: 'AAVE',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 10
    },
    {
      tk: 'ARKMFDUSD',
      balas: 'FDUSD',
      trdas: 'ARKM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CRVTRY',
      balas: 'TRY',
      trdas: 'CRV',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'FETBRL',
      balas: 'BRL',
      trdas: 'FET',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'RAYFDUSD',
      balas: 'FDUSD',
      trdas: 'RAY',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RNDREUR',
      balas: 'EUR',
      trdas: 'RNDR',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BONKUSDC',
      balas: 'USDC',
      trdas: 'BONK',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'FLOKIUSDC',
      balas: 'USDC',
      trdas: 'FLOKI',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'MKRTRY',
      balas: 'TRY',
      trdas: 'MKR',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 10
    },
    {
      tk: 'RAYTRY',
      balas: 'TRY',
      trdas: 'RAY',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'RNDRBRL',
      balas: 'BRL',
      trdas: 'RNDR',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ENABTC',
      balas: 'BTC',
      trdas: 'ENA',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ENAUSDT',
      balas: 'USDT',
      trdas: 'ENA',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ENABNB',
      balas: 'BNB',
      trdas: 'ENA',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'ENAFDUSD',
      balas: 'FDUSD',
      trdas: 'ENA',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ENATRY',
      balas: 'TRY',
      trdas: 'ENA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LQTYFDUSD',
      balas: 'FDUSD',
      trdas: 'LQTY',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MASKTRY',
      balas: 'TRY',
      trdas: 'MASK',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'PENDLEUSDC',
      balas: 'USDC',
      trdas: 'PENDLE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RDNTTRY',
      balas: 'TRY',
      trdas: 'RDNT',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'WBTC',
      balas: 'BTC',
      trdas: 'W',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'WUSDT',
      balas: 'USDT',
      trdas: 'W',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'WFDUSD',
      balas: 'FDUSD',
      trdas: 'W',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'WTRY',
      balas: 'TRY',
      trdas: 'W',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BOMEUSDC',
      balas: 'USDC',
      trdas: 'BOME',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: 'JTOUSDC',
      balas: 'USDC',
      trdas: 'JTO',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'WIFUSDC',
      balas: 'USDC',
      trdas: 'WIF',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 1
    },
    {
      tk: 'TNSRBTC',
      balas: 'BTC',
      trdas: 'TNSR',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'TNSRUSDT',
      balas: 'USDT',
      trdas: 'TNSR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TNSRFDUSD',
      balas: 'FDUSD',
      trdas: 'TNSR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TNSRTRY',
      balas: 'TRY',
      trdas: 'TNSR',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SAGABTC',
      balas: 'BTC',
      trdas: 'SAGA',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SAGAUSDT',
      balas: 'USDT',
      trdas: 'SAGA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SAGABNB',
      balas: 'BNB',
      trdas: 'SAGA',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'SAGAFDUSD',
      balas: 'FDUSD',
      trdas: 'SAGA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SAGATRY',
      balas: 'TRY',
      trdas: 'SAGA',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'USDTMXN',
      balas: 'MXN',
      trdas: 'USDT',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 150
    },
    {
      tk: 'CKBUSDC',
      balas: 'USDC',
      trdas: 'CKB',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ENAUSDC',
      balas: 'USDC',
      trdas: 'ENA',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ETHFIUSDC',
      balas: 'USDC',
      trdas: 'ETHFI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'YGGUSDC',
      balas: 'USDC',
      trdas: 'YGG',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'USDTCZK',
      balas: 'CZK',
      trdas: 'USDT',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 200
    },
    {
      tk: 'TAOBTC',
      balas: 'BTC',
      trdas: 'TAO',
      scnt: 1,
      pdec: 6,
      sdec: 4,
      min: 0.0001
    },
    {
      tk: 'TAOUSDT',
      balas: 'USDT',
      trdas: 'TAO',
      scnt: 1,
      pdec: 1,
      sdec: 4,
      min: 5
    },
    {
      tk: 'TAOFDUSD',
      balas: 'FDUSD',
      trdas: 'TAO',
      scnt: 1,
      pdec: 1,
      sdec: 4,
      min: 5
    },
    {
      tk: 'TAOTRY',
      balas: 'TRY',
      trdas: 'TAO',
      scnt: 1,
      pdec: 0,
      sdec: 4,
      min: 10
    },
    {
      tk: 'CFXUSDC',
      balas: 'USDC',
      trdas: 'CFX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RNDRUSDC',
      balas: 'USDC',
      trdas: 'RNDR',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'RUNEUSDC',
      balas: 'USDC',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SAGAUSDC',
      balas: 'USDC',
      trdas: 'SAGA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'POLYXTRY',
      balas: 'TRY',
      trdas: 'POLYX',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'OMNIBTC',
      balas: 'BTC',
      trdas: 'OMNI',
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'OMNIUSDT',
      balas: 'USDT',
      trdas: 'OMNI',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'OMNIBNB',
      balas: 'BNB',
      trdas: 'OMNI',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'OMNIFDUSD',
      balas: 'FDUSD',
      trdas: 'OMNI',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'OMNITRY',
      balas: 'TRY',
      trdas: 'OMNI',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'APTUSDC',
      balas: 'USDC',
      trdas: 'APT',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'GALAUSDC',
      balas: 'USDC',
      trdas: 'GALA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'OMNIBRL',
      balas: 'BRL',
      trdas: 'OMNI',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'STXUSDC',
      balas: 'USDC',
      trdas: 'STX',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ICPUSDC',
      balas: 'USDC',
      trdas: 'ICP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'OMNIUSDC',
      balas: 'USDC',
      trdas: 'OMNI',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'PEPEBRL',
      balas: 'BRL',
      trdas: 'PEPE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10
    },
    {
      tk: 'YGGTRY',
      balas: 'TRY',
      trdas: 'YGG',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ADAJPY',
      balas: 'JPY',
      trdas: 'ADA',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 100
    },
    {
      tk: 'SHIBJPY',
      balas: 'JPY',
      trdas: 'SHIB',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 100
    },
    {
      tk: 'SOLJPY',
      balas: 'JPY',
      trdas: 'SOL',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 100
    },
    {
      tk: 'XRPJPY',
      balas: 'JPY',
      trdas: 'XRP',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 100
    },
    {
      tk: 'REZBTC',
      balas: 'BTC',
      trdas: 'REZ',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'REZUSDT',
      balas: 'USDT',
      trdas: 'REZ',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'REZBNB',
      balas: 'BNB',
      trdas: 'REZ',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'REZFDUSD',
      balas: 'FDUSD',
      trdas: 'REZ',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'REZTRY',
      balas: 'TRY',
      trdas: 'REZ',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'EGLDTRY',
      balas: 'TRY',
      trdas: 'EGLD',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PHBTRY',
      balas: 'TRY',
      trdas: 'PHB',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'RSRTRY',
      balas: 'TRY',
      trdas: 'RSR',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BBBTC',
      balas: 'BTC',
      trdas: 'BB',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'BBUSDT',
      balas: 'USDT',
      trdas: 'BB',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BBBNB',
      balas: 'BNB',
      trdas: 'BB',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'BBFDUSD',
      balas: 'FDUSD',
      trdas: 'BB',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BBTRY',
      balas: 'TRY',
      trdas: 'BB',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'FRONTUSDC',
      balas: 'USDC',
      trdas: 'FRONT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PEOPLETRY',
      balas: 'TRY',
      trdas: 'PEOPLE',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'TRBUSDC',
      balas: 'USDC',
      trdas: 'TRB',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'NOTUSDT',
      balas: 'USDT',
      trdas: 'NOT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NOTBNB',
      balas: 'BNB',
      trdas: 'NOT',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'NOTFDUSD',
      balas: 'FDUSD',
      trdas: 'NOT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NOTTRY',
      balas: 'TRY',
      trdas: 'NOT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'ARKMUSDC',
      balas: 'USDC',
      trdas: 'ARKM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ARUSDC',
      balas: 'USDC',
      trdas: 'AR',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BBUSDC',
      balas: 'USDC',
      trdas: 'BB',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CRVUSDC',
      balas: 'USDC',
      trdas: 'CRV',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PEOPLEUSDC',
      balas: 'USDC',
      trdas: 'PEOPLE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 1
    },
    {
      tk: 'ARFDUSD',
      balas: 'FDUSD',
      trdas: 'AR',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ENAEUR',
      balas: 'EUR',
      trdas: 'ENA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'PEPEEUR',
      balas: 'EUR',
      trdas: 'PEPE',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'REZUSDC',
      balas: 'USDC',
      trdas: 'REZ',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TRBFDUSD',
      balas: 'FDUSD',
      trdas: 'TRB',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'USDCTRY',
      balas: 'TRY',
      trdas: 'USDC',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BTCMXN',
      balas: 'MXN',
      trdas: 'BTC',
      scnt: 1,
      pdec: 0,
      sdec: 6,
      min: 150
    },
    {
      tk: 'XRPMXN',
      balas: 'MXN',
      trdas: 'XRP',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 150
    },
    {
      tk: 'ENSUSDC',
      balas: 'USDC',
      trdas: 'ENS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'LDOUSDC',
      balas: 'USDC',
      trdas: 'LDO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'NOTUSDC',
      balas: 'USDC',
      trdas: 'NOT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NEARBRL',
      balas: 'BRL',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'HIGHTRY',
      balas: 'TRY',
      trdas: 'HIGH',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PEOPLEFDUSD',
      balas: 'FDUSD',
      trdas: 'PEOPLE',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 1
    },
    {
      tk: 'TNSRUSDC',
      balas: 'USDC',
      trdas: 'TNSR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'USDTCOP',
      balas: 'COP',
      trdas: 'USDT',
      scnt: 1,
      pdec: 0,
      sdec: 0,
      min: 4000
    },
    {
      tk: 'IOBTC',
      balas: 'BTC',
      trdas: 'IO',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'IOUSDT',
      balas: 'USDT',
      trdas: 'IO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'IOBNB',
      balas: 'BNB',
      trdas: 'IO',
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'IOFDUSD',
      balas: 'FDUSD',
      trdas: 'IO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'IOTRY',
      balas: 'TRY',
      trdas: 'IO',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'NOTBRL',
      balas: 'BRL',
      trdas: 'NOT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'TRUTRY',
      balas: 'TRY',
      trdas: 'TRU',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'WIFEUR',
      balas: 'EUR',
      trdas: 'WIF',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 1
    },
    {
      tk: 'ZKBTC',
      balas: 'BTC',
      trdas: 'ZK',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'ZKUSDT',
      balas: 'USDT',
      trdas: 'ZK',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ZKFDUSD',
      balas: 'FDUSD',
      trdas: 'ZK',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ZKTRY',
      balas: 'TRY',
      trdas: 'ZK',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'LISTAUSDT',
      balas: 'USDT',
      trdas: 'LISTA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'LISTABNB',
      balas: 'BNB',
      trdas: 'LISTA',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'LISTAFDUSD',
      balas: 'FDUSD',
      trdas: 'LISTA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'LISTATRY',
      balas: 'TRY',
      trdas: 'LISTA',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ZROBTC',
      balas: 'BTC',
      trdas: 'ZRO',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'ZROUSDT',
      balas: 'USDT',
      trdas: 'ZRO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ZROFDUSD',
      balas: 'FDUSD',
      trdas: 'ZRO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ZROTRY',
      balas: 'TRY',
      trdas: 'ZRO',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'LISTABRL',
      balas: 'BRL',
      trdas: 'LISTA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BAKETRY',
      balas: 'TRY',
      trdas: 'BAKE',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'WIFBRL',
      balas: 'BRL',
      trdas: 'WIF',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ZKUSDC',
      balas: 'USDC',
      trdas: 'ZK',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ZROUSDC',
      balas: 'USDC',
      trdas: 'ZRO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'IOUSDC',
      balas: 'USDC',
      trdas: 'IO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: '1000SATSUSDC',
      balas: 'USDC',
      trdas: '1000SATS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BNXTRY',
      balas: 'TRY',
      trdas: 'BNX',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ETHARS',
      balas: 'ARS',
      trdas: 'ETH',
      scnt: 1,
      pdec: 0,
      sdec: 5,
      min: 2000
    },
    {
      tk: 'GUSDT',
      balas: 'USDT',
      trdas: 'G',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GTRY',
      balas: 'TRY',
      trdas: 'G',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BANANABTC',
      balas: 'BTC',
      trdas: 'BANANA',
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'BANANAUSDT',
      balas: 'USDT',
      trdas: 'BANANA',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'BANANABNB',
      balas: 'BNB',
      trdas: 'BANANA',
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 0.01
    },
    {
      tk: 'BANANAFDUSD',
      balas: 'FDUSD',
      trdas: 'BANANA',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'BANANATRY',
      balas: 'TRY',
      trdas: 'BANANA',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 10
    },
    {
      tk: 'RENDERBTC',
      balas: 'BTC',
      trdas: 'RENDER',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'RENDERUSDT',
      balas: 'USDT',
      trdas: 'RENDER',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'RENDERFDUSD',
      balas: 'FDUSD',
      trdas: 'RENDER',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'RENDERUSDC',
      balas: 'USDC',
      trdas: 'RENDER',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'RENDERTRY',
      balas: 'TRY',
      trdas: 'RENDER',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'RENDEREUR',
      balas: 'EUR',
      trdas: 'RENDER',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'RENDERBRL',
      balas: 'BRL',
      trdas: 'RENDER',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'TONBTC',
      balas: 'BTC',
      trdas: 'TON',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'TONUSDT',
      balas: 'USDT',
      trdas: 'TON',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'TONFDUSD',
      balas: 'FDUSD',
      trdas: 'TON',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'TONTRY',
      balas: 'TRY',
      trdas: 'TON',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'BONKBRL',
      balas: 'BRL',
      trdas: 'BONK',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10
    },
    {
      tk: 'NOTEUR',
      balas: 'EUR',
      trdas: 'NOT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DOGEJPY',
      balas: 'JPY',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 100
    },
    {
      tk: 'MATICJPY',
      balas: 'JPY',
      trdas: 'MATIC',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 100
    },
    {
      tk: 'NEARJPY',
      balas: 'JPY',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 1,
      sdec: 1,
      min: 100
    },
    {
      tk: 'TONUSDC',
      balas: 'USDC',
      trdas: 'TON',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AAVEFDUSD',
      balas: 'FDUSD',
      trdas: 'AAVE',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'DOGSUSDT',
      balas: 'USDT',
      trdas: 'DOGS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: 'DOGSBNB',
      balas: 'BNB',
      trdas: 'DOGS',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'DOGSFDUSD',
      balas: 'FDUSD',
      trdas: 'DOGS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: 'DOGSTRY',
      balas: 'TRY',
      trdas: 'DOGS',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'EUREURI',
      balas: 'EURI',
      trdas: 'EUR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'EURIUSDT',
      balas: 'USDT',
      trdas: 'EURI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DOGSBRL',
      balas: 'BRL',
      trdas: 'DOGS',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'DOGSUSDC',
      balas: 'USDC',
      trdas: 'DOGS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: 'RAREBRL',
      balas: 'BRL',
      trdas: 'RARE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 10
    },
    {
      tk: 'RAREUSDC',
      balas: 'USDC',
      trdas: 'RARE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SLFBTC',
      balas: 'BTC',
      trdas: 'SLF',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SLFTRY',
      balas: 'TRY',
      trdas: 'SLF',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SLFUSDC',
      balas: 'USDC',
      trdas: 'SLF',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SLFUSDT',
      balas: 'USDT',
      trdas: 'SLF',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AAVEUSDC',
      balas: 'USDC',
      trdas: 'AAVE',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'SUNTRY',
      balas: 'TRY',
      trdas: 'SUN',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'STMXTRY',
      balas: 'TRY',
      trdas: 'STMX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'POLBNB',
      balas: 'BNB',
      trdas: 'POL',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'POLBRL',
      balas: 'BRL',
      trdas: 'POL',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'POLBTC',
      balas: 'BTC',
      trdas: 'POL',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'POLETH',
      balas: 'ETH',
      trdas: 'POL',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'POLEUR',
      balas: 'EUR',
      trdas: 'POL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'POLFDUSD',
      balas: 'FDUSD',
      trdas: 'POL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'POLJPY',
      balas: 'JPY',
      trdas: 'POL',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 100
    },
    {
      tk: 'POLTRY',
      balas: 'TRY',
      trdas: 'POL',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'POLUSDC',
      balas: 'USDC',
      trdas: 'POL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'POLUSDT',
      balas: 'USDT',
      trdas: 'POL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'NEIROUSDT',
      balas: 'USDT',
      trdas: 'NEIRO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'TURBOUSDT',
      balas: 'USDT',
      trdas: 'TURBO',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: '1MBABYDOGEUSDT',
      balas: 'USDT',
      trdas: '1MBABYDOGE',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: 'CATIUSDT',
      balas: 'USDT',
      trdas: 'CATI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    },
    {
      tk: 'CATIBNB',
      balas: 'BNB',
      trdas: 'CATI',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'CATIFDUSD',
      balas: 'FDUSD',
      trdas: 'CATI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    },
    {
      tk: 'CATITRY',
      balas: 'TRY',
      trdas: 'CATI',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: '1MBABYDOGEFDUSD',
      balas: 'FDUSD',
      trdas: '1MBABYDOGE',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: '1MBABYDOGETRY',
      balas: 'TRY',
      trdas: '1MBABYDOGE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'CATIBRL',
      balas: 'BRL',
      trdas: 'CATI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BTCEURI',
      balas: 'EURI',
      trdas: 'BTC',
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 5
    },
    {
      tk: 'NEIROFDUSD',
      balas: 'FDUSD',
      trdas: 'NEIRO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'NEIROTRY',
      balas: 'TRY',
      trdas: 'NEIRO',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 10
    },
    {
      tk: 'HMSTRUSDT',
      balas: 'USDT',
      trdas: 'HMSTR',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HMSTRBNB',
      balas: 'BNB',
      trdas: 'HMSTR',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'HMSTRFDUSD',
      balas: 'FDUSD',
      trdas: 'HMSTR',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HMSTRTRY',
      balas: 'TRY',
      trdas: 'HMSTR',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'EIGENBTC',
      balas: 'BTC',
      trdas: 'EIGEN',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'EIGENUSDT',
      balas: 'USDT',
      trdas: 'EIGEN',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'EIGENFDUSD',
      balas: 'FDUSD',
      trdas: 'EIGEN',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'EIGENTRY',
      balas: 'TRY',
      trdas: 'EIGEN',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'NEIROBRL',
      balas: 'BRL',
      trdas: 'NEIRO',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 10
    },
    {
      tk: 'NEIROEUR',
      balas: 'EUR',
      trdas: 'NEIRO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BNSOLSOL',
      balas: 'SOL',
      trdas: 'BNSOL',
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 0.05
    },
    {
      tk: 'SCRUSDT',
      balas: 'USDT',
      trdas: 'SCR',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SUIBRL',
      balas: 'BRL',
      trdas: 'SUI',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'TURBOTRY',
      balas: 'TRY',
      trdas: 'TURBO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'BNSOLUSDT',
      balas: 'USDT',
      trdas: 'BNSOL',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'LUMIAUSDT',
      balas: 'USDT',
      trdas: 'LUMIA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'SCRBTC',
      balas: 'BTC',
      trdas: 'SCR',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SCRFDUSD',
      balas: 'FDUSD',
      trdas: 'SCR',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SCRTRY',
      balas: 'TRY',
      trdas: 'SCR',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'KAIAUSDT',
      balas: 'USDT',
      trdas: 'KAIA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'COWUSDT',
      balas: 'USDT',
      trdas: 'COW',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CETUSUSDT',
      balas: 'USDT',
      trdas: 'CETUS',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PNUTUSDT',
      balas: 'USDT',
      trdas: 'PNUT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    },
    {
      tk: 'ACTUSDT',
      balas: 'USDT',
      trdas: 'ACT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    },
    {
      tk: 'ACTTRY',
      balas: 'TRY',
      trdas: 'ACT',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'COWTRY',
      balas: 'TRY',
      trdas: 'COW',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'CETUSTRY',
      balas: 'TRY',
      trdas: 'CETUS',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'TROYTRY',
      balas: 'TRY',
      trdas: 'TROY',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'PNUTTRY',
      balas: 'TRY',
      trdas: 'PNUT',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ACTFDUSD',
      balas: 'FDUSD',
      trdas: 'ACT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    },
    {
      tk: 'ACTUSDC',
      balas: 'USDC',
      trdas: 'ACT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    },
    {
      tk: 'NEIROUSDC',
      balas: 'USDC',
      trdas: 'NEIRO',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: 'PNUTBTC',
      balas: 'BTC',
      trdas: 'PNUT',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'PNUTFDUSD',
      balas: 'FDUSD',
      trdas: 'PNUT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    },
    {
      tk: 'PNUTUSDC',
      balas: 'USDC',
      trdas: 'PNUT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    },
    {
      tk: 'USUALUSDT',
      balas: 'USDT',
      trdas: 'USUAL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ACTBRL',
      balas: 'BRL',
      trdas: 'ACT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ACTEUR',
      balas: 'EUR',
      trdas: 'ACT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    },
    {
      tk: 'CATIUSDC',
      balas: 'USDC',
      trdas: 'CATI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    },
    {
      tk: 'ETHEURI',
      balas: 'EURI',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 5
    },
    {
      tk: 'LUMIATRY',
      balas: 'TRY',
      trdas: 'LUMIA',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'PNUTBRL',
      balas: 'BRL',
      trdas: 'PNUT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'PNUTEUR',
      balas: 'EUR',
      trdas: 'PNUT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    },
    {
      tk: 'APEFDUSD',
      balas: 'FDUSD',
      trdas: 'APE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'FDUSDUSDC',
      balas: 'USDC',
      trdas: 'FDUSD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HBARUSDC',
      balas: 'USDC',
      trdas: 'HBAR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'OMUSDC',
      balas: 'USDC',
      trdas: 'OM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RAYUSDC',
      balas: 'USDC',
      trdas: 'RAY',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TAOUSDC',
      balas: 'USDC',
      trdas: 'TAO',
      scnt: 1,
      pdec: 1,
      sdec: 4,
      min: 5
    },
    {
      tk: 'TURBOFDUSD',
      balas: 'FDUSD',
      trdas: 'TURBO',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: 'THEBTC',
      balas: 'BTC',
      trdas: 'THE',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'THEBNB',
      balas: 'BNB',
      trdas: 'THE',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'THEFDUSD',
      balas: 'FDUSD',
      trdas: 'THE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'THETRY',
      balas: 'TRY',
      trdas: 'THE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'THEUSDT',
      balas: 'USDT',
      trdas: 'THE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'APEUSDC',
      balas: 'USDC',
      trdas: 'APE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BOMEEUR',
      balas: 'EUR',
      trdas: 'BOME',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: 'EIGENUSDC',
      balas: 'USDC',
      trdas: 'EIGEN',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'HBARFDUSD',
      balas: 'FDUSD',
      trdas: 'HBAR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MEMEUSDC',
      balas: 'USDC',
      trdas: 'MEME',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'TROYUSDC',
      balas: 'USDC',
      trdas: 'TROY',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'WLDEUR',
      balas: 'EUR',
      trdas: 'WLD',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: '1MBABYDOGEUSDC',
      balas: 'USDC',
      trdas: '1MBABYDOGE',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: 'CETUSUSDC',
      balas: 'USDC',
      trdas: 'CETUS',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'COWUSDC',
      balas: 'USDC',
      trdas: 'COW',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DYDXUSDC',
      balas: 'USDC',
      trdas: 'DYDX',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'HMSTRUSDC',
      balas: 'USDC',
      trdas: 'HMSTR',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TURBOUSDC',
      balas: 'USDC',
      trdas: 'TURBO',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ENABRL',
      balas: 'BRL',
      trdas: 'ENA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 10
    },
    {
      tk: 'EOSFDUSD',
      balas: 'FDUSD',
      trdas: 'EOS',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'KAIAUSDC',
      balas: 'USDC',
      trdas: 'KAIA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SANDUSDC',
      balas: 'USDC',
      trdas: 'SAND',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'XLMFDUSD',
      balas: 'FDUSD',
      trdas: 'XLM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CHZUSDC',
      balas: 'USDC',
      trdas: 'CHZ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PYTHUSDC',
      balas: 'USDC',
      trdas: 'PYTH',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RSRUSDC',
      balas: 'USDC',
      trdas: 'RSR',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RSRFDUSD',
      balas: 'FDUSD',
      trdas: 'RSR',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 5
    },
    {
      tk: 'WUSDC',
      balas: 'USDC',
      trdas: 'W',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'XTZUSDC',
      balas: 'USDC',
      trdas: 'XTZ',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ACXUSDT',
      balas: 'USDT',
      trdas: 'ACX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ORCAUSDT',
      balas: 'USDT',
      trdas: 'ORCA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'MOVEBTC',
      balas: 'BTC',
      trdas: 'MOVE',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'MOVEUSDT',
      balas: 'USDT',
      trdas: 'MOVE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MOVEBNB',
      balas: 'BNB',
      trdas: 'MOVE',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'MOVEFDUSD',
      balas: 'FDUSD',
      trdas: 'MOVE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MOVETRY',
      balas: 'TRY',
      trdas: 'MOVE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MEBTC',
      balas: 'BTC',
      trdas: 'ME',
      scnt: 1,
      pdec: 8,
      sdec: 2,
      min: 0.0001
    },
    {
      tk: 'MEUSDT',
      balas: 'USDT',
      trdas: 'ME',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'MEFDUSD',
      balas: 'FDUSD',
      trdas: 'ME',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'METRY',
      balas: 'TRY',
      trdas: 'ME',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 10
    },
    {
      tk: 'ACXUSDC',
      balas: 'USDC',
      trdas: 'ACX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ORCAUSDC',
      balas: 'USDC',
      trdas: 'ORCA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ACXFDUSD',
      balas: 'FDUSD',
      trdas: 'ACX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ORCAFDUSD',
      balas: 'FDUSD',
      trdas: 'ORCA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ACXTRY',
      balas: 'TRY',
      trdas: 'ACX',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'ORCATRY',
      balas: 'TRY',
      trdas: 'ORCA',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: 'KSMTRY',
      balas: 'TRY',
      trdas: 'KSM',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 10
    },
    {
      tk: 'CELOTRY',
      balas: 'TRY',
      trdas: 'CELO',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'HIVEFDUSD',
      balas: 'FDUSD',
      trdas: 'HIVE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HIVEUSDC',
      balas: 'USDC',
      trdas: 'HIVE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'IDEXFDUSD',
      balas: 'FDUSD',
      trdas: 'IDEX',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'IDEXUSDC',
      balas: 'USDC',
      trdas: 'IDEX',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TLMFDUSD',
      balas: 'FDUSD',
      trdas: 'TLM',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TLMUSDC',
      balas: 'USDC',
      trdas: 'TLM',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'VELODROMEUSDT',
      balas: 'USDT',
      trdas: 'VELODROME',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'VANAUSDT',
      balas: 'USDT',
      trdas: 'VANA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'VANABNB',
      balas: 'BNB',
      trdas: 'VANA',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.01
    },
    {
      tk: 'VANAFDUSD',
      balas: 'FDUSD',
      trdas: 'VANA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'VANATRY',
      balas: 'TRY',
      trdas: 'VANA',
      scnt: 1,
      pdec: 1,
      sdec: 2,
      min: 10
    },
    {
      tk: '1000CATUSDT',
      balas: 'USDT',
      trdas: '1000CAT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 1
    },
    {
      tk: '1000CATBNB',
      balas: 'BNB',
      trdas: '1000CAT',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.01
    },
    {
      tk: '1000CATFDUSD',
      balas: 'FDUSD',
      trdas: '1000CAT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 1
    },
    {
      tk: '1000CATTRY',
      balas: 'TRY',
      trdas: '1000CAT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'PENGUUSDT',
      balas: 'USDT',
      trdas: 'PENGU',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: 'PENGUBNB',
      balas: 'BNB',
      trdas: 'PENGU',
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'PENGUFDUSD',
      balas: 'FDUSD',
      trdas: 'PENGU',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: 'PENGUTRY',
      balas: 'TRY',
      trdas: 'PENGU',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 10
    },
    {
      tk: 'USUALBTC',
      balas: 'BTC',
      trdas: 'USUAL',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'USUALFDUSD',
      balas: 'FDUSD',
      trdas: 'USUAL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'USUALTRY',
      balas: 'TRY',
      trdas: 'USUAL',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: '1000CATUSDC',
      balas: 'USDC',
      trdas: '1000CAT',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 1
    },
    {
      tk: 'PENGUUSDC',
      balas: 'USDC',
      trdas: 'PENGU',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BIOUSDT',
      balas: 'USDT',
      trdas: 'BIO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BIOBNB',
      balas: 'BNB',
      trdas: 'BIO',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'BIOFDUSD',
      balas: 'FDUSD',
      trdas: 'BIO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BIOTRY',
      balas: 'TRY',
      trdas: 'BIO',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BIOUSDC',
      balas: 'USDC',
      trdas: 'BIO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'HIVETRY',
      balas: 'TRY',
      trdas: 'HIVE',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'MOVEUSDC',
      balas: 'USDC',
      trdas: 'MOVE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PHATRY',
      balas: 'TRY',
      trdas: 'PHA',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 10
    },
    {
      tk: 'SUSHITRY',
      balas: 'TRY',
      trdas: 'SUSHI',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'DUSDT',
      balas: 'USDT',
      trdas: 'D',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DTRY',
      balas: 'TRY',
      trdas: 'D',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'APTJPY',
      balas: 'JPY',
      trdas: 'APT',
      scnt: 1,
      pdec: 0,
      sdec: 2,
      min: 100
    },
    {
      tk: 'SUIJPY',
      balas: 'JPY',
      trdas: 'SUI',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 100
    },
    {
      tk: 'XLMJPY',
      balas: 'JPY',
      trdas: 'XLM',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 100
    },
    {
      tk: 'PEPEJPY',
      balas: 'JPY',
      trdas: 'PEPE',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 100
    },
    {
      tk: 'PHAUSDC',
      balas: 'USDC',
      trdas: 'PHA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'USDCPLN',
      balas: 'PLN',
      trdas: 'USDC',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 25
    },
    {
      tk: 'STEEMUSDC',
      balas: 'USDC',
      trdas: 'STEEM',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'USUALUSDC',
      balas: 'USDC',
      trdas: 'USUAL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AIXBTUSDT',
      balas: 'USDT',
      trdas: 'AIXBT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AIXBTUSDC',
      balas: 'USDC',
      trdas: 'AIXBT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CGPTUSDT',
      balas: 'USDT',
      trdas: 'CGPT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CGPTUSDC',
      balas: 'USDC',
      trdas: 'CGPT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'COOKIEUSDT',
      balas: 'USDT',
      trdas: 'COOKIE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'COOKIEUSDC',
      balas: 'USDC',
      trdas: 'COOKIE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SBTC',
      balas: 'BTC',
      trdas: 'S',
      scnt: 1,
      pdec: 8,
      sdec: 1,
      min: 0.0001
    },
    {
      tk: 'SBNB',
      balas: 'BNB',
      trdas: 'S',
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'SETH',
      balas: 'ETH',
      trdas: 'S',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.001
    },
    {
      tk: 'SEUR',
      balas: 'EUR',
      trdas: 'S',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SFDUSD',
      balas: 'FDUSD',
      trdas: 'S',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'STRY',
      balas: 'TRY',
      trdas: 'S',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'SUSDC',
      balas: 'USDC',
      trdas: 'S',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SUSDT',
      balas: 'USDT',
      trdas: 'S',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'IOTXJPY',
      balas: 'JPY',
      trdas: 'IOTX',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 100
    },
    {
      tk: 'SEIJPY',
      balas: 'JPY',
      trdas: 'SEI',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 100
    },
    {
      tk: 'SOLVUSDT',
      balas: 'USDT',
      trdas: 'SOLV',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SOLVBNB',
      balas: 'BNB',
      trdas: 'SOLV',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 0.01
    },
    {
      tk: 'SOLVFDUSD',
      balas: 'FDUSD',
      trdas: 'SOLV',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SOLVTRY',
      balas: 'TRY',
      trdas: 'SOLV',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 10
    },
    {
      tk: 'TRUMPUSDT',
      balas: 'USDT',
      trdas: 'TRUMP',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 1
    },
    {
      tk: 'TRUMPUSDC',
      balas: 'USDC',
      trdas: 'TRUMP',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 1
    },
    {
      tk: 'AIXBTTRY',
      balas: 'TRY',
      trdas: 'AIXBT',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 10
    },
    {
      tk: 'TRUMPTRY',
      balas: 'TRY',
      trdas: 'TRUMP',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 10
    },
    {
      tk: 'ANIMEUSDT',
      balas: 'USDT',
      trdas: 'ANIME',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ANIMEUSDC',
      balas: 'USDC',
      trdas: 'ANIME',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ANIMEBNB',
      balas: 'BNB',
      trdas: 'ANIME',
      scnt: 1,
      pdec: 7,
      sdec: 1,
      min: 0.01
    },
    {
      tk: 'ANIMEFDUSD',
      balas: 'FDUSD',
      trdas: 'ANIME',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ANIMETRY',
      balas: 'TRY',
      trdas: 'ANIME',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 10
    },
    {
      tk: 'BERABTC',
      balas: 'BTC',
      trdas: 'BERA',
      scnt: 1,
      pdec: 8,
      sdec: 3,
      min: 0.0001
    },
    {
      tk: 'BERAUSDT',
      balas: 'USDT',
      trdas: 'BERA',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 5
    },
    {
      tk: 'BERAUSDC',
      balas: 'USDC',
      trdas: 'BERA',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 5
    },
    {
      tk: 'BERAFDUSD',
      balas: 'FDUSD',
      trdas: 'BERA',
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 5
    },
    {
      tk: 'BERABNB',
      balas: 'BNB',
      trdas: 'BERA',
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 0.01
    },
    {
      tk: 'BERATRY',
      balas: 'TRY',
      trdas: 'BERA',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 10
    },
    {
      tk: '1000CHEEMSUSDT',
      balas: 'USDT',
      trdas: '1000CHEEMS',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: '1000CHEEMSUSDC',
      balas: 'USDC',
      trdas: '1000CHEEMS',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: 'TSTUSDT',
      balas: 'USDT',
      trdas: 'TST',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    },
    {
      tk: 'TSTUSDC',
      balas: 'USDC',
      trdas: 'TST',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 1
    }
  ],
  futures: [
    {
      tk: 'BTCUSDT',
      balas: 'USDT',
      trdas: 'BTC',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 100
    },
    {
      tk: 'ETHUSDT',
      balas: 'USDT',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 20
    },
    {
      tk: 'BCHUSDT',
      balas: 'USDT',
      trdas: 'BCH',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 20
    },
    {
      tk: 'XRPUSDT',
      balas: 'USDT',
      trdas: 'XRP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'EOSUSDT',
      balas: 'USDT',
      trdas: 'EOS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'LTCUSDT',
      balas: 'USDT',
      trdas: 'LTC',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 20
    },
    {
      tk: 'TRXUSDT',
      balas: 'USDT',
      trdas: 'TRX',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ETCUSDT',
      balas: 'USDT',
      trdas: 'ETC',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 20
    },
    {
      tk: 'LINKUSDT',
      balas: 'USDT',
      trdas: 'LINK',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 20
    },
    {
      tk: 'XLMUSDT',
      balas: 'USDT',
      trdas: 'XLM',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ADAUSDT',
      balas: 'USDT',
      trdas: 'ADA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'XMRUSDT',
      balas: 'USDT',
      trdas: 'XMR',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'DASHUSDT',
      balas: 'USDT',
      trdas: 'DASH',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'ZECUSDT',
      balas: 'USDT',
      trdas: 'ZEC',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'XTZUSDT',
      balas: 'USDT',
      trdas: 'XTZ',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BNBUSDT',
      balas: 'USDT',
      trdas: 'BNB',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ATOMUSDT',
      balas: 'USDT',
      trdas: 'ATOM',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ONTUSDT',
      balas: 'USDT',
      trdas: 'ONT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'IOTAUSDT',
      balas: 'USDT',
      trdas: 'IOTA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BATUSDT',
      balas: 'USDT',
      trdas: 'BAT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'VETUSDT',
      balas: 'USDT',
      trdas: 'VET',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NEOUSDT',
      balas: 'USDT',
      trdas: 'NEO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'QTUMUSDT',
      balas: 'USDT',
      trdas: 'QTUM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'IOSTUSDT',
      balas: 'USDT',
      trdas: 'IOST',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'THETAUSDT',
      balas: 'USDT',
      trdas: 'THETA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ALGOUSDT',
      balas: 'USDT',
      trdas: 'ALGO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ZILUSDT',
      balas: 'USDT',
      trdas: 'ZIL',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'KNCUSDT',
      balas: 'USDT',
      trdas: 'KNC',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ZRXUSDT',
      balas: 'USDT',
      trdas: 'ZRX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'COMPUSDT',
      balas: 'USDT',
      trdas: 'COMP',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'OMGUSDT',
      balas: 'USDT',
      trdas: 'OMG',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DOGEUSDT',
      balas: 'USDT',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SXPUSDT',
      balas: 'USDT',
      trdas: 'SXP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'KAVAUSDT',
      balas: 'USDT',
      trdas: 'KAVA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BANDUSDT',
      balas: 'USDT',
      trdas: 'BAND',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RLCUSDT',
      balas: 'USDT',
      trdas: 'RLC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'WAVESUSDT',
      balas: 'USDT',
      trdas: 'WAVES',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MKRUSDT',
      balas: 'USDT',
      trdas: 'MKR',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'SNXUSDT',
      balas: 'USDT',
      trdas: 'SNX',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DOTUSDT',
      balas: 'USDT',
      trdas: 'DOT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DEFIUSDT',
      balas: 'USDT',
      trdas: 'DEFI',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'YFIUSDT',
      balas: 'USDT',
      trdas: 'YFI',
      scnt: 1,
      pdec: 0,
      sdec: 3,
      min: 5
    },
    {
      tk: 'BALUSDT',
      balas: 'USDT',
      trdas: 'BAL',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CRVUSDT',
      balas: 'USDT',
      trdas: 'CRV',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TRBUSDT',
      balas: 'USDT',
      trdas: 'TRB',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RUNEUSDT',
      balas: 'USDT',
      trdas: 'RUNE',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SUSHIUSDT',
      balas: 'USDT',
      trdas: 'SUSHI',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'EGLDUSDT',
      balas: 'USDT',
      trdas: 'EGLD',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SOLUSDT',
      balas: 'USDT',
      trdas: 'SOL',
      scnt: 1,
      pdec: 2,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ICXUSDT',
      balas: 'USDT',
      trdas: 'ICX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'STORJUSDT',
      balas: 'USDT',
      trdas: 'STORJ',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BLZUSDT',
      balas: 'USDT',
      trdas: 'BLZ',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'UNIUSDT',
      balas: 'USDT',
      trdas: 'UNI',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AVAXUSDT',
      balas: 'USDT',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FTMUSDT',
      balas: 'USDT',
      trdas: 'FTM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ENJUSDT',
      balas: 'USDT',
      trdas: 'ENJ',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FLMUSDT',
      balas: 'USDT',
      trdas: 'FLM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RENUSDT',
      balas: 'USDT',
      trdas: 'REN',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'KSMUSDT',
      balas: 'USDT',
      trdas: 'KSM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'NEARUSDT',
      balas: 'USDT',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AAVEUSDT',
      balas: 'USDT',
      trdas: 'AAVE',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 5
    },
    {
      tk: 'FILUSDT',
      balas: 'USDT',
      trdas: 'FIL',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RSRUSDT',
      balas: 'USDT',
      trdas: 'RSR',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LRCUSDT',
      balas: 'USDT',
      trdas: 'LRC',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'OCEANUSDT',
      balas: 'USDT',
      trdas: 'OCEAN',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CVCUSDT',
      balas: 'USDT',
      trdas: 'CVC',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BELUSDT',
      balas: 'USDT',
      trdas: 'BEL',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CTKUSDT',
      balas: 'USDT',
      trdas: 'CTK',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AXSUSDT',
      balas: 'USDT',
      trdas: 'AXS',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ALPHAUSDT',
      balas: 'USDT',
      trdas: 'ALPHA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ZENUSDT',
      balas: 'USDT',
      trdas: 'ZEN',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SKLUSDT',
      balas: 'USDT',
      trdas: 'SKL',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GRTUSDT',
      balas: 'USDT',
      trdas: 'GRT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: '1INCHUSDT',
      balas: 'USDT',
      trdas: '1INCH',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CHZUSDT',
      balas: 'USDT',
      trdas: 'CHZ',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SANDUSDT',
      balas: 'USDT',
      trdas: 'SAND',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ANKRUSDT',
      balas: 'USDT',
      trdas: 'ANKR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LITUSDT',
      balas: 'USDT',
      trdas: 'LIT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'UNFIUSDT',
      balas: 'USDT',
      trdas: 'UNFI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'REEFUSDT',
      balas: 'USDT',
      trdas: 'REEF',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RVNUSDT',
      balas: 'USDT',
      trdas: 'RVN',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SFPUSDT',
      balas: 'USDT',
      trdas: 'SFP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'XEMUSDT',
      balas: 'USDT',
      trdas: 'XEM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BTCSTUSDT',
      balas: 'USDT',
      trdas: 'BTCST',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'COTIUSDT',
      balas: 'USDT',
      trdas: 'COTI',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CHRUSDT',
      balas: 'USDT',
      trdas: 'CHR',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MANAUSDT',
      balas: 'USDT',
      trdas: 'MANA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ALICEUSDT',
      balas: 'USDT',
      trdas: 'ALICE',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'HBARUSDT',
      balas: 'USDT',
      trdas: 'HBAR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ONEUSDT',
      balas: 'USDT',
      trdas: 'ONE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LINAUSDT',
      balas: 'USDT',
      trdas: 'LINA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'STMXUSDT',
      balas: 'USDT',
      trdas: 'STMX',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DENTUSDT',
      balas: 'USDT',
      trdas: 'DENT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CELRUSDT',
      balas: 'USDT',
      trdas: 'CELR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HOTUSDT',
      balas: 'USDT',
      trdas: 'HOT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MTLUSDT',
      balas: 'USDT',
      trdas: 'MTL',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'OGNUSDT',
      balas: 'USDT',
      trdas: 'OGN',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NKNUSDT',
      balas: 'USDT',
      trdas: 'NKN',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SCUSDT',
      balas: 'USDT',
      trdas: 'SC',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DGBUSDT',
      balas: 'USDT',
      trdas: 'DGB',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: '1000SHIBUSDT',
      balas: 'USDT',
      trdas: '1000SHIB',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BAKEUSDT',
      balas: 'USDT',
      trdas: 'BAKE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GTCUSDT',
      balas: 'USDT',
      trdas: 'GTC',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BTCDOMUSDT',
      balas: 'USDT',
      trdas: 'BTCDOM',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'IOTXUSDT',
      balas: 'USDT',
      trdas: 'IOTX',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RAYUSDT',
      balas: 'USDT',
      trdas: 'RAY',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'C98USDT',
      balas: 'USDT',
      trdas: 'C98',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MASKUSDT',
      balas: 'USDT',
      trdas: 'MASK',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ATAUSDT',
      balas: 'USDT',
      trdas: 'ATA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DYDXUSDT',
      balas: 'USDT',
      trdas: 'DYDX',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: '1000XECUSDT',
      balas: 'USDT',
      trdas: '1000XEC',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GALAUSDT',
      balas: 'USDT',
      trdas: 'GALA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CELOUSDT',
      balas: 'USDT',
      trdas: 'CELO',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ARUSDT',
      balas: 'USDT',
      trdas: 'AR',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'KLAYUSDT',
      balas: 'USDT',
      trdas: 'KLAY',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ARPAUSDT',
      balas: 'USDT',
      trdas: 'ARPA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CTSIUSDT',
      balas: 'USDT',
      trdas: 'CTSI',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LPTUSDT',
      balas: 'USDT',
      trdas: 'LPT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ENSUSDT',
      balas: 'USDT',
      trdas: 'ENS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'PEOPLEUSDT',
      balas: 'USDT',
      trdas: 'PEOPLE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ROSEUSDT',
      balas: 'USDT',
      trdas: 'ROSE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DUSKUSDT',
      balas: 'USDT',
      trdas: 'DUSK',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FLOWUSDT',
      balas: 'USDT',
      trdas: 'FLOW',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'IMXUSDT',
      balas: 'USDT',
      trdas: 'IMX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'API3USDT',
      balas: 'USDT',
      trdas: 'API3',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'GMTUSDT',
      balas: 'USDT',
      trdas: 'GMT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'APEUSDT',
      balas: 'USDT',
      trdas: 'APE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'WOOUSDT',
      balas: 'USDT',
      trdas: 'WOO',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FTTUSDT',
      balas: 'USDT',
      trdas: 'FTT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'JASMYUSDT',
      balas: 'USDT',
      trdas: 'JASMY',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DARUSDT',
      balas: 'USDT',
      trdas: 'DAR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'OPUSDT',
      balas: 'USDT',
      trdas: 'OP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'INJUSDT',
      balas: 'USDT',
      trdas: 'INJ',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'STGUSDT',
      balas: 'USDT',
      trdas: 'STG',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SPELLUSDT',
      balas: 'USDT',
      trdas: 'SPELL',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: '1000LUNCUSDT',
      balas: 'USDT',
      trdas: '1000LUNC',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LUNA2USDT',
      balas: 'USDT',
      trdas: 'LUNA2',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LDOUSDT',
      balas: 'USDT',
      trdas: 'LDO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CVXUSDT',
      balas: 'USDT',
      trdas: 'CVX',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ICPUSDT',
      balas: 'USDT',
      trdas: 'ICP',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 5
    },
    {
      tk: 'APTUSDT',
      balas: 'USDT',
      trdas: 'APT',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'QNTUSDT',
      balas: 'USDT',
      trdas: 'QNT',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 5
    },
    {
      tk: 'FETUSDT',
      balas: 'USDT',
      trdas: 'FET',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FXSUSDT',
      balas: 'USDT',
      trdas: 'FXS',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'HOOKUSDT',
      balas: 'USDT',
      trdas: 'HOOK',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MAGICUSDT',
      balas: 'USDT',
      trdas: 'MAGIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TUSDT',
      balas: 'USDT',
      trdas: 'T',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HIGHUSDT',
      balas: 'USDT',
      trdas: 'HIGH',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MINAUSDT',
      balas: 'USDT',
      trdas: 'MINA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ASTRUSDT',
      balas: 'USDT',
      trdas: 'ASTR',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AGIXUSDT',
      balas: 'USDT',
      trdas: 'AGIX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PHBUSDT',
      balas: 'USDT',
      trdas: 'PHB',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GMXUSDT',
      balas: 'USDT',
      trdas: 'GMX',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'CFXUSDT',
      balas: 'USDT',
      trdas: 'CFX',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'STXUSDT',
      balas: 'USDT',
      trdas: 'STX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BNXUSDT',
      balas: 'USDT',
      trdas: 'BNX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ACHUSDT',
      balas: 'USDT',
      trdas: 'ACH',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SSVUSDT',
      balas: 'USDT',
      trdas: 'SSV',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'CKBUSDT',
      balas: 'USDT',
      trdas: 'CKB',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PERPUSDT',
      balas: 'USDT',
      trdas: 'PERP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TRUUSDT',
      balas: 'USDT',
      trdas: 'TRU',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LQTYUSDT',
      balas: 'USDT',
      trdas: 'LQTY',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'USDCUSDT',
      balas: 'USDT',
      trdas: 'USDC',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'IDUSDT',
      balas: 'USDT',
      trdas: 'ID',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ARBUSDT',
      balas: 'USDT',
      trdas: 'ARB',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'JOEUSDT',
      balas: 'USDT',
      trdas: 'JOE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TLMUSDT',
      balas: 'USDT',
      trdas: 'TLM',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AMBUSDT',
      balas: 'USDT',
      trdas: 'AMB',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LEVERUSDT',
      balas: 'USDT',
      trdas: 'LEVER',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RDNTUSDT',
      balas: 'USDT',
      trdas: 'RDNT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HFTUSDT',
      balas: 'USDT',
      trdas: 'HFT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'XVSUSDT',
      balas: 'USDT',
      trdas: 'XVS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ETHBTC',
      balas: 'BTC',
      trdas: 'ETH',
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 0.001
    },
    {
      tk: 'BLURUSDT',
      balas: 'USDT',
      trdas: 'BLUR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'EDUUSDT',
      balas: 'USDT',
      trdas: 'EDU',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'IDEXUSDT',
      balas: 'USDT',
      trdas: 'IDEX',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SUIUSDT',
      balas: 'USDT',
      trdas: 'SUI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: '1000PEPEUSDT',
      balas: 'USDT',
      trdas: '1000PEPE',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: '1000FLOKIUSDT',
      balas: 'USDT',
      trdas: '1000FLOKI',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'UMAUSDT',
      balas: 'USDT',
      trdas: 'UMA',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RADUSDT',
      balas: 'USDT',
      trdas: 'RAD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'KEYUSDT',
      balas: 'USDT',
      trdas: 'KEY',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: 'COMBOUSDT',
      balas: 'USDT',
      trdas: 'COMBO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'NMRUSDT',
      balas: 'USDT',
      trdas: 'NMR',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MAVUSDT',
      balas: 'USDT',
      trdas: 'MAV',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MDTUSDT',
      balas: 'USDT',
      trdas: 'MDT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'XVGUSDT',
      balas: 'USDT',
      trdas: 'XVG',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: 'WLDUSDT',
      balas: 'USDT',
      trdas: 'WLD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PENDLEUSDT',
      balas: 'USDT',
      trdas: 'PENDLE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ARKMUSDT',
      balas: 'USDT',
      trdas: 'ARKM',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AGLDUSDT',
      balas: 'USDT',
      trdas: 'AGLD',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'YGGUSDT',
      balas: 'USDT',
      trdas: 'YGG',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DODOXUSDT',
      balas: 'USDT',
      trdas: 'DODOX',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BNTUSDT',
      balas: 'USDT',
      trdas: 'BNT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'OXTUSDT',
      balas: 'USDT',
      trdas: 'OXT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SEIUSDT',
      balas: 'USDT',
      trdas: 'SEI',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CYBERUSDT',
      balas: 'USDT',
      trdas: 'CYBER',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'HIFIUSDT',
      balas: 'USDT',
      trdas: 'HIFI',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ARKUSDT',
      balas: 'USDT',
      trdas: 'ARK',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GLMRUSDT',
      balas: 'USDT',
      trdas: 'GLMR',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BICOUSDT',
      balas: 'USDT',
      trdas: 'BICO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'STRAXUSDT',
      balas: 'USDT',
      trdas: 'STRAX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LOOMUSDT',
      balas: 'USDT',
      trdas: 'LOOM',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BIGTIMEUSDT',
      balas: 'USDT',
      trdas: 'BIGTIME',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BONDUSDT',
      balas: 'USDT',
      trdas: 'BOND',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ORBSUSDT',
      balas: 'USDT',
      trdas: 'ORBS',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'STPTUSDT',
      balas: 'USDT',
      trdas: 'STPT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'WAXPUSDT',
      balas: 'USDT',
      trdas: 'WAXP',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BSVUSDT',
      balas: 'USDT',
      trdas: 'BSV',
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RIFUSDT',
      balas: 'USDT',
      trdas: 'RIF',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'POLYXUSDT',
      balas: 'USDT',
      trdas: 'POLYX',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GASUSDT',
      balas: 'USDT',
      trdas: 'GAS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'POWRUSDT',
      balas: 'USDT',
      trdas: 'POWR',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SLPUSDT',
      balas: 'USDT',
      trdas: 'SLP',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TIAUSDT',
      balas: 'USDT',
      trdas: 'TIA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SNTUSDT',
      balas: 'USDT',
      trdas: 'SNT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CAKEUSDT',
      balas: 'USDT',
      trdas: 'CAKE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MEMEUSDT',
      balas: 'USDT',
      trdas: 'MEME',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TWTUSDT',
      balas: 'USDT',
      trdas: 'TWT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TOKENUSDT',
      balas: 'USDT',
      trdas: 'TOKEN',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ORDIUSDT',
      balas: 'USDT',
      trdas: 'ORDI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'STEEMUSDT',
      balas: 'USDT',
      trdas: 'STEEM',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BADGERUSDT',
      balas: 'USDT',
      trdas: 'BADGER',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ILVUSDT',
      balas: 'USDT',
      trdas: 'ILV',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'NTRNUSDT',
      balas: 'USDT',
      trdas: 'NTRN',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'KASUSDT',
      balas: 'USDT',
      trdas: 'KAS',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BEAMXUSDT',
      balas: 'USDT',
      trdas: 'BEAMX',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: '1000BONKUSDT',
      balas: 'USDT',
      trdas: '1000BONK',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PYTHUSDT',
      balas: 'USDT',
      trdas: 'PYTH',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SUPERUSDT',
      balas: 'USDT',
      trdas: 'SUPER',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'USTCUSDT',
      balas: 'USDT',
      trdas: 'USTC',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ONGUSDT',
      balas: 'USDT',
      trdas: 'ONG',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ETHWUSDT',
      balas: 'USDT',
      trdas: 'ETHW',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'JTOUSDT',
      balas: 'USDT',
      trdas: 'JTO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: '1000SATSUSDT',
      balas: 'USDT',
      trdas: '1000SATS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AUCTIONUSDT',
      balas: 'USDT',
      trdas: 'AUCTION',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: '1000RATSUSDT',
      balas: 'USDT',
      trdas: '1000RATS',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ACEUSDT',
      balas: 'USDT',
      trdas: 'ACE',
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 5
    },
    {
      tk: 'MOVRUSDT',
      balas: 'USDT',
      trdas: 'MOVR',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'NFPUSDT',
      balas: 'USDT',
      trdas: 'NFP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BTCUSDC',
      balas: 'USDC',
      trdas: 'BTC',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 100
    },
    {
      tk: 'ETHUSDC',
      balas: 'USDC',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 20
    },
    {
      tk: 'BNBUSDC',
      balas: 'USDC',
      trdas: 'BNB',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'SOLUSDC',
      balas: 'USDC',
      trdas: 'SOL',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'XRPUSDC',
      balas: 'USDC',
      trdas: 'XRP',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AIUSDT',
      balas: 'USDT',
      trdas: 'AI',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'XAIUSDT',
      balas: 'USDT',
      trdas: 'XAI',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DOGEUSDC',
      balas: 'USDC',
      trdas: 'DOGE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'WIFUSDT',
      balas: 'USDT',
      trdas: 'WIF',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MANTAUSDT',
      balas: 'USDT',
      trdas: 'MANTA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ONDOUSDT',
      balas: 'USDT',
      trdas: 'ONDO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'LSKUSDT',
      balas: 'USDT',
      trdas: 'LSK',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ALTUSDT',
      balas: 'USDT',
      trdas: 'ALT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'JUPUSDT',
      balas: 'USDT',
      trdas: 'JUP',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ZETAUSDT',
      balas: 'USDT',
      trdas: 'ZETA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RONINUSDT',
      balas: 'USDT',
      trdas: 'RONIN',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DYMUSDT',
      balas: 'USDT',
      trdas: 'DYM',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SUIUSDC',
      balas: 'USDC',
      trdas: 'SUI',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'OMUSDT',
      balas: 'USDT',
      trdas: 'OM',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'LINKUSDC',
      balas: 'USDC',
      trdas: 'LINK',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'PIXELUSDT',
      balas: 'USDT',
      trdas: 'PIXEL',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'STRKUSDT',
      balas: 'USDT',
      trdas: 'STRK',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MAVIAUSDT',
      balas: 'USDT',
      trdas: 'MAVIA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ORDIUSDC',
      balas: 'USDC',
      trdas: 'ORDI',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'GLMUSDT',
      balas: 'USDT',
      trdas: 'GLM',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PORTALUSDT',
      balas: 'USDT',
      trdas: 'PORTAL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TONUSDT',
      balas: 'USDT',
      trdas: 'TON',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AXLUSDT',
      balas: 'USDT',
      trdas: 'AXL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MYROUSDT',
      balas: 'USDT',
      trdas: 'MYRO',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: '1000PEPEUSDC',
      balas: 'USDC',
      trdas: '1000PEPE',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: 'METISUSDT',
      balas: 'USDT',
      trdas: 'METIS',
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 5
    },
    {
      tk: 'AEVOUSDT',
      balas: 'USDT',
      trdas: 'AEVO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'WLDUSDC',
      balas: 'USDC',
      trdas: 'WLD',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'VANRYUSDT',
      balas: 'USDT',
      trdas: 'VANRY',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BOMEUSDT',
      balas: 'USDT',
      trdas: 'BOME',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ETHFIUSDT',
      balas: 'USDT',
      trdas: 'ETHFI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AVAXUSDC',
      balas: 'USDC',
      trdas: 'AVAX',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: '1000SHIBUSDC',
      balas: 'USDC',
      trdas: '1000SHIB',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ENAUSDT',
      balas: 'USDT',
      trdas: 'ENA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'WUSDT',
      balas: 'USDT',
      trdas: 'W',
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 5
    },
    {
      tk: 'WIFUSDC',
      balas: 'USDC',
      trdas: 'WIF',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BCHUSDC',
      balas: 'USDC',
      trdas: 'BCH',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'TNSRUSDT',
      balas: 'USDT',
      trdas: 'TNSR',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SAGAUSDT',
      balas: 'USDT',
      trdas: 'SAGA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'LTCUSDC',
      balas: 'USDC',
      trdas: 'LTC',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'NEARUSDC',
      balas: 'USDC',
      trdas: 'NEAR',
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TAOUSDT',
      balas: 'USDT',
      trdas: 'TAO',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'OMNIUSDT',
      balas: 'USDT',
      trdas: 'OMNI',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'ARBUSDC',
      balas: 'USDC',
      trdas: 'ARB',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'NEOUSDC',
      balas: 'USDC',
      trdas: 'NEO',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'FILUSDC',
      balas: 'USDC',
      trdas: 'FIL',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TIAUSDC',
      balas: 'USDC',
      trdas: 'TIA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BOMEUSDC',
      balas: 'USDC',
      trdas: 'BOME',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'REZUSDT',
      balas: 'USDT',
      trdas: 'REZ',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ENAUSDC',
      balas: 'USDC',
      trdas: 'ENA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ETHFIUSDC',
      balas: 'USDC',
      trdas: 'ETHFI',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: '1000BONKUSDC',
      balas: 'USDC',
      trdas: '1000BONK',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BBUSDT',
      balas: 'USDT',
      trdas: 'BB',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NOTUSDT',
      balas: 'USDT',
      trdas: 'NOT',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TURBOUSDT',
      balas: 'USDT',
      trdas: 'TURBO',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: 'IOUSDT',
      balas: 'USDT',
      trdas: 'IO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ZKUSDT',
      balas: 'USDT',
      trdas: 'ZK',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MEWUSDT',
      balas: 'USDT',
      trdas: 'MEW',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LISTAUSDT',
      balas: 'USDT',
      trdas: 'LISTA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ZROUSDT',
      balas: 'USDT',
      trdas: 'ZRO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CRVUSDC',
      balas: 'USDC',
      trdas: 'CRV',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RENDERUSDT',
      balas: 'USDT',
      trdas: 'RENDER',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'BANANAUSDT',
      balas: 'USDT',
      trdas: 'BANANA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'RAREUSDT',
      balas: 'USDT',
      trdas: 'RARE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GUSDT',
      balas: 'USDT',
      trdas: 'G',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SYNUSDT',
      balas: 'USDT',
      trdas: 'SYN',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SYSUSDT',
      balas: 'USDT',
      trdas: 'SYS',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'VOXELUSDT',
      balas: 'USDT',
      trdas: 'VOXEL',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BRETTUSDT',
      balas: 'USDT',
      trdas: 'BRETT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ALPACAUSDT',
      balas: 'USDT',
      trdas: 'ALPACA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'POPCATUSDT',
      balas: 'USDT',
      trdas: 'POPCAT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SUNUSDT',
      balas: 'USDT',
      trdas: 'SUN',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'VIDTUSDT',
      balas: 'USDT',
      trdas: 'VIDT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NULSUSDT',
      balas: 'USDT',
      trdas: 'NULS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DOGSUSDT',
      balas: 'USDT',
      trdas: 'DOGS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MBOXUSDT',
      balas: 'USDT',
      trdas: 'MBOX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CHESSUSDT',
      balas: 'USDT',
      trdas: 'CHESS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FLUXUSDT',
      balas: 'USDT',
      trdas: 'FLUX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BSWUSDT',
      balas: 'USDT',
      trdas: 'BSW',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'QUICKUSDT',
      balas: 'USDT',
      trdas: 'QUICK',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NEIROETHUSDT',
      balas: 'USDT',
      trdas: 'NEIROETH',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RPLUSDT',
      balas: 'USDT',
      trdas: 'RPL',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AERGOUSDT',
      balas: 'USDT',
      trdas: 'AERGO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'POLUSDT',
      balas: 'USDT',
      trdas: 'POL',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'UXLINKUSDT',
      balas: 'USDT',
      trdas: 'UXLINK',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: '1MBABYDOGEUSDT',
      balas: 'USDT',
      trdas: '1MBABYDOGE',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: 'NEIROUSDT',
      balas: 'USDT',
      trdas: 'NEIRO',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: 'KDAUSDT',
      balas: 'USDT',
      trdas: 'KDA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FIDAUSDT',
      balas: 'USDT',
      trdas: 'FIDA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FIOUSDT',
      balas: 'USDT',
      trdas: 'FIO',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CATIUSDT',
      balas: 'USDT',
      trdas: 'CATI',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GHSTUSDT',
      balas: 'USDT',
      trdas: 'GHST',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LOKAUSDT',
      balas: 'USDT',
      trdas: 'LOKA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HMSTRUSDT',
      balas: 'USDT',
      trdas: 'HMSTR',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BTCUSDT_250328',
      balas: 'USDT',
      trdas: 'BTC',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'ETHUSDT_250328',
      balas: 'USDT',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'REIUSDT',
      balas: 'USDT',
      trdas: 'REI',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'COSUSDT',
      balas: 'USDT',
      trdas: 'COS',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'EIGENUSDT',
      balas: 'USDT',
      trdas: 'EIGEN',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DIAUSDT',
      balas: 'USDT',
      trdas: 'DIA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: '1000CATUSDT',
      balas: 'USDT',
      trdas: '1000CAT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SCRUSDT',
      balas: 'USDT',
      trdas: 'SCR',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GOATUSDT',
      balas: 'USDT',
      trdas: 'GOAT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MOODENGUSDT',
      balas: 'USDT',
      trdas: 'MOODENG',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SAFEUSDT',
      balas: 'USDT',
      trdas: 'SAFE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SANTOSUSDT',
      balas: 'USDT',
      trdas: 'SANTOS',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TROYUSDT',
      balas: 'USDT',
      trdas: 'TROY',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PONKEUSDT',
      balas: 'USDT',
      trdas: 'PONKE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'COWUSDT',
      balas: 'USDT',
      trdas: 'COW',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CETUSUSDT',
      balas: 'USDT',
      trdas: 'CETUS',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: '1000000MOGUSDT',
      balas: 'USDT',
      trdas: '1000000MOG',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'GRASSUSDT',
      balas: 'USDT',
      trdas: 'GRASS',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DRIFTUSDT',
      balas: 'USDT',
      trdas: 'DRIFT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SWELLUSDT',
      balas: 'USDT',
      trdas: 'SWELL',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ACTUSDT',
      balas: 'USDT',
      trdas: 'ACT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PNUTUSDT',
      balas: 'USDT',
      trdas: 'PNUT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HIPPOUSDT',
      balas: 'USDT',
      trdas: 'HIPPO',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: '1000XUSDT',
      balas: 'USDT',
      trdas: '1000X',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DEGENUSDT',
      balas: 'USDT',
      trdas: 'DEGEN',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BANUSDT',
      balas: 'USDT',
      trdas: 'BAN',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AKTUSDT',
      balas: 'USDT',
      trdas: 'AKT',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SLERFUSDT',
      balas: 'USDT',
      trdas: 'SLERF',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SCRTUSDT',
      balas: 'USDT',
      trdas: 'SCRT',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: '1000CHEEMSUSDT',
      balas: 'USDT',
      trdas: '1000CHEEMS',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: '1000WHYUSDT',
      balas: 'USDT',
      trdas: '1000WHY',
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 5
    },
    {
      tk: 'THEUSDT',
      balas: 'USDT',
      trdas: 'THE',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MORPHOUSDT',
      balas: 'USDT',
      trdas: 'MORPHO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'CHILLGUYUSDT',
      balas: 'USDT',
      trdas: 'CHILLGUY',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'KAIAUSDT',
      balas: 'USDT',
      trdas: 'KAIA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AEROUSDT',
      balas: 'USDT',
      trdas: 'AERO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ACXUSDT',
      balas: 'USDT',
      trdas: 'ACX',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ORCAUSDT',
      balas: 'USDT',
      trdas: 'ORCA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'MOVEUSDT',
      balas: 'USDT',
      trdas: 'MOVE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'RAYSOLUSDT',
      balas: 'USDT',
      trdas: 'RAYSOL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'KOMAUSDT',
      balas: 'USDT',
      trdas: 'KOMA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'VIRTUALUSDT',
      balas: 'USDT',
      trdas: 'VIRTUAL',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SPXUSDT',
      balas: 'USDT',
      trdas: 'SPX',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MEUSDT',
      balas: 'USDT',
      trdas: 'ME',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'AVAUSDT',
      balas: 'USDT',
      trdas: 'AVA',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DEGOUSDT',
      balas: 'USDT',
      trdas: 'DEGO',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'VELODROMEUSDT',
      balas: 'USDT',
      trdas: 'VELODROME',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'MOCAUSDT',
      balas: 'USDT',
      trdas: 'MOCA',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'VANAUSDT',
      balas: 'USDT',
      trdas: 'VANA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'PENGUUSDT',
      balas: 'USDT',
      trdas: 'PENGU',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'LUMIAUSDT',
      balas: 'USDT',
      trdas: 'LUMIA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'USUALUSDT',
      balas: 'USDT',
      trdas: 'USUAL',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AIXBTUSDT',
      balas: 'USDT',
      trdas: 'AIXBT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'FARTCOINUSDT',
      balas: 'USDT',
      trdas: 'FARTCOIN',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'KMNOUSDT',
      balas: 'USDT',
      trdas: 'KMNO',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'CGPTUSDT',
      balas: 'USDT',
      trdas: 'CGPT',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'HIVEUSDT',
      balas: 'USDT',
      trdas: 'HIVE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DEXEUSDT',
      balas: 'USDT',
      trdas: 'DEXE',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BTCUSDT_250627',
      balas: 'USDT',
      trdas: 'BTC',
      scnt: 1,
      pdec: 1,
      sdec: 3,
      min: 5
    },
    {
      tk: 'ETHUSDT_250627',
      balas: 'USDT',
      trdas: 'ETH',
      scnt: 1,
      pdec: 2,
      sdec: 3,
      min: 5
    },
    {
      tk: 'PHAUSDT',
      balas: 'USDT',
      trdas: 'PHA',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'DFUSDT',
      balas: 'USDT',
      trdas: 'DF',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'GRIFFAINUSDT',
      balas: 'USDT',
      trdas: 'GRIFFAIN',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AI16ZUSDT',
      balas: 'USDT',
      trdas: 'AI16Z',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'ZEREBROUSDT',
      balas: 'USDT',
      trdas: 'ZEREBRO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'BIOUSDT',
      balas: 'USDT',
      trdas: 'BIO',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'COOKIEUSDT',
      balas: 'USDT',
      trdas: 'COOKIE',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ALCHUSDT',
      balas: 'USDT',
      trdas: 'ALCH',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SWARMSUSDT',
      balas: 'USDT',
      trdas: 'SWARMS',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SONICUSDT',
      balas: 'USDT',
      trdas: 'SONIC',
      scnt: 1,
      pdec: 4,
      sdec: 1,
      min: 5
    },
    {
      tk: 'DUSDT',
      balas: 'USDT',
      trdas: 'D',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PROMUSDT',
      balas: 'USDT',
      trdas: 'PROM',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'SUSDT',
      balas: 'USDT',
      trdas: 'S',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'SOLVUSDT',
      balas: 'USDT',
      trdas: 'SOLV',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ARCUSDT',
      balas: 'USDT',
      trdas: 'ARC',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'AVAAIUSDT',
      balas: 'USDT',
      trdas: 'AVAAI',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'TRUMPUSDT',
      balas: 'USDT',
      trdas: 'TRUMP',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'MELANIAUSDT',
      balas: 'USDT',
      trdas: 'MELANIA',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'VTHOUSDT',
      balas: 'USDT',
      trdas: 'VTHO',
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 5
    },
    {
      tk: 'ANIMEUSDT',
      balas: 'USDT',
      trdas: 'ANIME',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    },
    {
      tk: 'VINEUSDT',
      balas: 'USDT',
      trdas: 'VINE',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'PIPPINUSDT',
      balas: 'USDT',
      trdas: 'PIPPIN',
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 5
    },
    {
      tk: 'VVVUSDT',
      balas: 'USDT',
      trdas: 'VVV',
      scnt: 1,
      pdec: 3,
      sdec: 2,
      min: 5
    },
    {
      tk: 'BERAUSDT',
      balas: 'USDT',
      trdas: 'BERA',
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 5
    },
    {
      tk: 'TSTUSDT',
      balas: 'USDT',
      trdas: 'TST',
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 5
    }
  ],
  delivery: [
    {
      tk: 'BTCUSD_PERP',
      balas: 'BTC',
      trdas: 'USD',
      scnt: 100,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BTCUSD_250328',
      balas: 'BTC',
      trdas: 'USD',
      scnt: 100,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BTCUSD_250627',
      balas: 'BTC',
      trdas: 'USD',
      scnt: 100,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ETHUSD_PERP',
      balas: 'ETH',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ETHUSD_250328',
      balas: 'ETH',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ETHUSD_250627',
      balas: 'ETH',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'LINKUSD_PERP',
      balas: 'LINK',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BNBUSD_PERP',
      balas: 'BNB',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'TRXUSD_PERP',
      balas: 'TRX',
      trdas: 'USD',
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'DOTUSD_PERP',
      balas: 'DOT',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ADAUSD_PERP',
      balas: 'ADA',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'EOSUSD_PERP',
      balas: 'EOS',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'LTCUSD_PERP',
      balas: 'LTC',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BCHUSD_PERP',
      balas: 'BCH',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'XRPUSD_PERP',
      balas: 'XRP',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ETCUSD_PERP',
      balas: 'ETC',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'FILUSD_PERP',
      balas: 'FIL',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'EGLDUSD_PERP',
      balas: 'EGLD',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'DOGEUSD_PERP',
      balas: 'DOGE',
      trdas: 'USD',
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'UNIUSD_PERP',
      balas: 'UNI',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'THETAUSD_PERP',
      balas: 'THETA',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'XLMUSD_PERP',
      balas: 'XLM',
      trdas: 'USD',
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'SOLUSD_PERP',
      balas: 'SOL',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'SANDUSD_PERP',
      balas: 'SAND',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'MANAUSD_PERP',
      balas: 'MANA',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'AVAXUSD_PERP',
      balas: 'AVAX',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'GALAUSD_PERP',
      balas: 'GALA',
      trdas: 'USD',
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'NEARUSD_PERP',
      balas: 'NEAR',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ATOMUSD_PERP',
      balas: 'ATOM',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'AAVEUSD_PERP',
      balas: 'AAVE',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'AXSUSD_PERP',
      balas: 'AXS',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ROSEUSD_PERP',
      balas: 'ROSE',
      trdas: 'USD',
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'XTZUSD_PERP',
      balas: 'XTZ',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ICXUSD_PERP',
      balas: 'ICX',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ALGOUSD_PERP',
      balas: 'ALGO',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'RUNEUSD_PERP',
      balas: 'RUNE',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'APEUSD_PERP',
      balas: 'APE',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'VETUSD_PERP',
      balas: 'VET',
      trdas: 'USD',
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ZILUSD_PERP',
      balas: 'ZIL',
      trdas: 'USD',
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: 'KNCUSD_PERP',
      balas: 'KNC',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'GMTUSD_PERP',
      balas: 'GMT',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'OPUSD_PERP',
      balas: 'OP',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ENSUSD_PERP',
      balas: 'ENS',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'CHZUSD_PERP',
      balas: 'CHZ',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'APTUSD_PERP',
      balas: 'APT',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'WIFUSD_PERP',
      balas: 'WIF',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'DOGSUSD_PERP',
      balas: 'DOGS',
      trdas: 'USD',
      scnt: 10,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: 'SUIUSD_PERP',
      balas: 'SUI',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ADAUSD_250328',
      balas: 'ADA',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'LINKUSD_250328',
      balas: 'LINK',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BCHUSD_250328',
      balas: 'BCH',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'DOTUSD_250328',
      balas: 'DOT',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'XRPUSD_250328',
      balas: 'XRP',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'LTCUSD_250328',
      balas: 'LTC',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BNBUSD_250328',
      balas: 'BNB',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'SOLUSD_250328',
      balas: 'SOL',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'WLDUSD_PERP',
      balas: 'WLD',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'ADAUSD_250627',
      balas: 'ADA',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'LINKUSD_250627',
      balas: 'LINK',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BCHUSD_250627',
      balas: 'BCH',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'DOTUSD_250627',
      balas: 'DOT',
      trdas: 'USD',
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: 'XRPUSD_250627',
      balas: 'XRP',
      trdas: 'USD',
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: 'LTCUSD_250627',
      balas: 'LTC',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'BNBUSD_250627',
      balas: 'BNB',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: 'SOLUSD_250627',
      balas: 'SOL',
      trdas: 'USD',
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    }
  ]
}

export {
  binanceTickers
}
