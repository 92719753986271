import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Table, Row, Col, message, Button, Input, InputNumber, Select, Radio } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { onSnapshot, doc, getDocs, setDoc, collection, query, where, limit } from 'firebase/firestore'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { pickBy } from '../../utils/tools'
import { billingCoin } from '../../const/billing'

import './Dashboard.css'

const Form = () => {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const { db } = useAuth()
  const { getTXs, removeTX } = useLayoutContext()

  const [value, setValue] = useState()
  const [btnLoading, setBtnLoading] = useState(false)
  const [networks, setNetworks] = useState([])
  const [tx, setTx] = useState()
  const [txDay, setTxDay] = useState(7)

  const [billingForm] = AntdForm.useForm()

  const txs = useAsync(async () => {
    if (!value) {
      return []
    }
    const txList = await getTXs(value.n, value.to, txDay)
    return txList.map((o) => ({
      ...o,
      vdiff: o.txv - value.am,
      tdiff: (value.pdate.seconds - o.ts) / (24 * 60 * 60)
    }))
  }, [value, txDay])

  useEffect(() => {
    const unsub = onSnapshot(query(collection(db, 'billings'), where('s', '==', 'pending'), where('c', '==', 'USDT'), limit(1)), { includeMetadataChanges: true }, (snapshot) => {
      const [data] = snapshot.docs.map((doc) => {
        const d = doc.data()
        const getNetworks = billingCoin.find((o) => o.value === d.c)?.networks ?? []
        setNetworks(getNetworks)
        return {
          ...d,
          id: doc.id
        }
      })
      setValue(data)
    })
    return () => unsub()
  }, [db])

  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      if (tx) {
        const snap = await getDocs(query(collection(db, 'billings'), where('n', '==', formData.n), where('blockNo', '==', tx.blockNo), where('txi', '==', tx.txi)))
        if (snap.size === 0) {
          await setDoc(doc(db, 'billings', formData.id), pickBy({
            ap: formData.ap,
            r: formData?.r,
            aam: formData?.aam,
            am: tx.txv,
            blockNo: tx.blockNo,
            txi: tx.txi
          }), {
            merge: true
          })
          removeTX(formData.n, tx.id)
        } else {
          removeTX(formData.n, tx.id)
          messageApi.open({
            type: 'warning',
            content: 'Duplicated transaction!',
            duration: 5,
          })
        }
      } else {
        messageApi.open({
          type: 'warning',
          content: 'You must select transaction!',
          duration: 5,
        })
      }
      setBtnLoading(false)
    } catch (error) {
      setBtnLoading(false)
    }
  }

  const rowSelection = {
    type: 'radio',
    onChange: (_, selectedRows) => {
      setTx(...selectedRows)
    }
  }

  const columns = [
    {
      title: 'Value',
      dataIndex: 'txv',
    },
    {
      title: 'Value Diff',
      dataIndex: 'vdiff',
      render: (data) => <span style={{ color: (Math.abs(data) < 1) ? '#15dd15' : '' }}>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(data)}</span>
    },
    {
      title: 'Time Diff',
      dataIndex: 'tdiff',
      render: (data) => <span style={{ color: (Math.abs(data) < 7) ? '#15dd15' : '' }}>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(data)}</span>
    },
    {
      title: 'Block Number',
      dataIndex: 'blockNo',
    },
    {
      title: 'Transaction Index',
      dataIndex: 'txi',
    },
    {
      title: 'Transaction Hash',
      dataIndex: 'hash',
    }
  ]

  if (!value) return (
    <div style={{ textAlign: 'center' }}>
      <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 20 }} onClick={() => navigate(-1)}><CloseOutlined />Close</Button>
    </div>
  )
  return (
    <div className='dashboard-page'>
      <Card
        title='Billing Approval'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
        extra={<InputNumber
          placeholder='Input last x days of TX'
          onChange={(v) => setTxDay(v)}
        />}
      >
        <AntdForm
          labelCol={{ span: 4 }}
          onFinish={handleSubmit}
          initialValues={value}
          form={billingForm}
          name='billingForm'
        >
          <AntdForm.Item name='id' hidden={true}>
            <Input />
          </AntdForm.Item>
          <AntdForm.Item name='c' label='Coin'>
            <Select
              placeholder='Select Coin'
              disabled
            >
              {((billingCoin || []).map((o) => (
                <Select.Option key={o.value} value={o.value}>{o.name}</Select.Option>
              )))
              }
            </Select>
          </AntdForm.Item>
          <AntdForm.Item name='n' label='Network'>
            <Select
              placeholder='Select Network'
              disabled
            >
              {((networks || []).map((o) => (
                <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>
              )))
              }
            </Select>
          </AntdForm.Item>
          <AntdForm.Item name='to' label='To Address'>
            <Input disabled />
          </AntdForm.Item>
          <AntdForm.Item name='from' label='From Address'>
            <Input disabled />
          </AntdForm.Item>
          <AntdForm.Item name='am' label='Amount'>
            <InputNumber disabled />
          </AntdForm.Item>
          <AntdForm.Item name='aam' label='Additional Amount'>
            <InputNumber />
          </AntdForm.Item>
          <AntdForm.Item name='r' label='Remark'>
            <Input.TextArea autoSize={{ minRows: 1 }} />
          </AntdForm.Item>
          <AntdForm.Item name='ap' label='Approval' rules={[{ required: true, message: 'Please Select Approval' }]}>
            <Radio.Group
              options={[
                { label: 'Approve', value: 'approved' },
                { label: 'Reject', value: 'rejected' }
              ]}
              optionType='button'
              buttonStyle='solid'
            />
          </AntdForm.Item>
        </AntdForm>
        <Row style={{ margin: '36px 0' }}>
          <Col span={4}></Col>
          <Col span={20}>
            <Table
              bordered
              rowKey='id'
              columns={columns}
              dataSource={txs.value}
              pagination={false}
              rowSelection={rowSelection}
            />
          </Col>
        </Row>
        <div style={{ textAlign: 'center' }}>
          <Button loading={btnLoading} style={{ width: 300, borderRadius: '4px', height: 40, marginTop: 8 }} onClick={() => billingForm.submit()} type='primary'><SaveOutlined />Submit</Button>
          <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 8, marginLeft: 20 }} onClick={() => navigate(-1)}><CloseOutlined />Close</Button>
        </div>
      </Card>
      {contextHolder}
    </div>
  )
}

export default Form
