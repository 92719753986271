import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Row, Col, Card, Table, Alert, Badge, Modal, Skeleton } from 'antd'
import { IdcardOutlined, TransactionOutlined, DollarOutlined, AccountBookOutlined, StopOutlined, DeleteOutlined } from '@ant-design/icons'

import { getDoc, doc, getAggregateFromServer, query, collection, where, count, sum } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { threshold, pending, timeFrame, rate } from '../../const/bots'
import { formatNumber } from '../../utils/tools'

import './Dashboard.css'

const fee = timeFrame.tv.map(o => ({
  id: o.value,
  tf: o.name,
  tv: (o.rate * rate).toFixed(2)
})).reverse()

const Dashboard = () => {
  const navigate = useNavigate()
  const [modal, contextHolder] = Modal.useModal()
  const { db, functions, uid, admin } = useAuth()
  const { getStat, setStat } = useLayoutContext()

  const billings = useAsync(async () => {
    if (!admin) {
      return 0
    }
    const q = query(collection(db, 'billings'), where('s', '==', 'pending'), where('c', 'in', ['USDT', 'BUSD']))
    const snap = await getAggregateFromServer(q, {
      countOfDocs: count()
    })
    return snap.data().countOfDocs
  }, [])

  const usage = useAsync(async () => {
    if (!admin) {
      return { bots: 0, usage: 0 }
    }
    const usage = getStat()
    if (usage) {
      return { bots: usage.bots, usage: usage.usage }
    }
    const q = query(collection(db, 'bots'), where('active', '==', true), where('ex', 'in', ['binance', 'okx']), where('ksi', 'in', [1]))
    const snap = await getAggregateFromServer(q, {
      countOfDocs: count(),
      totalPopulation: sum('fr')
    })
    const result = {
      bots: snap.data().countOfDocs,
      usage: snap.data().totalPopulation
    }
    setStat(result)
    return result
  }, [])

  const { value, loading } = useAsync(async () => {
    const snap = await getDoc(doc(db, 'users', uid))
    const data = snap.data()
    return {
      email: data.email,
      threshold: threshold[data.t],
      usage: Math.abs(data.u),
      bu: data.bu,
      color: (data.u < 0) ? '#73d13d' : (data.u <= threshold[data.t]) ? '#4096ff' : '#f5222d',
      text: (data.u < 0) ? 'Remaining' : 'Usage',
      pending: data.p
    }
  }, [])

  const handleDeleteBots = async () => {
    try {
      const confirmed = await modal.confirm({
        title: 'Delete Bots',
        content: 'Confirm to delete unused bots!',
      })
      if (confirmed) {
        const deleteBots = httpsCallable(functions, 'deleteBots-deleteBots')
        await deleteBots()
      }
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'Time Frame',
      dataIndex: 'tf',
      align: 'center'
    },
    {
      title: 'TradingView Bot (USD / Day)',
      dataIndex: 'tv',
      align: 'center'
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <div className='dashboard-page'>
      {(value.pending > pending) && (
        <Alert
          description='Your account has been suspended, please pay your fees to continue your bots.'
          type='error'
          icon={<StopOutlined />}
          banner
          closable
          style={{ width: '100%', marginBottom: 8 }}
        />
      )}
      {(admin) && (
        <>
          <Row gutter={16}>
            <Col span={8}>
              <Card
                hoverable
                styles={{
                  body: { textAlign: 'center' }
                }}
                onClick={() => navigate('/admin/dashboard/tickers')}
              >
                <Row gutter={8}>
                  <Col span={8}>
                    <DollarOutlined style={{ fontSize: '96px', color: '#13c2c2' }} />
                  </Col>
                  <Col span={16} style={{ margin: 'auto', width: '50%', fontSize: '28px', fontWeight: 'bold', color: '#36cfc9' }}>
                    Get Tickers
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                hoverable
                styles={{
                  body: { textAlign: 'center' }
                }}
                onClick={() => navigate('/admin/dashboard/gen-tickers')}
              >
                <Row gutter={8}>
                  <Col span={8}>
                    <DollarOutlined style={{ fontSize: '96px', color: '#1677ff' }} />
                  </Col>
                  <Col span={16} style={{ margin: 'auto', width: '50%', fontSize: '28px', fontWeight: 'bold', color: '#4096ff' }}>
                    Generate Tickers (Mon)
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                hoverable
                styles={{
                  body: { textAlign: 'center' }
                }}
                onClick={() => handleDeleteBots()}
              >
                <Row gutter={8}>
                  <Col span={8}>
                    <DeleteOutlined style={{ fontSize: '96px', color: '#f5222d' }} />
                  </Col>
                  <Col span={16} style={{ margin: 'auto', width: '50%', fontSize: '32px', fontWeight: 'bold', color: '#ff4d4f' }}>
                    Delete Bots (1st)
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Card
                hoverable
                styles={{
                  body: { textAlign: 'center' }
                }}
                onClick={() => navigate('/admin/dashboard/billings')}
              >
                <Row gutter={8}>
                  <Col span={8}>
                    <Badge count={billings.value} >
                      <TransactionOutlined style={{ fontSize: '96px', color: '#1677ff' }} />
                    </Badge>
                  </Col>
                  <Col span={16} style={{ margin: 'auto', width: '50%', fontSize: '32px', fontWeight: 'bold', color: '#4096ff' }}>
                    Billing Approval
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                hoverable
                styles={{
                  body: { textAlign: 'center' }
                }}
                onClick={() => navigate('/admin/dashboard/stat')}
              >
                <Row gutter={8}>
                  <Col span={8}>
                    <AccountBookOutlined style={{ fontSize: '96px', color: '#52c41a' }} />
                  </Col>
                  <Col span={6} style={{ margin: 'auto', width: '50%', textAlign: 'right', fontSize: '24px', fontWeight: 'bold', color: '#73d13d' }}>
                    Bots:<br />U/D:
                  </Col>
                  <Col span={10} style={{ margin: 'auto', paddingLeft: 24, width: '50%', textAlign: 'left', fontSize: '24px', fontWeight: 'bold', color: '#73d13d' }}>
                    {new Intl.NumberFormat().format(usage.value?.bots ?? 0)}<br />{new Intl.NumberFormat().format((usage.value?.usage ?? 0) * rate)}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={8}></Col>
          </Row>
          {contextHolder}
        </>
      )}
      <Row gutter={16}>
        <Col span={24}>
          <Card>
            <Row gutter={8}>
              <Col flex='150px'>
                <IdcardOutlined style={{ fontSize: '96px', color: '#13c2c2' }} />
              </Col>
              <Col flex='auto' style={{ margin: 'auto', width: '50%', fontSize: '24px', fontWeight: 'bold' }}>
                <span style={{ fontSize: '16px' }}>Email: {value.email}</span><br />
                <span style={{ fontSize: '16px' }}>ฺBalance {value.text}: </span><span style={{ fontSize: '18px', color: value.color }}>{formatNumber(value.usage, 2)} USD</span>
                {(value.text === 'Usage') && (
                  <span style={{ fontSize: '14px', color: '#9d9d9d' }}>  (Threshold: {formatNumber(value.threshold, 0)} USD)</span>
                )}
                {(value.bu > 0) && (
                  <>
                    <br /><span style={{ fontSize: '16px' }}>Bill Amount: </span><span style={{ fontSize: '18px', color: '#f5222d' }}>{formatNumber(value.bu, 2)} USD</span>
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Card
            title='Bot Fee'
            styles={{
              header: { fontWeight: 'bold', fontSize: '20px' },
              body: { padding: 0 }
            }}
          >
            <Table
              bordered={false}
              rowKey='id'
              size='small'
              pagination={false}
              columns={columns}
              dataSource={fee}
            />
          </Card>
        </Col>
        <Col span={8}></Col>
        <Col span={8}></Col>
      </Row>
    </div>
  )
}

export default Dashboard
